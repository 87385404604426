import classNames from 'classnames/bind';
import styles from './Login.module.scss';

const cx = classNames.bind(styles);

const Login = () => {
  return <>Login Admin Page</>;
};

export default Login;
