import { Listbox } from '@headlessui/react';
import classNames from 'classnames/bind';
import { useEffect, useRef, useState } from 'react';
import styles from './ButtonFoodOrderTouch.module.scss';

import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
  orderActions,
  selectIsResetOrderItemSelected,
  selectTopingRemoved,
  selectTypePizzaSelected,
} from '~/sagas/order/slice';
import { IToping } from '~/sagas/order/state';
import { selectIsCustomPizza } from '~/sagas/pizza/slice';
import { emptyValue } from '~/utils/constants/common';
import { useLongPress } from '~/utils/customHook';
import { ToppingSelectorPositionEnum, ToppingValueEnum } from '~/utils/enum';
import {
  IToppingItem,
  IToppingItemSelected,
  IToppingValueSelected,
  SelectorToppingData,
} from '~/utils/interface/common';

export type SelectorProps = {
  toppingItem: IToppingItem;
  data: SelectorToppingData[];
  isActive: boolean;
  onChange?: (itemSelected: IToppingItemSelected) => void;
  isResetToping?: boolean;
};

const cx = classNames.bind(styles);

const ButtonFoodOrderTouch = (props: SelectorProps) => {
  const { toppingItem, data, isActive, onChange, isResetToping = false } = props;

  const dispatch = useDispatch();
  const location = useLocation();

  const topingRemovedSelector: IToping = useSelector(selectTopingRemoved);
  const typePizzaSelected: number = useSelector(selectTypePizzaSelected);
  const isCustomPizza: number = useSelector(selectIsCustomPizza);
  const isResetOderItem: boolean = useSelector(selectIsResetOrderItemSelected);

  const optionsLeftRef = useRef<HTMLUListElement>(null);
  const dropdownLeftRef = useRef<HTMLDivElement>(null);
  const optionsMidRef = useRef<HTMLUListElement>(null);
  const dropdownMidRef = useRef<HTMLDivElement>(null);
  const optionsRightRef = useRef<HTMLUListElement>(null);
  const dropdownRightRef = useRef<HTMLDivElement>(null);

  const [isClickNone, setIsClickNone] = useState<boolean>(false);
  const [valueSelected, setValueSelected] = useState<IToppingValueSelected>({
    Left: '',
    Mid: '',
    Right: '',
  });
  const [hasValueLeft, setHasValueLeft] = useState<boolean>(false);
  const [hasValueMid, setHasValueMid] = useState<boolean>(false);
  const [hasValueRight, setHasValueRight] = useState<boolean>(false);

  const [isClickLeft, setIsClickLeft] = useState<boolean>(false);
  const [isClickMid, setIsClickMid] = useState<boolean>(isActive);
  const [isClickRight, setIsClickRight] = useState<boolean>(false);

  const [isVisibleLeft, setIsVisibleLeft] = useState<boolean>(false);
  const [isVisibleMid, setIsVisibleMid] = useState<boolean>(false);
  const [isVisibleRight, setIsVisibleRight] = useState<boolean>(false);
  const isPressingRef = useRef<boolean>(false);
  const thresholdDuration = 550;

  useEffect(() => {
    setValueSelected({
      Left: '',
      Mid: '',
      Right: '',
    });
  }, [typePizzaSelected]);

  useEffect(() => {
    if (!isResetOderItem) {
      return;
    }
    onValueChange('', 'CLEAR');
    dispatch(orderActions.setIsResetOrderItem(false));
  }, [isResetOderItem]);

  // const didMount = useRef(false);
  // useEffect(() => {
  //   if (!didMount.current) {
  //     didMount.current = true;
  //     return;
  //   }

  //   if (!isActive) {
  //     return;
  //   }

  //   onValueChange(ToppingValueEnum.X1, ToppingSelectorPositionEnum.MID);
  // }, [isActive]);

  useEffect(() => {
    if (!isActive) {
      return;
    }

    onValueChange(ToppingValueEnum.X1, ToppingSelectorPositionEnum.MID);
  }, [isActive]);

  useEffect(() => {
    if (topingRemovedSelector && topingRemovedSelector.title === toppingItem.title) {
      setValueSelected((prev) => {
        return {
          ...prev,
          [topingRemovedSelector.position]: '',
        };
      });

      switch (topingRemovedSelector.position) {
        case ToppingSelectorPositionEnum.LEFT:
          setHasValueLeft(false);
          setIsClickLeft(false);
          break;
        case ToppingSelectorPositionEnum.MID:
          setHasValueMid(false);
          setIsClickMid(false);
          break;
        case ToppingSelectorPositionEnum.RIGHT:
          setHasValueRight(false);
          setIsClickRight(false);
          break;
      }
    }
  }, [topingRemovedSelector]);

  const handleOutsideClick = (event: Event) => {
    if (
      optionsLeftRef.current &&
      !optionsLeftRef.current.contains(event.target as Node) &&
      dropdownLeftRef.current &&
      !dropdownLeftRef.current.contains(event.target as Node)
    ) {
      setIsVisibleLeft(false);
    }
    if (
      optionsMidRef.current &&
      !optionsMidRef.current.contains(event.target as Node) &&
      dropdownMidRef.current &&
      !dropdownMidRef.current.contains(event.target as Node)
    ) {
      setIsVisibleMid(false);
    }
    if (
      optionsRightRef.current &&
      !optionsRightRef.current.contains(event.target as Node) &&
      dropdownRightRef.current &&
      !dropdownRightRef.current.contains(event.target as Node)
    ) {
      setIsVisibleRight(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    document.addEventListener('touchstart', handleOutsideClick);

    return () => {
      document.removeEventListener('touchstart', handleOutsideClick);
      document.removeEventListener('touchstart', handleOutsideClick);
    };
  }, []);

  const handleTouchStart = (payload: { position: ToppingSelectorPositionEnum }) => {
    isPressingRef.current = true;

    setTimeout(() => {
      if (isPressingRef.current) {
        switch (payload.position) {
          case ToppingSelectorPositionEnum.LEFT:
            setIsVisibleLeft(true);
            break;
          case ToppingSelectorPositionEnum.MID:
            setIsVisibleMid(true);
            break;
          case ToppingSelectorPositionEnum.RIGHT:
            setIsVisibleRight(true);
            break;
        }
      }
    }, thresholdDuration);
  };

  const handleTouchEnd = () => {
    isPressingRef.current = false;
  };

  const onLongPress = (payload: { position: ToppingSelectorPositionEnum }) => {
    switch (payload.position) {
      case ToppingSelectorPositionEnum.LEFT:
        setIsVisibleLeft(true);
        break;
      case ToppingSelectorPositionEnum.MID:
        setIsVisibleMid(true);
        break;
      case ToppingSelectorPositionEnum.RIGHT:
        setIsVisibleRight(true);
        break;
    }
  };

  const onClick = () => {
    console.log('click is triggered');
  };

  const defaultOptions = {
    shouldPreventDefault: true,
    delay: 550,
  };
  const { onMouseDown, onTouchStart, onMouseUp, onMouseLeave, onTouchEnd } = useLongPress(
    onLongPress,
    onClick,
    defaultOptions
  );

  const checkValue = (value: string) => {
    if (value === ToppingValueEnum.LIGHT) {
      return 'Lt.';
    }
    if (value === ToppingValueEnum.NONE) {
      return '';
    }
    return value;
  };

  const onValueChange = (value: string, positionSelected: ToppingSelectorPositionEnum | string, e?: MouseEvent) => {
    e?.preventDefault();
    const checkedValue = checkValue(value);

    // if (positionSelected === ToppingSelectorPositionEnum.MID) {
    //   if (!checkedValue) {
    //     setIsClickNone(true);
    //   } else {
    //     setIsClickNone(false);
    //   }
    // }

    // const newValueSelect = { ...valueSelected, [positionSelected]: checkedValue };
    // const newToppingItem = {
    //   ...toppingItem,
    //   position: positionSelected,
    //   value: checkedValue,
    //   selectedValue: newValueSelect,
    // };

    // onChange && onChange(newToppingItem);
    // setValueSelected(newValueSelect);

    let valueSelectCopy = { ...valueSelected };

    switch (positionSelected) {
      case ToppingSelectorPositionEnum.LEFT:
        if (!checkedValue) {
          //Case select None in dropdown
          valueSelectCopy[positionSelected] = checkedValue;

          setHasValueLeft(false);
          setIsClickLeft(false);
        } else {
          valueSelectCopy[positionSelected] = checkedValue;
          valueSelectCopy[ToppingSelectorPositionEnum.MID] = '';
          // valueSelectCopy[ToppingSelectorPositionEnum.RIGHT] = '';

          const newToppingItemMid = {
            ...toppingItem,
            position: ToppingSelectorPositionEnum.MID,
            value: '',
            selectedValue: valueSelectCopy,
          };
          onChange && onChange(newToppingItemMid);
          // const newToppingItemRight = {
          //   ...toppingItem,
          //   position: ToppingSelectorPositionEnum.RIGHT,
          //   value: '',
          //   selectedValue: valueSelectCopy,
          // };
          // onChange && onChange(newToppingItemRight);

          setHasValueLeft(true);
          setIsClickLeft(true);
          setHasValueMid(false);
          setIsClickMid(false);
          // setHasValueRight(false);
          // setIsClickRight(false);
        }
        setIsVisibleLeft(false);
        break;
      case ToppingSelectorPositionEnum.MID:
        if (!checkedValue) {
          //Case select None in dropdown
          valueSelectCopy[positionSelected] = checkedValue;

          setHasValueMid(false);
          setIsClickMid(false);
        } else {
          valueSelectCopy[positionSelected] = checkedValue;
          valueSelectCopy[ToppingSelectorPositionEnum.LEFT] = '';
          valueSelectCopy[ToppingSelectorPositionEnum.RIGHT] = '';

          const newToppingItemLeft = {
            ...toppingItem,
            position: ToppingSelectorPositionEnum.LEFT,
            value: '',
            selectedValue: valueSelectCopy,
          };
          onChange && onChange(newToppingItemLeft);
          const newToppingItemRight = {
            ...toppingItem,
            position: ToppingSelectorPositionEnum.RIGHT,
            value: '',
            selectedValue: valueSelectCopy,
          };
          onChange && onChange(newToppingItemRight);

          setHasValueLeft(false);
          setIsClickLeft(false);
          setHasValueMid(true);
          setIsClickMid(true);
          setHasValueRight(false);
          setIsClickRight(false);
        }
        setIsVisibleMid(false);
        break;
      case ToppingSelectorPositionEnum.RIGHT:
        if (!checkedValue) {
          //Case select None in dropdown
          valueSelectCopy[positionSelected] = checkedValue;

          setHasValueRight(false);
          setIsClickRight(false);
        } else {
          valueSelectCopy[positionSelected] = checkedValue;
          valueSelectCopy[ToppingSelectorPositionEnum.MID] = '';
          // valueSelectCopy[ToppingSelectorPositionEnum.LEFT] = '';

          // const newToppingItemLeft = {
          //   ...toppingItem,
          //   position: ToppingSelectorPositionEnum.LEFT,
          //   value: '',
          //   selectedValue: valueSelectCopy,
          // };
          // onChange && onChange(newToppingItemLeft);
          const newToppingItemMid = {
            ...toppingItem,
            position: ToppingSelectorPositionEnum.MID,
            value: '',
            selectedValue: valueSelectCopy,
          };
          onChange && onChange(newToppingItemMid);

          // setHasValueLeft(false);
          // setIsClickLeft(false);
          setHasValueMid(false);
          setIsClickMid(false);
          setHasValueRight(true);
          setIsClickRight(true);
        }
        setIsVisibleRight(false);
        break;

      default:
        valueSelectCopy[ToppingSelectorPositionEnum.LEFT] = '';
        valueSelectCopy[ToppingSelectorPositionEnum.MID] = '';
        valueSelectCopy[ToppingSelectorPositionEnum.RIGHT] = '';
        setHasValueLeft(false);
        setIsClickLeft(false);
        setHasValueRight(false);
        setIsClickRight(false);
        setHasValueMid(false);
        setIsClickMid(false);
        break;
    }

    const newToppingItem = {
      ...toppingItem,
      position: positionSelected,
      value: checkedValue,
      selectedValue: valueSelectCopy,
    };

    onChange && onChange(newToppingItem);
    setValueSelected(valueSelectCopy);
  };

  const handleClickToping = (payload: { position: ToppingSelectorPositionEnum }) => {
    switch (payload.position) {
      case ToppingSelectorPositionEnum.LEFT:
        if (isClickLeft && hasValueLeft) {
          // setHasValueLeft(false);
          // setIsClickLeft(false);
          // setValueSelected((prev) => {
          //   return {
          //     ...prev,
          //     Left: '',
          //   };
          // });
          onValueChange('', ToppingSelectorPositionEnum.LEFT);
          return;
        }

        // x1 topping is default
        const toppingLeftDefault = ToppingValueEnum.X1;
        onValueChange(toppingLeftDefault, ToppingSelectorPositionEnum.LEFT);
        // setIsClickLeft(!isClickLeft);
        break;
      case ToppingSelectorPositionEnum.MID:
        if (isClickMid && hasValueMid) {
          // setHasValueMid(false);
          // setIsClickMid(false);
          // setValueSelected((prev) => {
          //   return {
          //     ...prev,
          //     Mid: '',
          //   };
          // });
          onValueChange('', ToppingSelectorPositionEnum.MID);
          return;
        }

        // x1 topping is default
        const toppingMidDefault = ToppingValueEnum.X1;
        onValueChange(toppingMidDefault, ToppingSelectorPositionEnum.MID);
        // setIsClickMid(!isClickMid);
        break;
      case ToppingSelectorPositionEnum.RIGHT:
        if (isClickRight && hasValueRight) {
          // setHasValueRight(false);
          // setIsClickRight(false);
          // setValueSelected((prev) => {
          //   return {
          //     ...prev,
          //     Right: '',
          //   };
          // });
          onValueChange('', ToppingSelectorPositionEnum.RIGHT);
          return;
        }

        // x1 topping is default
        const toppingRightDefault = ToppingValueEnum.X1;
        onValueChange(toppingRightDefault, ToppingSelectorPositionEnum.RIGHT);
        // setIsClickRight(!isClickRight);
        break;
    }
  };

  return (
    <div className={cx('topping-select-container')}>
      <div className={cx('topping-name')}>{toppingItem.title}</div>

      <div className={cx('topping-select-wrap')}>
        {/* Left */}
        <div
          className={cx([
            'topping-left',
            'btn-topping-common-style',
            (isClickLeft || hasValueLeft) && 'btn-topping-active',
          ])}
          ref={dropdownLeftRef}
        >
          <Listbox
            defaultValue={emptyValue}
            value={valueSelected[ToppingSelectorPositionEnum.LEFT]}
            // disabled={!isActive}
            onChange={(value) => onValueChange(value, ToppingSelectorPositionEnum.LEFT)}
          >
            <Listbox.Button
              className={cx('list-box-btn')}
              // onClick={() => handleClickToping({ position: ToppingSelectorPositionEnum.LEFT })}
              // onTouchStart={() => handleTouchStart({ position: ToppingSelectorPositionEnum.LEFT })}
              // onTouchEnd={handleTouchEnd}
              onClick={() => handleClickToping({ position: ToppingSelectorPositionEnum.LEFT })}
              onTouchStart={() => onTouchStart({ position: ToppingSelectorPositionEnum.LEFT })}
              onTouchEnd={onTouchEnd}
            >
              {valueSelected[ToppingSelectorPositionEnum.LEFT] && (
                <div className={cx(['btn-topping-text'])}>
                  {valueSelected[ToppingSelectorPositionEnum.LEFT] || emptyValue}
                  {/* {(valueSelected[ToppingSelectorPositionEnum.LEFT] === ToppingValueEnum.X1
                    ? ''
                    : valueSelected[ToppingSelectorPositionEnum.LEFT]) || emptyStrValue} */}
                </div>
              )}
            </Listbox.Button>
            {isVisibleLeft && (
              <Listbox.Options ref={optionsLeftRef} className={cx('topping-list-selector')} static>
                {data.map((item) => (
                  <Listbox.Option key={item.id} value={item.value}>
                    {({ active, selected }) => (
                      <div
                        className={cx([
                          'topping-selector-item',
                          (active || selected) && 'topping-selector-item-active',
                        ])}
                      >
                        {item.value}
                      </div>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            )}
          </Listbox>
        </div>

        {/* Mid */}
        {location.pathname !== '/pizza' || isCustomPizza ? (
          <div
            className={cx([
              'topping-mid',
              'btn-topping-common-style',
              (isClickMid || hasValueMid) && 'btn-topping-active',
            ])}
            ref={dropdownMidRef}
          >
            <Listbox
              defaultValue={emptyValue}
              value={valueSelected[ToppingSelectorPositionEnum.MID]}
              // disabled={!isActive}
              onChange={(value) => onValueChange(value, ToppingSelectorPositionEnum.MID)}
            >
              <Listbox.Button
                className={cx('list-box-btn')}
                // onClick={() => handleClickToping({ position: ToppingSelectorPositionEnum.MID })}
                // onTouchStart={() => handleTouchStart({ position: ToppingSelectorPositionEnum.MID })}
                // onTouchEnd={handleTouchEnd}

                onClick={() => handleClickToping({ position: ToppingSelectorPositionEnum.MID })}
                onTouchStart={() => onTouchStart({ position: ToppingSelectorPositionEnum.MID })}
                onTouchEnd={onTouchEnd}
              >
                {valueSelected[ToppingSelectorPositionEnum.MID] && (
                  <div className={cx(['btn-topping-text'])}>
                    {valueSelected[ToppingSelectorPositionEnum.MID] || emptyValue}
                    {/* {(valueSelected[ToppingSelectorPositionEnum.MID] === ToppingValueEnum.X1
                      ? ''
                      : valueSelected[ToppingSelectorPositionEnum.MID]) || emptyStrValue} */}
                  </div>
                )}
              </Listbox.Button>

              {isVisibleMid && (
                <Listbox.Options ref={optionsMidRef} className={cx('topping-list-selector')} static>
                  {data.map((item) => (
                    <Listbox.Option key={item.id} value={item.value}>
                      {({ active, selected }) => (
                        <div
                          className={cx([
                            'topping-selector-item',
                            (active || selected) && 'topping-selector-item-active',
                          ])}
                        >
                          {item.value}
                        </div>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              )}
            </Listbox>
          </div>
        ) : (
          <div
            id='divMidActive'
            className={cx([
              'topping-mid',
              'btn-topping-common-style',
              (isClickMid || hasValueMid) && 'btn-topping-active',
            ])}
            ref={dropdownMidRef}
          >
            <Listbox
              defaultValue={emptyValue}
              value={valueSelected[ToppingSelectorPositionEnum.MID]}
              // disabled={!isActive}
              onChange={(value) => onValueChange(value, ToppingSelectorPositionEnum.MID)}
            >
              <Listbox.Button
                className={cx('list-box-btn')}
                // onClick={() => handleClickToping({ position: ToppingSelectorPositionEnum.MID })}
                // onTouchStart={() => handleTouchStart({ position: ToppingSelectorPositionEnum.MID })}
                // onTouchEnd={handleTouchEnd}

                onClick={() => handleClickToping({ position: ToppingSelectorPositionEnum.MID })}
                onTouchStart={() => onTouchStart({ position: ToppingSelectorPositionEnum.MID })}
                onTouchEnd={onTouchEnd}
              >
                {valueSelected[ToppingSelectorPositionEnum.MID] && (
                  <div className={cx(['btn-topping-text'])}>
                    {valueSelected[ToppingSelectorPositionEnum.MID] || emptyValue}
                    {/* {(valueSelected[ToppingSelectorPositionEnum.MID] === ToppingValueEnum.X1
                      ? ''
                      : valueSelected[ToppingSelectorPositionEnum.MID]) || emptyStrValue} */}
                  </div>
                )}
              </Listbox.Button>

              {isVisibleMid && (
                <Listbox.Options ref={optionsMidRef} className={cx('topping-list-selector')} static>
                  {data.map((item) => (
                    <Listbox.Option key={item.id} value={item.value}>
                      {({ active, selected }) => (
                        <div
                          className={cx([
                            'topping-selector-item',
                            (active || selected) && 'topping-selector-item-active',
                          ])}
                        >
                          {item.value}
                        </div>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              )}
            </Listbox>
          </div>
        )}

        {/* Right */}
        <div
          className={cx([
            'topping-right',
            'btn-topping-common-style',
            (isClickRight || hasValueRight) && 'btn-topping-active',
          ])}
          ref={dropdownRightRef}
        >
          <Listbox
            defaultValue={emptyValue}
            value={valueSelected[ToppingSelectorPositionEnum.RIGHT]}
            onChange={(value) => onValueChange(value, ToppingSelectorPositionEnum.RIGHT)}
          >
            <Listbox.Button
              className={cx('list-box-btn')}
              // onClick={() => handleClickToping({ position: ToppingSelectorPositionEnum.RIGHT })}
              // onTouchStart={() => handleTouchStart({ position: ToppingSelectorPositionEnum.RIGHT })}
              // onTouchEnd={handleTouchEnd}

              onClick={() => handleClickToping({ position: ToppingSelectorPositionEnum.RIGHT })}
              onTouchStart={() => onTouchStart({ position: ToppingSelectorPositionEnum.RIGHT })}
              onTouchEnd={onTouchEnd}
            >
              {valueSelected[ToppingSelectorPositionEnum.RIGHT] && (
                <div className={cx(['btn-topping-text'])}>
                  {valueSelected[ToppingSelectorPositionEnum.RIGHT] || emptyValue}
                  {/* {(valueSelected[ToppingSelectorPositionEnum.RIGHT] === ToppingValueEnum.X1
                    ? ''
                    : valueSelected[ToppingSelectorPositionEnum.RIGHT]) || emptyStrValue} */}
                </div>
              )}
            </Listbox.Button>

            {isVisibleRight && (
              <Listbox.Options ref={optionsRightRef} className={cx('topping-list-selector')} static>
                {data.map((item) => (
                  <Listbox.Option key={item.id} value={item.value}>
                    {({ active, selected }) => (
                      <div
                        className={cx([
                          'topping-selector-item',
                          (active || selected) && 'topping-selector-item-active',
                        ])}
                      >
                        {item.value}
                      </div>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            )}
          </Listbox>
        </div>
      </div>
    </div>
  );
};

export default ButtonFoodOrderTouch;
