import classNames from 'classnames/bind';
import styles from './BaseButton.module.scss';

type BaseButtonItemProps = {
  title: string | number;
  isNumericOrder?: boolean;
  fontSize18?: boolean;
  fontSize20?: boolean;
  backgroundType1?: boolean;
  backgroundType2?: boolean;
  onClick?: (e: any, value: string | number) => void;
};

const cx = classNames.bind(styles);

const BaseButton = (props: BaseButtonItemProps) => {
  //#region Destructuring Props
  const {
    title,
    isNumericOrder,
    fontSize18 = true,
    fontSize20,
    onClick,
    backgroundType1 = true,
    backgroundType2,
  } = props;
  //#endregion Destructuring Props

  return (
    <>
      {isNumericOrder ? (
        <button
          type='button'
          className={cx([
            'base-button-type-number',
            backgroundType1 && 'base-button-background-1',
            backgroundType2 && 'base-button-background-2',
          ])}
          onClick={(e) => (onClick && title ? onClick(e, title) : undefined)}
        >
          {title}
        </button>
      ) : (
        <button
          type='button'
          onClick={(e) => (onClick && title ? onClick(e, title) : undefined)}
          className={cx([
            fontSize18 && 'base-button-text-18',
            fontSize20 && 'base-button-text-20',
            backgroundType1 && 'base-button-background-1',
            backgroundType2 && 'base-button-background-2',
          ])}
        >
          {title}
        </button>
      )}
    </>
  );
};

export default BaseButton;
