import { IconSvgProps } from "~/utils/interface/common";
import styles from './styles.module.scss';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

const IconSecretSvg = (props: IconSvgProps) => {
  //#region Destructuring Props
  const { strokePath = '#1A1346' } = props;
  //#endregion Destructuring Props

  return (
    <svg className={cx('icon-secret')} viewBox='0 0 26 34' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        id='Vector'
        d='M21.6665 13.9485V8.7178C21.6665 3.91075 17.779 0 13 0C8.22102 0 4.33347 3.91055 4.33347 8.7178V13.9485C1.94554 13.9485 0 15.9053 0 18.3076V29.6409C0 32.0429 1.94534 34 4.33347 34H21.6665C24.0545 34 26 32.0431 26 29.6409V18.3076C26 15.9024 24.0547 13.9485 21.6665 13.9485ZM6.06677 8.71461C6.06677 4.86945 9.17745 1.74037 13 1.74037C16.8225 1.74037 19.9332 4.86945 19.9332 8.71461V13.9453H6.06677V8.71461ZM24.2667 29.6373C24.2667 31.0804 23.1014 32.2527 21.6667 32.2527H4.33366C2.89904 32.2527 1.7337 31.0804 1.7337 29.6373V18.304C1.7337 16.8609 2.89904 15.6886 4.33366 15.6886H21.6667C23.1014 15.6886 24.2667 16.8609 24.2667 18.304V29.6373Z'
        fill={strokePath}
      />
    </svg>
  );
};

export default IconSecretSvg;
