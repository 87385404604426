import IconBack from '~/assets/icons/ic-back.svg';
import IconArrowDown from '~/assets/icons/ic-arrow-down.svg';
import IconArrowUp from '~/assets/icons/ic-arrow-up.svg';
import IconArrowDownColor from '~/assets/icons/ic-arrow-down-color.svg';
import IconPizza from '~/assets/icons/ic-pizza.svg';
import IconPizzaActive from '~/assets/icons/ic-pizza-active.svg';
import IconFlatbread from '~/assets/icons/ic-flatbread.svg';
import IconFlatbreadActive from '~/assets/icons/ic-flatbread-active.svg';
import IconSubs from '~/assets/icons/ic-subs.svg';
import IconSubsActive from '~/assets/icons/ic-subs-active.svg';
import IconBread from '~/assets/icons/ic-bread.svg';
import IconBreadActive from '~/assets/icons/ic-bread-active.svg';
import IconBowl from '~/assets/icons/ic-bowl.svg';
import IconBowlActive from '~/assets/icons/ic-bowl-active.svg';
import IconSalads from '~/assets/icons/ic-salads.svg';
import IconSaladsActive from '~/assets/icons/ic-salads-active.svg';
import IconWings from '~/assets/icons/ic-wings.svg';
import IconWingsActive from '~/assets/icons/ic-wings-active.svg';
import IconDesserts from '~/assets/icons/ic-desserts.svg';
import IconDessertsActive from '~/assets/icons/ic-desserts-active.svg';
import IconDrinks from '~/assets/icons/ic-drinks.svg';
import IconDrinksActive from '~/assets/icons/ic-drinks-active.svg';
import IconPasta from '~/assets/icons/ic-pasta.svg';
import IconPastaActive from '~/assets/icons/ic-pasta-active.svg';
import IconSecret from '~/assets/icons/ic-secret.svg';
import IconSecretActive from '~/assets/icons/ic-secret-active.svg';
import IconTrash from '~/assets/icons/ic-trash.svg';
import IconSun from '~/assets/icons/ic-sun.svg';
import IconClock from '~/assets/icons/ic-clock.svg';
import IconCross from '~/assets/icons/ic-cross.svg';
import IconCar from '~/assets/icons/ic-car.svg';
import IconBackWhite from '~/assets/icons/ic-back-white.svg';
import IconOrders from '~/assets/icons/ic-orders.svg';
import IconTimeClock from '~/assets/icons/ic-time-clock.svg';
import IconDelivery from '~/assets/icons/ic-delivery.svg';
import IconTools from '~/assets/icons/ic-tools.svg';
import IconCash from '~/assets/icons/ic-cash.svg';
import IconLogOff from '~/assets/icons/ic-log-off.svg';
import IconMall from '~/assets/icons/ic-mall.svg';
import IconLogo from '~/assets/icons/ic-logo.svg';

const icons = {
  logo: require('./logo_fife.ico'),
  backSvg: IconBack,
  arrowDownSvg: IconArrowDown,
  arrowUpSvg: IconArrowUp,
  arrowDownColorSvg: IconArrowDownColor,
  pizzaSvg: IconPizza,
  pizzaActiveSvg: IconPizzaActive,
  flatbreadSvg: IconFlatbread,
  flatbreadActiveSvg: IconFlatbreadActive,
  subsSvg: IconSubs,
  subsActiveSvg: IconSubsActive,
  breadSvg: IconBread,
  breadActiveSvg: IconBreadActive,
  bowlSvg: IconBowl,
  bowlActiveSvg: IconBowlActive,
  saladsSvg: IconSalads,
  saladsActiveSvg: IconSaladsActive,
  wingsSvg: IconWings,
  wingsActiveSvg: IconWingsActive,
  dessertsSvg: IconDesserts,
  dessertsActiveSvg: IconDessertsActive,
  drinksSvg: IconDrinks,
  drinksActiveSvg: IconDrinksActive,
  secretSvg: IconSecret,
  secretActiveSvg: IconSecretActive,
  pastaSvg: IconPasta,
  pastaActiveSvg: IconPastaActive,
  trashSvg: IconTrash,
  sunSvg: IconSun,
  clockSvg: IconClock,
  crossSvg: IconCross,
  carSvg: IconCar,
  backWhiteSvg: IconBackWhite,
  ordersSvg: IconOrders,
  timeClockSvg: IconTimeClock,
  deliverySvg: IconDelivery,
  toolsSvg: IconTools,
  cashSvg: IconCash,
  logOffSvg: IconLogOff,
  mallSvg: IconMall,
  logoSvg: IconLogo,
};

export default icons;
