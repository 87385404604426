import classNames from 'classnames/bind';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { TimeClock } from '~/components/specific';
import BaseButton from '~/components/specific/baseButton/BaseButton';
import InputCode from '~/components/specific/inputCode/inputCode';
import { pizzaActions } from '~/sagas/pizza/slice';
import { firstTimeContent, numberKeyboard } from '~/utils/constants/common';
import { userRoute } from '~/utils/constants/route';
import styles from './OrderLookupPage.module.scss';
import BackButton from './components/backButton/BackButton';
import Footer from './components/footer/Footer';
import LeftContent from './components/leftContent/LeftContent';

const cx = classNames.bind(styles);

const OrderLookupPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [code, setCode] = useState<string>('6');

  const timeClocked = '9:45am';

  const handleBackButtonClick = () => {
    const isPizzaPage = location.pathname === `${userRoute.base}${userRoute.pizza}`;

    if (isPizzaPage) {
      dispatch(pizzaActions.setAddPizzaAction(true));
      dispatch(pizzaActions.setIsCustomPizza(false));
      return;
    }
    dispatch(pizzaActions.setIsCustomPizza(false));
    navigate(`${userRoute.base}${userRoute.pizza}`);
  };

  const handleSendButtonClick = () => {
    navigate(-1);
  };

  const handleClearCode = () => {};

  const handleBackCode = () => {};

  const handleEnterCode = (e: any, value: string | number) => {
    setCode(code + value.toString());
  };

  return (
    <div id='orderLookupPage' className={cx('order-lookup-layout-container')}>
      <div className={cx('order-lookup-content-wrap')}>
        <div className={cx('order-lookup-content-layout')}>
          <div className={cx('order-lookup-content-left')}>
            <LeftContent />
          </div>

          <div className={cx('order-lookup-content-right')}>
            <div className={cx('order-lookup-search-wrap')}>
              <div className={cx('order-lookup-search-title')}>Search by</div>

              <div className={cx('order-lookup-search-options')}>
                <button className={cx(['order-lookup-search-button', 'active'])}>By Order</button>

                <button className={cx(['order-lookup-search-button', 'default'])}>By Phone</button>

                <button className={cx(['order-lookup-search-button', 'default'])}>By Table</button>
              </div>
            </div>

            <div className={cx('order-lookup-calculator')}>
              <div className={cx('order-lookup-input-form')}>
                <InputCode value={code} />
              </div>

              <div className={cx('order-lookup-keyboard-items')}>
                {numberKeyboard.map((item) => (
                  <BaseButton key={item} title={item} isNumericOrder onClick={handleEnterCode} backgroundType2 />
                ))}

                <BaseButton title={'Clear'} onClick={handleClearCode} backgroundType2 />

                <BaseButton title={0} isNumericOrder onClick={handleEnterCode} backgroundType2 />

                <BaseButton title={'Back'} onClick={handleBackCode} backgroundType2 />
              </div>
            </div>
          </div>
        </div>

        <div className={cx('order-lookup-back-button-wrap')}>
          <BackButton onBackButtonClick={handleBackButtonClick} />
        </div>
        <div className={cx('order-lookup-time-clock-wrap')}>
          <TimeClock contentTime={firstTimeContent} valueTime={timeClocked} />
        </div>
      </div>

      <div className={cx('footer-wrap')}>
        <Footer onSendButtonClick={handleSendButtonClick} />
      </div>
    </div>
  );
};

export default OrderLookupPage;
