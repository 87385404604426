import React, { useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import classNames from 'classnames/bind';
import styles from './DialogButtonSend.module.scss';
import { TIMEOUT_DIALOG_DEFAULT, descriptionDialogOrderSent } from '~/utils/constants/mockData';

type DialogButtonSendProps = {
  isOpen: boolean;
  onClose: () => void;
};

const cx = classNames.bind(styles);

const DialogButtonSend = (props: DialogButtonSendProps) => {
  const { isOpen, onClose } = props;

  useEffect(() => {
    if (isOpen) {
      const timeoutId = setTimeout(() => {
        onClose();
      }, TIMEOUT_DIALOG_DEFAULT);

      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [isOpen, onClose]);

  return (
    <Transition appear show={isOpen} as={React.Fragment}>
      <Dialog as='div' onClose={onClose} className={cx('dialog-container')}>
        <Dialog.Overlay className={cx('overlay')} />

        <div className={cx('content')}>
          <Dialog.Description className={cx('description')}>{descriptionDialogOrderSent}</Dialog.Description>
        </div>
      </Dialog>
    </Transition>
  );
};

export default DialogButtonSend;
