export enum EnvironmentEnum {
  LOCAL = 'local',
  DEVELOPMENT = 'development',
  STAG = 'stag',
  PROD = 'prod',
}

export enum RolesEnum {
  ADMIN = 'ADMIN',
}

export enum StorageEnum {
  ACCESS_TOKEN = 'access_token',
  REFRESH_TOKEN = 'refresh_token',
  USER = 'user',
  STATUS_LOGIN = 'statusLogin',
}

export enum LanguageEnum {
  EN = 'en',
}

export enum TypeFooterButtonEnum {
  ARROW = 'arrow',
  ACTION = 'action',
  SEND = 'send',
  ADD = 'add',
}

// Pizza Selector component enum
export enum KeyTypeChoosePizzaEnum {
  BACON_JALAPENO = 1,
  BEE_STRING_PIZZA = 2,
  PEPPERONI_DUO = 3,
  MEAT_EATERS = 4,
  WORKS_PIZZA = 5,
  HOWIE_SPECIAL = 6,
  HOWIE_MAUI = 7,
  BBQ_CHICKEN = 8,
  VEGGIE = 9,
  HAWAIIAN = 10,
  CHICKEN_BACON_RANCH = 11,
  BACON_CHEDDAR_CHEESEBURGER = 12,
  ASIAN_CHICKEN = 13,
  PHILLY_STEAK_CHEESE = 14,
  BUFFALO_CHICKEN = 15,
  THREEE_CHEESER_PEPPERONI = 16,
}

export enum KeyTypeSizeEnum {
  EIGHT_LARGE = 1,
  TEN_LARGE = 2,
  TWELVE_MEDIUM_LARGE = 3,
  FOURTEEN_LARGE = 4,
  EIGHTEEN_X_LARGE = 5,
}

export enum KeyTypeCrustTypeEnum {
  ORIGINAL_ROUND = 1,
  THIN_CRUST = 2,
  STUFFED_CRUST = 3,
}

export enum KeyTypeSauceEnum {
  RANCH_SAUCE = 1,
  GARLIC_SAUCE = 2,
  MARINARA = 3,
  BBQ_SAUCE = 4,
  TABASCO = 5,
  SRIRACHA = 6,
}

export enum KeyTypeFlavorsEnum {
  BUTTER = 1,
  BUTTER_CHEESE = 2,
  ASIAGO_CHEESE = 3,
  RANCH = 4,
  ITALIAN_HERB = 5,
  CAJUN = 6,
  SESAME = 7,
  LEMON_ZEST = 8,
}

export enum TypeButtonEnum {
  LOGIN = 'login',
  SUBMIT = 'submit',
}

export enum KeySizePizzaEnum {
  SMALL = 1,
  MEDIUM = 2,
  LARGE = 3,
  X_LARGE = 4,
}

export enum SizeNumberPizzaEnum {
  SMALL = 10,
  MEDIUM = 12,
  LARGE = 14,
  X_LARGE = 16,
}

export enum ListedPricePizzaEnum {
  SMALL = 10,
  MEDIUM = 12,
  LARGE = 14,
  X_LARGE = 18,
}

// TODO: Update other KeyTypeEnum like this
export enum KeyTypeWingsEnum {
  BONELESS_WINGS = 1,
  HOWIE_WINGS = 2,
}

export enum ToppingSelectorPositionEnum {
  LEFT = 'Left',
  MID = 'Mid',
  RIGHT = 'Right',
}

export enum ToppingValueEnum {
  X1 = '1x',
  X2 = '2x',
  X3 = '3x',
  LIGHT = 'Light',
  NONE = 'None',
}

export enum DropdownOrderLookupEnum {
  ORDER_TYPE = 'Order type',
  STATUS = 'Status',
  SORT = 'Sort',
}

export enum KeyOptionOrderEnum {
  PEPPERONI = 1,
  SAUSAGE,
  BACON,
  KIELBASA,
  SPINACH,
  PINEAPPLE,
  EGGPLANT,
  POTATOES,
  JALAPENO,
  CAULIFLOWER,
  MUSHROOMS,
  RED_ONIONS,
  GREEN_PEPPERS,
  BLACK_OLIVES,
  TOMATOES,
  ITALIAN_SAUSAGE,
  GROUND_BEEF,
  GREEN_OLIVES,
  SHRIMP,
  CHICKEN,
  ANCHOVIES,
  CRAB,
  MACARONI,
  GARLIC,
  HAM,
}

export enum MeridiemCodeEnum {
  AM = 'AM',
  PM = 'PM',
}

export enum BackGroundColorEnum {
  JADE500 = 'jade500',
}

export enum TypeDateEnum {
  LOGIN = 'login',
  WELCOME = 'welcome',
}

export enum NameSelectBoxHeaderPizzaEnum {
  SPECIALTY = 'Specialty',
  SIZE = 'Size',
  CRUST_TYPE = 'Crust_Type',
  SAUCE = 'Sauce',
  FLAVORS = 'Flavors',
}

export enum NameSelectBoxHeaderWingsEnum {
  WINGS = 'Wings',
  SIDES = 'Sides',
  DRESSSING = 'Dresssing',
  PREFERENCE = 'Preference',
}

export enum NameSelectBoxHeaderPastaEnum {
  PASTA = 'Pasta',
  SIZE = 'Size',
  CHEESE = 'Cheese',
  SAUCE = 'Sauce',
  SIDE_BREAD = 'Side Bread',
}

export enum NameSelectBoxHeaderBowlEnum {
  ENTREE = 'Entree',
  SIZE = 'Size',
  SIDES = 'Sides',
  SAUCES = 'Sauces',
  SEASONING = 'Seasoning',
}

export enum KeyOptionOrderEnum {
  PIZZA = 'Pizza',
  FLATBREAD = 'Flatbread',
  SUBS = 'Subs',
  BREAD = 'Bread',
  BOWL = 'Bowl',
  SALADS = 'Salads',
  WINGS = 'Wings',
  DESSERTS = 'Desserts',
  DRINKS = 'Drinks',
  SECRET = 'Secret',
  PASTA = 'Pasta',
}

export enum KeyboardActionTextEnum {
  LOGIN = 'Login',
  CANCEL = 'Cancel',
  CLEAR = 'Clear',
  BACK = 'Back',
}

// DessertsPage
export enum NameSelectBoxHeaderDessertsEnum {
  SIZE = 'Size',
}

// Desserts Selector component enum
export enum KeyTypeChooseSizeDessertsEnum {
  NINE_INCHES = 1,
  TWELVE_INCHES = 2,
}

// TODO: Update key option
export enum KeyOptionsEntree {
  BBQ_CHICKEN = 1,
}

// TODO: Update key option
export enum KeyOptionsSize {
  SMALL = 1,
}

// TODO: Update key option
export enum KeyOptionsCrust {
  ORIGINAL = 1,
}

// TODO: Update key option
export enum KeyOptionsSauces {
  BBQ_SAUCE = 1,
}

// TODO: Update key option
export enum KeyOptionsPreference {
  LIGHT_DONE = 1,
}

export enum NameSelectBoxHeaderFlatbreadEnum {
  ENTREE = 'Entree',
  SIZE = 'Size',
  CRUST = 'Crust',
  SAUCES = 'Sauces',
  PREFERENCE = 'Preference',
}

export enum NameSelectBoxHeaderSaladsEnum {
  SALAD = 'Salad',
  SIZE = 'Size',
  CHEESE = 'Cheese',
  DRESSING = 'Dressing',
}
export enum NameSelectBoxHeaderSubsEnum {
  SUB = 'Sub',
  SIZE = 'Size',
  SIDES = 'Sides',
  SAUCES = 'Sauces',
  PREFERENCE = 'Preference',
}

export enum NameSelectBoxHeaderBreadEnum {
  ENTREE = 'Entree',
  SIZE = 'Size',
  SEASONING = 'Seasoning',
  SAUCES = 'Sauces',
}

export enum NameSelectBoxHeaderDrinksEnum {
  SIZE = 'Size',
}
