import { PropsWithChildren } from 'react';
import { useTouchScreen } from '~/utils/customHook';

type Props = {};

const Free = (props: PropsWithChildren<Props>) => {
  const isTouchScreen = useTouchScreen();

  return <>{props.children}</>;
};

export default Free;
