import { Listbox, Transition } from '@headlessui/react';
import classNames from 'classnames/bind';
import { Fragment, useEffect, useState } from 'react';

import { IconArrowDown } from '~/assets/svgComponents';
import { GREEN_0E8476 } from '~/utils/constants/color';
import { IItemSelected, SelectorData } from '~/utils/interface/common';
import styles from './SelectBox.module.scss';
import { NameSelectBoxHeaderPizzaEnum } from '~/utils/enum';
import { useDispatch, useSelector } from 'react-redux';
import { orderActions, selectIsResetOrderItemSelected } from '~/sagas/order/slice';

type SelectBoxProps = {
  name: string;
  data: SelectorData[];
  defaultValue?: SelectorData;
  emptyValue?: string;
  onChange?: (itemSelected: IItemSelected) => void;
};

const cx = classNames.bind(styles);

const SelectBox = (props: SelectBoxProps) => {
  const { name, data, defaultValue, emptyValue = 'None', onChange } = props;

  const dispatch = useDispatch();

  const isResetOderItem: boolean = useSelector(selectIsResetOrderItemSelected);

  const [hasValueSelected, setHasValueSelected] = useState<boolean>(false);
  const [isDisable, setIsDisable] = useState<boolean>(false);
  const [selectedValue, setSelectedValue] = useState<SelectorData>(() => {
    if (!defaultValue) {
      return {
        id: -1,
        content: emptyValue,
      };
    }
    return defaultValue;
  });

  useEffect(() => {
    if (!isResetOderItem) {
      return;
    }

    if (!defaultValue) {
      setSelectedValue({
        id: -1,
        content: emptyValue,
      });
      dispatch(orderActions.setIsResetOrderItem(false));

      return;
    }

    setSelectedValue(defaultValue);
    dispatch(orderActions.setIsResetOrderItem(false));
  }, [isResetOderItem]);

  useEffect(() => {
    onChange && onChange({ id: selectedValue.id, value: selectedValue.content, nameSelectBox: name });
  }, []);

  useEffect(() => {
    if (data.length) {
      // setSelected(data[defaultSelected].content);
      setIsDisable(false);
      return;
    }
    setIsDisable(true);
    // setSelected('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.length]);

  const handleOpenChange = (itemSelected: SelectorData) => {
    setSelectedValue(itemSelected);
    setHasValueSelected(true);
    onChange && onChange({ id: itemSelected.id, value: itemSelected.content, nameSelectBox: name });
  };

  const handleSelectStyleButton = (payload: { open: boolean; hasValueSelected: boolean; isDisable: boolean }) => {
    const { open, hasValueSelected, isDisable } = payload;

    if ((selectedValue.content === 'None' || selectedValue.content === defaultValue?.content) && !open) {
      return '';
    }

    if (open) {
      return 'open-dropdown-style';
    }
    if (hasValueSelected) {
      return 'bg-has-value';
    }
    if (isDisable) {
      return 'disable-style';
    }
  };

  const handleSelectStyleText = (payload: { open: boolean }) => {
    const { open } = payload;

    if (open) {
      return 'open-dropdown-text-style';
    }
    return 'text-style';
  };

  return (
    <div className={cx('selector-container')}>
      <Listbox value={selectedValue} onChange={handleOpenChange} disabled={isDisable} defaultValue={defaultValue}>
        {/* Display value */}
        <Listbox.Button className={cx('selector-btn-wrap')}>
          {({ open }) => (
            <div className={cx(['selector-btn', handleSelectStyleButton({ open, hasValueSelected, isDisable })])}>
              <div className={cx('selector-value-group')}>
                <span className={cx([handleSelectStyleText({ open }), 'selector-title', 'truncate'])}>{name}</span>
                <span className={cx([handleSelectStyleText({ open }), 'selector-value', 'truncate'])}>
                  {selectedValue.content}
                </span>
              </div>

              <span className={open ? cx('selector-arrow-rotate') : ''}>
                <IconArrowDown strokePath={open ? GREEN_0E8476 : 'white'} />
              </span>
            </div>
          )}
        </Listbox.Button>

        {/* Options */}
        <Transition as={Fragment} leave='transition ease-in duration-100' leaveFrom='opacity-100' leaveTo='opacity-0'>
          <Listbox.Options className={cx('selector-list')}>
            {data.map((item) => (
              <Listbox.Option className={cx('selector-item')} key={item.id} value={item}>
                {item.content}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </Listbox>
    </div>
  );
};

export default SelectBox;
