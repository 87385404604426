import { MouseEvent } from 'react';
import classNames from 'classnames/bind';
import styles from './Table.module.scss';
import { dataTableOrderLookup, footerOrderButton, footerOrderButtonSaveAndReturn } from '~/utils/constants/mockData';
import { TypeFooterButtonEnum } from '~/utils/enum';

type TableProps = {};

const cx = classNames.bind(styles);

const Table = (props: TableProps) => {
  return (
    <table id='tableOrderLookup' className={cx('table-order')}>
      <thead className=''>
        <tr>
          <th scope='col' className={cx('table-order-thead')}>
            ID
          </th>
          <th scope='col' className={cx('table-order-thead')}>
            Time
          </th>
          <th scope='col' className={cx('table-order-thead')}>
            Type
          </th>
          <th scope='col' className={cx('table-order-thead')}>
            Table
          </th>
          <th scope='col' className={cx('table-order-thead')}>
            Customer
          </th>
          <th scope='col' className={cx('table-order-thead')}>
            Paid
          </th>
          <th scope='col' className={cx('table-order-thead')}>
            Total
          </th>
        </tr>
      </thead>
      <tbody>
        {dataTableOrderLookup.map((item, index) => (
          <tr className={cx('table-order-trow')}>
            <td className={cx('table-order-cell')}>{item.id}</td>
            <td className={cx('table-order-cell')}>{item.time}</td>
            <td className={cx('table-order-cell')}>{item.type}</td>
            <td className={cx('table-order-cell')}>{item.table}</td>
            <td className={cx(['table-order-cell', 'table-order-cell-custom'])}>
              <div className={cx('table-order-cell-custom-info-wrap')}>
                <span className={cx('custom-info-wrap-item')}>{item.customerContent}</span>
                <span className={cx('custom-info-wrap-sub-item')}>{item.customerSubContent}</span>
              </div>
            </td>
            <td className={cx('table-order-cell')}>{item.paid}</td>
            <td className={cx('table-order-cell')}>{item.total}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default Table;
