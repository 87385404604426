import classNames from 'classnames/bind';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ButtonSubmit from '~/components/specific/buttonSubmit/ButtonSubmit';
import ButtonTop from '~/components/specific/buttonTop/ButtonTop';
import ContentButton from '~/components/specific/contentButton/ContentButton';
import { orderActions, selectOrder, selectTypePizzaSelected } from '~/sagas/order/slice';
import { IItemOrder } from '~/sagas/order/state';
import { pizzaActions, selectIsAddPizzaEvent, selectIsReload } from '~/sagas/pizza/slice';
import { buttonTop, contentButton, customPizzaButton } from '~/utils/constants/mockData';
import { KeyOptionOrderEnum, KeySizePizzaEnum } from '~/utils/enum';
import styles from './PizzaPage.module.scss';
import OptionsOrderPizza from './optionsOrderPizza/OptionsOrderPizza';

const cx = classNames.bind(styles);

const PizzaPage = () => {
  const dispatch = useDispatch();

  const orderSelector: IItemOrder[] = useSelector(selectOrder);
  const isAddNewPizza: boolean = useSelector(selectIsAddPizzaEvent);
  const typePizzaSelected: number = useSelector(selectTypePizzaSelected);
  const isReload: boolean = useSelector(selectIsReload);

  const [selectedSizePizza, setSelectedSizePizza] = useState<KeySizePizzaEnum>(KeySizePizzaEnum.LARGE);
  const [selectedTypePizza, setSelectedTypePizza] = useState<number>();
  const [isShowOptions, setIsShowOptions] = useState<boolean>(false);

  useEffect(() => {
    setSelectedTypePizza(typePizzaSelected);
  }, [typePizzaSelected]);

  useEffect(() => {
    if (isReload) {
      setIsShowOptions(false);
      dispatch(pizzaActions.setIsReload(false));
      dispatch(pizzaActions.setIsCustomPizza(false));
    }
  }, [isReload]);

  useEffect(() => {
    if (orderSelector.length === 0) {
      setIsShowOptions(false);
    }
  }, [orderSelector]);

  useEffect(() => {
    if (!isAddNewPizza) {
      return;
    }
    setIsShowOptions(false);
    dispatch(pizzaActions.setAddPizzaAction(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAddNewPizza]);

  const handleSelectPizzaType = (payload: { id: number; content: string }) => {
    const { id, content } = payload;
    setIsShowOptions(true);
    setSelectedTypePizza(id);
    const itemSizeSelected = buttonTop.find((x) => x.id === selectedSizePizza);
    if (!itemSizeSelected) {
      return;
    }
    const pizzaSelected: IItemOrder = {
      id: Date.now(),
      name: content,
      size: itemSizeSelected.size,
      money: itemSizeSelected.size,
      type: KeyOptionOrderEnum.PIZZA,
      quantity: 1,
      toping: [],
    };
    dispatch(orderActions.addToOrder(pizzaSelected));
    dispatch(orderActions.setPizzaSelected(id));
    // dispatch(pizzaActions.setStateNamePizza(content));
  };

  const handleChangeSizePizza = (id: number) => {
    setSelectedSizePizza(id);
    dispatch(pizzaActions.setStateSizePizzaKey(id));
  };

  const handleClickCustomPizza = () => {
    dispatch(orderActions.setPizzaSelected(null));
    dispatch(pizzaActions.setIsCustomPizza(true));
    setIsShowOptions(true);
  };

  return (
    <div className={cx('page-container')}>
      {isShowOptions ? (
        <OptionsOrderPizza typePizzaId={selectedTypePizza} />
      ) : (
        <>
          <div className={cx('page-head-wrap')}>
            {buttonTop.map((item, index) => (
              <div key={item.id}>
                <ButtonTop
                  title={item.title}
                  subTitle={item.subTitle}
                  isActive={item.id === selectedSizePizza}
                  onClick={() => handleChangeSizePizza(item.id)}
                />
              </div>
            ))}
          </div>

          <div className={cx('page-divide-line')} />

          <div className={cx('page-body-wrap')}>
            {contentButton.map((item) => (
              <ContentButton key={item.id} buttonText={item.content} onClick={() => handleSelectPizzaType(item)} />
            ))}
          </div>

          <ButtonSubmit title={customPizzaButton} onClick={handleClickCustomPizza} />
        </>
      )}
    </div>
  );
};

export default PizzaPage;
