import classNames from 'classnames/bind';
import styles from './Delivery.module.scss';

type DeliveryProps = {
  icon: string;
  title: string;
};

const cx = classNames.bind(styles);

const Delivery = (props: DeliveryProps) => {
  const { icon, title } = props;
  return (
    <div id='deliveryComponent' className={cx('delivery-container')}>
      <div className={cx('delivery-icon-wrap')}>
        <img src={icon} alt='icon' className={cx('delivery-icon')} />
      </div>
      <p className={cx('delivery-text-content')}>{title}</p>
    </div>
  );
};

export default Delivery;
