import classNames from 'classnames/bind';
import { useEffect, useState } from 'react';

import styles from './Time.module.scss';
import {
  HOURS_IN_HAFT_A_DAY,
  LENGTH_OF_THE_TIME_SECONDS,
  TIME_SECONDS_INTERVAL_GET_TIME,
  VALUE_PAD_OF_THE_TIME_SECONDS,
} from '~/utils/constants/mockData';
import { MeridiemCodeEnum } from '~/utils/enum';

type TimeProps = {
  isLogin?: boolean;
};

const cx = classNames.bind(styles);

const Time = (props: TimeProps) => {
  const { isLogin = false } = props;

  const [currentTime, setCurrentTime] = useState(new Date());
  const [styleTime, setStyleTime] = useState<string>('time-time-display');
  const [styleMeridiem, setStyleMeridiem] = useState<string>('time-meridiem');

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(new Date());
    }, TIME_SECONDS_INTERVAL_GET_TIME);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (isLogin) {
      setStyleTime('time-time-display');
      setStyleMeridiem('time-meridiem');
    } else {
      setStyleTime('time-time-display-less');
      setStyleMeridiem('time-meridiem-less');
    }
  }, [isLogin]);

  const displayTime = (): string => {
    const hours = currentTime.getHours();
    const minutes = currentTime.getMinutes();
    const formattedHours = (hours % HOURS_IN_HAFT_A_DAY || HOURS_IN_HAFT_A_DAY).toString();
    const formattedMinutes = minutes.toString().padStart(LENGTH_OF_THE_TIME_SECONDS, VALUE_PAD_OF_THE_TIME_SECONDS);
    return `${formattedHours}:${formattedMinutes}`;
  };

  const displayMeridiem = (): string => {
    const hours = currentTime.getHours();
    const meridiem = hours >= HOURS_IN_HAFT_A_DAY ? MeridiemCodeEnum.PM : MeridiemCodeEnum.AM;
    return meridiem;
  };

  return (
    <div id='timeComponent' className={cx('time-container')}>
      <span className={cx(styleTime)}>{displayTime()}</span>
      <span className={cx(styleMeridiem)}>{displayMeridiem()}</span>
    </div>
  );
};

export default Time;
