import { useEffect, useState } from 'react';
import classNames from 'classnames/bind';

import icons from '~/assets/icons';
import styles from './OrderMethodRow.module.scss';
import { orderTypeSidebar } from '~/utils/constants/mockData';

interface Options {
  id: number;
  value: string;
}

interface Option {
  id: number;
  title: string;
  isActive: boolean;
}

const cx = classNames.bind(styles);

const OrderMethodRow = () => {
  const [isShowOptions, setIsShowOptions] = useState<boolean>(false);
  const [options, setOptions] = useState(orderTypeSidebar);
  const [optionSelected, setOptionSelected] = useState<Option>();
  const [activeArrow, setActiveArrow] = useState<boolean>(false);

  useEffect(() => {
    const optionSelected = options?.find((option) => option.isActive);

    setOptionSelected(optionSelected);
  }, []);

  useEffect(() => {
    const firstThreeElements = options?.slice(0, 3);
    const checkIsActive = firstThreeElements?.find((option) => option.id === optionSelected?.id);

    if (optionSelected && checkIsActive === undefined) {
      setActiveArrow(true);
    } else {
      setActiveArrow(false);
    }
  }, [options, optionSelected]);

  const handleShowOptions = () => {
    setIsShowOptions(!isShowOptions);
  };

  const handleSelectOrderType = (id: number) => {
    const newData = options?.map((option) => {
      if (option.id === id) {
        setOptionSelected(option);

        return { ...option, isActive: true };
      }
      return { ...option, isActive: false };
    });

    setOptions(newData);
    setIsShowOptions(false);
  };

  return (
    <div className={cx('order-method-container')}>
      <div className={cx('order-method-wrap')}>
        {options?.slice(0, 3)?.map((option) => (
          <div
            key={option.id}
            className={cx(['ord-text-style', 'order-type', option.isActive && 'active'])}
            onClick={() => handleSelectOrderType(option.id)}
          >
            {option.title}
          </div>
        ))}

        <div
          className={cx(['ord-text-style', 'ord-arrow-down', activeArrow && 'ord-dropdown-active'])}
          onClick={handleShowOptions}
        >
          <img className={isShowOptions ? cx('ord-arrow-show') : ''} src={icons.arrowDownSvg} alt='arrowDownSvg' />
        </div>
      </div>

      {isShowOptions && (
        <ul className={cx('ord-options')}>
          {options?.slice(-3)?.map((option) => (
            <li className={cx('ord-option')} key={option.id}>
              <div className={cx('ord-option-checkbox-wrap')} onClick={() => handleSelectOrderType(option.id)}>
                <div className={cx(['ord-option-checkbox', option.isActive && 'ord-option-checked'])}></div>
              </div>
              <span className={cx(['ord-option-label'])}>{option.title}</span>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default OrderMethodRow;
