import classNames from 'classnames/bind';
import { ChangeEvent, Dispatch, SetStateAction } from 'react';

import styles from './InputPassword.module.scss';
import { incorrectPasswordMessage } from '~/utils/constants/common';

interface Props {
  inputValue: string;
  setInputValue: Dispatch<SetStateAction<string>>;
  isShowError: boolean;
  isPassword?: boolean;
}

const cx = classNames.bind(styles);

const InputPassword = (props: Props) => {
  const { inputValue, setInputValue, isShowError, isPassword } = props;

  const handleOnchange = (event: ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  return (
    <div id='inputPassword'>
      <input
        type={isPassword ? 'password' : ''}
        value={inputValue}
        onChange={handleOnchange}
        className={cx('input-password')}
      />
      {isShowError && <p className={cx('input-password-message')}>{incorrectPasswordMessage}</p>}
    </div>
  );
};

export default InputPassword;
