import React, { useState } from 'react';
import classNames from 'classnames/bind';
import styles from './inputCode.module.scss';
import { MAX_LENGTH_INPUT_CODE } from '~/utils/constants/mockData';

type InputCodeProps = {
  title?: string | number;
  value?: string;
};

const cx = classNames.bind(styles);

const InputCode = (props: InputCodeProps) => {
  const { title, value } = props;

  const [inputValue, setInputValue] = useState('');
  const [displayValue, setDisplayValue] = useState('');

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setInputValue(value);
    const maskedValue = '*'.repeat(value.length);
    setDisplayValue(maskedValue);
  };

  return (
    <div id='inputCode' className={cx('input-code-container')}>
      {title ? (
        <>
          <p className={cx('input-code-title')}>{title}</p>
          <input className={cx('input-general', 'input-code')} value={displayValue} onChange={handleChange} maxLength={MAX_LENGTH_INPUT_CODE} />
        </>
      ) : (
        <input className={cx('input-general', 'input-code-order-lookup')} value={value} />
      )}
    </div>
  );
};

export default InputCode;
