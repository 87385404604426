import { useEffect, useState } from 'react';
import classNames from 'classnames/bind';

import { OrderInfoRow, OrderMethodRow, OrderPriceRow } from './components';
import { FoodOrder } from '~/components/specific';

import { SidebarContext } from '~/context';
import styles from './Sidebar.module.scss';
import { useSelector } from 'react-redux';
import { selectNamePizza, selectSizePizzaKey } from '~/sagas/pizza/slice';
import { KeySizePizzaEnum, ListedPricePizzaEnum, SizeNumberPizzaEnum } from '~/utils/enum';
import { selectOrder } from '~/sagas/order/slice';
import { IItemOrder, IOrder } from '~/sagas/order/state';
import { defaultTax } from '~/utils/constants/common';

const cx = classNames.bind(styles);

const Sidebar = () => {
  const namePizzaSelected = useSelector(selectNamePizza);
  const sizePizzaKeySelected = useSelector(selectSizePizzaKey);
  const orderSelector: IItemOrder[] = useSelector(selectOrder);

  const [price, setPrice] = useState<number>(SizeNumberPizzaEnum.LARGE);
  const [sizePizzaNumber, setSizePizzaNumber] = useState<number>();
  const [listedPrice, setListedPrice] = useState<number>();
  const [subTotal, setSubTotal] = useState<number>(0);

  useEffect(() => {
    const priceItemOrder = orderSelector.reduce((accumulator, currentValue) => {
      const money = currentValue.money * currentValue.quantity;
      const totalPriceToping = currentValue.toping.reduce((prev, item) => {
        return prev + item.money;
      }, 0);

      const total = money + totalPriceToping;
      return accumulator + total;
    }, 0);

    setSubTotal(priceItemOrder);
  }, [orderSelector]);

  useEffect(() => {
    switch (sizePizzaKeySelected) {
      case KeySizePizzaEnum.SMALL:
        setSizePizzaNumber(SizeNumberPizzaEnum.SMALL);
        setListedPrice(ListedPricePizzaEnum.SMALL);
        return;

      case KeySizePizzaEnum.MEDIUM:
        setSizePizzaNumber(SizeNumberPizzaEnum.MEDIUM);
        setListedPrice(ListedPricePizzaEnum.MEDIUM);
        return;

      case KeySizePizzaEnum.LARGE:
        setSizePizzaNumber(SizeNumberPizzaEnum.LARGE);
        setListedPrice(ListedPricePizzaEnum.LARGE);
        return;

      case KeySizePizzaEnum.X_LARGE:
        setSizePizzaNumber(SizeNumberPizzaEnum.X_LARGE);
        setListedPrice(ListedPricePizzaEnum.X_LARGE);
        return;
      default:
        setSizePizzaNumber(SizeNumberPizzaEnum.LARGE);
        setListedPrice(ListedPricePizzaEnum.LARGE);
    }
  }, [sizePizzaKeySelected]);

  useEffect(() => {
    if (!namePizzaSelected || !sizePizzaKeySelected) {
      setPrice(0);
    }
  }, [namePizzaSelected, sizePizzaKeySelected]);

  return (
    <div className={cx('sidebar-wrap')}>
      <SidebarContext.Provider value={{ listedPrice, price, setPrice }}>
        {/* Order Info*/}
        <OrderInfoRow />

        {/* Order Method */}
        <OrderMethodRow />

        {/* Content */}
        <div className={cx('main-content-wrap')}>
          {orderSelector.map((item: IItemOrder) => (
            <FoodOrder
              key={item.id}
              id={item.id}
              pizzaName={item.name}
              pizzaSize={item.size ?? 0}
              money={item.money}
              toping={item.toping}
            />
          ))}
        </div>

        {/* Order Price */}
        <OrderPriceRow subTotal={subTotal} tax={subTotal * defaultTax} />
      </SidebarContext.Provider>
    </div>
  );
};

export default Sidebar;
