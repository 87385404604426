import { useContext, useEffect, useState } from 'react';
import classNames from 'classnames/bind';

import SelectOption from '../selectOption/SelectOption';
import { SidebarContext } from '~/context';
import { formatNumberToCurrency } from '~/utils/helper';
import { foodOrderOptions } from '~/utils/constants/mockData';
import icons from '~/assets/icons';
import styles from './FootOrder.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { pizzaActions } from '~/sagas/pizza/slice';
import { orderActions, selectOrder } from '~/sagas/order/slice';
import { IItemOrder, IToping } from '~/sagas/order/state';

interface FoodOrderProps {
  id: number;
  pizzaSize: number | string;
  pizzaName: string;
  money: number;
  toping: IToping[];
}

const cx = classNames.bind(styles);

const FoodOrder = (props: FoodOrderProps) => {
  const { pizzaSize, pizzaName, id, toping, money } = props;

  const dispatch = useDispatch();

  const orderSelector: IItemOrder[] = useSelector(selectOrder);

  const { listedPrice, price, setPrice } = useContext(SidebarContext);
  const [selected, setSelected] = useState<number>(foodOrderOptions[0].value);

  useEffect(() => {
    if (selected <= 0) {
      return;
    }
    const newPrice = listedPrice * selected;
    const priceToping = toping.reduce((accumulator, currentValue) => {
      return accumulator + currentValue.money;
    }, 0);

    setPrice(newPrice + priceToping);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listedPrice, selected]);

  const handleDeletePizza = () => {
    dispatch(orderActions.removeItemToOrder(id));
  };

  const handleChangeQuantity = (val: number) => {
    const dataUpdate = {
      id: id,
      newQuantity: val,
    };
    dispatch(orderActions.updateQuantityItem(dataUpdate));
  };

  const handleClickDeleteToping = (idToping: number) => {
    const id = orderSelector[0].id;
    const payloadRemoveToping = {
      id,
      idToping,
    };
    dispatch(orderActions.removeTopingOrder(payloadRemoveToping));
  };

  return (
    <>
      <div id='food-order' className={cx('food-order-container')}>
        <div className={cx('food-order-content')}>
          {pizzaSize ? (
            <div className={cx('food-order-title')}>{`${pizzaSize}” ${pizzaName}`}</div>
          ) : (
            <div className={cx('food-order-title')}>{`${pizzaSize} ${pizzaName}`}</div>
          )}
        </div>

        <div className={cx('food-order-select-container')}>
          <div className={cx('food-order-title-price')}>{`${formatNumberToCurrency(money)}`}</div>

          <SelectOption
            data={foodOrderOptions}
            selected={selected}
            setSelected={setSelected}
            onChange={handleChangeQuantity}
          />

          <button className={cx('food-order-btn-delete')} onClick={handleDeletePizza}>
            <img src={icons.trashSvg} alt='trashSvg' className={cx('icon-trash')} />
          </button>
        </div>
      </div>

      {toping.map((it) => (
        <div key={it.id} className={cx('food-order-list-topping')}>
          <button className={cx('food-order-del-topping')} onClick={() => handleClickDeleteToping(it.id)}>
            <img src={icons.crossSvg} alt='crossSvg' className={cx('icon-cross')} />
          </button>
          <div className={cx('food-order-topping-info')}>
            <span className={cx(['topping-info', 'text-style'])}>{it.name}</span>
            <span className={cx(['topping-price', 'text-style'])}>$2.00</span>
          </div>
        </div>
      ))}
    </>
  );
};

export default FoodOrder;
