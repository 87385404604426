import classNames from 'classnames/bind';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import ButtonPrimary from '~/components/specific/buttonPrimary/ButtonPrimary';
import InputPassword from '~/components/specific/inputPassword/InputPassword';

import styles from './Landing.module.scss';
import { PASSWORDS_LANDING_PAGE } from '~/utils/constants/mockData';
import { userRoute } from '~/utils/constants/route';
import icons from '~/assets/icons';

const cx = classNames.bind(styles);

const Landing = () => {
  const navigate = useNavigate();
  const [password, setPassword] = useState<string>('');
  const [isShowError, setIsShowError] = useState<boolean>(false);

  const handleSubmit = (event: any) => {
    event.preventDefault();

    if (password === PASSWORDS_LANDING_PAGE) {
      setIsShowError(false);
      // navigate(`/`);
      navigate(`${userRoute.base}${userRoute.pizza}`);
    }

    if (password !== PASSWORDS_LANDING_PAGE) {
      setIsShowError(true);
    }
  };

  return (
    <div id='landingPage' className={cx('page-container')}>
      {/* <div className={cx('page-content')}> */}
      <div className={cx('page-logo-icon')}>
        <img src={icons.logoSvg} alt='logoSvg' className={cx('logo-icon')} />
      </div>
      <h3 className={cx('page-content-title')}>Enter Password</h3>

      <form className={cx('form-password')} onSubmit={handleSubmit}>
        <div className={cx('form-password-item')}>
          <InputPassword isPassword isShowError={isShowError} inputValue={password} setInputValue={setPassword} />
        </div>

        <div className={cx('form-password-button-wrap')}>
          <ButtonPrimary title='Login' />
        </div>
      </form>
      {/* </div> */}
    </div>
  );
};

export default Landing;
