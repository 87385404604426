import { MouseEvent, useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import styles from './Footer.module.scss';
import { FooterButton } from '~/components/specific';
import { footerAddButton, footerButton, footerButtonSend, footerPayNowButton } from '~/utils/constants/mockData';
import { TypeFooterButtonEnum } from '~/utils/enum';
import icons from '~/assets/icons';
import DialogButtonSend from './components/dialogButtonSend/DialogButtonSend';
import { IItemOrder } from '~/sagas/order/state';
import { useDispatch, useSelector } from 'react-redux';
import { orderActions, selectOrder } from '~/sagas/order/slice';
import { useLocation, useNavigate } from 'react-router-dom';
import { userRoute } from '~/utils/constants/route';
import { pizzaActions } from '~/sagas/pizza/slice';

const cx = classNames.bind(styles);

const Footer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const orderSelector: IItemOrder[] = useSelector(selectOrder);

  const [isDialogOpen, setDialogOpen] = useState(false);
  const [isDisabledSend, setIsDisabledSend] = useState<boolean>(orderSelector.length === 0 ? true : false);

  useEffect(() => {
    if (orderSelector.length > 0) {
      setIsDisabledSend(false);
    } else {
      setIsDisabledSend(true);
    }
  }, [orderSelector]);

  const handleSendButtonClick = () => {
    dispatch(orderActions.resetStateOrder());
    setDialogOpen(true);
  };

  const handleAddButtonClick = () => {
    const isPizzaPage = location.pathname === `${userRoute.base}${userRoute.pizza}`;

    if (isPizzaPage) {
      dispatch(pizzaActions.setAddPizzaAction(true));
      dispatch(pizzaActions.setIsCustomPizza(false));
      return;
    }
    dispatch(pizzaActions.setIsCustomPizza(false));
    navigate(`${userRoute.base}${userRoute.pizza}`);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleClickOrderLookup = () => {
    navigate('/order-lookup');
  };

  const handleClick = (id: number) => {
    switch (id) {
      case 8:
        handleClickOrderLookup();
        break;
      case 10:
        handleAddButtonClick();
        break;
    }
  };

  return (
    <div id='footer' className={cx('footer-container')}>
      <FooterButton
        content={<img src={icons.arrowUpSvg} alt='arrowUpSvg' className={cx('arrow-up')} />}
        type={TypeFooterButtonEnum.ARROW}
      />
      <div className={cx('footer-group-button')}>
        {footerButton.map((item, _) => {
          let updateType: TypeFooterButtonEnum = item.type;
          if ((item.content === footerPayNowButton || item.content === footerAddButton) && !isDisabledSend) {
            updateType = TypeFooterButtonEnum.ADD;
          }

          return (
            <div key={item.id}>
              <FooterButton content={item.content} type={updateType} onClick={() => handleClick(item.id)} />
            </div>
          );
        })}
      </div>
      <div className={cx('footer-button-send')}>
        <FooterButton
          disabled={isDisabledSend}
          content={footerButtonSend}
          type={TypeFooterButtonEnum.SEND}
          onClick={handleSendButtonClick}
        />
      </div>

      <DialogButtonSend isOpen={isDialogOpen} onClose={handleCloseDialog} />
    </div>
  );
};

export default Footer;
