import { Listbox, Transition } from '@headlessui/react';
import classNames from 'classnames/bind';
import { Fragment, useEffect, useState } from 'react';

import { IconArrowDown } from '~/assets/svgComponents';
import { GREEN_0E8476 } from '~/utils/constants/color';
import { SelectorData } from '~/utils/interface/common';
import styles from './Selector.module.scss';

export type SelectorProps = {
  name: string;
  data: SelectorData[];
  selected?: string;
  setSelected: React.Dispatch<React.SetStateAction<any>>;
  defaultSelected?: number;
  emptyValue?: string;
};

const cx = classNames.bind(styles);

const Selector = (props: SelectorProps) => {
  const { name, data, selected, setSelected, defaultSelected = 0, emptyValue = 'None' } = props;
  const [hasValueSelected, setHasValueSelected] = useState<boolean>(false);
  const [isDisable, setIsDisable] = useState<boolean>(false);

  const handleOpenChange = (value: string) => {
    setSelected(value);
    setHasValueSelected(true);
  };

  useEffect(() => {
    if (data.length) {
      setSelected(data[defaultSelected].content);
      setIsDisable(false);
      return;
    }
    setIsDisable(true);
    setSelected('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.length]);

  const handleSelectStyleButton = (payload: { open: boolean; hasValueSelected: boolean; isDisable: boolean }) => {
    const { open, hasValueSelected, isDisable } = payload;

    if (open) {
      return 'open-dropdown-style';
    }
    if (hasValueSelected) {
      return 'bg-has-value';
    }
    if (isDisable) {
      return 'disable-style';
    }
  };

  const handleSelectStyleText = (payload: { open: boolean }) => {
    const { open } = payload;

    if (open) {
      return 'open-dropdown-text-style';
    }
    return 'text-style';
  };

  return (
    <div className={cx('selector-container')}>
      <Listbox value={selected} onChange={handleOpenChange} disabled={isDisable} defaultValue={emptyValue}>
        {/* Display value */}
        <Listbox.Button className={cx('selector-btn-wrap')}>
          {({ open }) => (
            <div className={cx(['selector-btn', handleSelectStyleButton({ open, hasValueSelected, isDisable })])}>
              <div className={cx('selector-value-group')}>
                <span className={cx([handleSelectStyleText({ open }), 'selector-title', 'truncate'])}>{name}</span>
                <span className={cx([handleSelectStyleText({ open }), 'selector-value', 'truncate'])}>
                  {selected || emptyValue}
                </span>
              </div>

              <span className={open ? cx('selector-arrow-rotate') : ''}>
                <IconArrowDown strokePath={open ? GREEN_0E8476 : 'white'} />
              </span>
            </div>
          )}
        </Listbox.Button>

        {/* Options */}
        <Transition as={Fragment} leave='transition ease-in duration-100' leaveFrom='opacity-100' leaveTo='opacity-0'>
          <Listbox.Options className={cx('selector-list')}>
            {data.map((item) => (
              <Listbox.Option className={cx('selector-item')} key={item.id} value={item.content}>
                {item.content}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </Listbox>
    </div>
  );
};

export default Selector;
