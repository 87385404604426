import classNames from 'classnames/bind';
import styles from './WelcomePage.module.scss';
import DateTitle from '~/components/specific/dateTitle/DateTitle';
import Time from '~/components/specific/time/Time';
import Greetings from '~/components/specific/greetings/Greetings';
import Delivery from './components/delivery/Delivery';
import { TypeDateEnum } from '~/utils/enum';
import { TITLE_GREETINGS_WELCOME, menuWelcomePage } from '~/utils/constants/mockData';
import Footer from './components/footer/Footer';

interface Props {}

const cx = classNames.bind(styles);

const WelcomePage = (props: Props) => {
  return (
    <div id='welcomePage' className={cx('welcome-container')}>
      <div className={cx('welcome-content')}>
        <div className={cx('welcome-content-greetings')}>
          <div className={cx('welcome-content-greetings-date')}>
            <DateTitle type={TypeDateEnum.WELCOME} />
          </div>
          <div className={cx('welcome-content-greetings-time')}>
            <Time />
          </div>
          <div className={cx('welcome-content-greetings-title')}>
            <Greetings isDisplaySun={false} isWelcome={true} title={TITLE_GREETINGS_WELCOME} />
          </div>
        </div>

        <div className={cx('welcome-content-function')}>
          {menuWelcomePage.map((item, index) => (
            <Delivery icon={item.icon} title={item.title} />
          ))}
        </div>
      </div>

      <div className={cx('welcome-footer')}><Footer /></div>
    </div>
  );
};

export default WelcomePage;
