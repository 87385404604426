import {
  IconBowlSvg,
  IconBreadSvg,
  IconDrinksSvg,
  IconFlatbreadSvg,
  IconPastaSvg,
  IconPizzaSvg,
  IconSaladsSvg,
  IconSecretSvg,
  IconSubsSvg,
  IconWingsSvg,
} from '~/assets/svgComponents';
import IconDessertSvg from '~/assets/svgComponents/iconDesserts';
import { NotFoundPage } from '~/components/common';
import { FreeLayout, MainLayout } from '~/layouts';
import {
  About,
  BowlsPage,
  DessertsPage,
  DrinksPage,
  LandingPage,
  LoginAdmin,
  LoginUser,
  EmployeeID,
  OrderLookupPage,
  PizzaPage,
  SecretPage,
  SubsPage,
  WelcomePage,
  WingsPage,
  FlatbreadPage,
  SaladsPage,
  PastaPage,
  BreadPage,
} from '~/pages';
import { PublicRoutes } from '~utils/interface/route';

// Router && Path
export const adminRoute = {
  base: '/admin',
  login: '/login',
};

export const userRoute = {
  base: '/',
  landing: 'landing',
  login: 'login',
  employeeID: 'employee-id',
  pizza: 'pizza',
  flatbread: 'flatbread',
  subs: 'subs',
  bread: 'bread',
  bowl: 'bowl',
  salads: 'salads',
  wings: 'wings',
  desserts: 'desserts',
  drinks: 'drinks',
  secret: 'secret',
  pasta: 'pasta',
  orderLookup: 'order-lookup',
  welcome: 'welcome',
};

export const publicRoutesAdmin = [
  {
    path: '*',
    component: NotFoundPage,
    layout: FreeLayout,
  },
  {
    path: `${adminRoute.base}`,
    component: LoginAdmin,
    layout: FreeLayout,
  },
  {
    path: `${adminRoute.base}${userRoute.login}`,
    component: LoginAdmin,
    layout: FreeLayout,
  },
];

export const publicRoutesUser = [
  {
    path: '*',
    component: NotFoundPage,
    layout: FreeLayout,
  },
  {
    // path: `${userRoute.base}${userRoute.landing}`,
    path: `${userRoute.base}`,
    component: LandingPage,
    layout: FreeLayout,
  },
  {
    path: `${userRoute.base}${userRoute.login}`,
    component: LoginUser,
    layout: FreeLayout,
  },
  {
    path: `${userRoute.base}${userRoute.employeeID}`,
    component: EmployeeID,
    layout: FreeLayout,
  },
  {
    name: 'Pizza',
    // path: `${userRoute.base}`,
    path: `${userRoute.base}${userRoute.pizza}`,
    component: PizzaPage,
    layout: MainLayout,
    icon: IconPizzaSvg,
  },
  {
    name: 'Flatbread',
    path: `${userRoute.base}${userRoute.flatbread}`,
    component: FlatbreadPage,
    layout: MainLayout,
    icon: IconFlatbreadSvg,
  },
  {
    name: 'Subs',
    path: `${userRoute.base}${userRoute.subs}`,
    component: SubsPage,
    layout: MainLayout,
    icon: IconSubsSvg,
  },
  {
    name: 'Bread',
    path: `${userRoute.base}${userRoute.bread}`,
    component: BreadPage,
    layout: MainLayout,
    icon: IconBreadSvg,
  },
  {
    name: 'Bowl',
    path: `${userRoute.base}${userRoute.bowl}`,
    component: BowlsPage,
    layout: MainLayout,
    icon: IconBowlSvg,
  },
  {
    name: 'Salads',
    path: `${userRoute.base}${userRoute.salads}`,
    component: SaladsPage,
    layout: MainLayout,
    icon: IconSaladsSvg,
  },
  {
    name: 'Wings',
    path: `${userRoute.base}${userRoute.wings}`,
    component: WingsPage,
    layout: MainLayout,
    icon: IconWingsSvg,
  },
  {
    name: 'Desserts',
    path: `${userRoute.base}${userRoute.desserts}`,
    component: DessertsPage,
    layout: MainLayout,
    icon: IconDessertSvg,
  },
  {
    name: 'Drinks',
    path: `${userRoute.base}${userRoute.drinks}`,
    component: DrinksPage,
    layout: MainLayout,
    icon: IconDrinksSvg,
  },
  {
    name: 'Secret',
    path: `${userRoute.base}${userRoute.secret}`,
    component: SecretPage,
    layout: MainLayout,
    icon: IconSecretSvg,
  },
  {
    name: 'Pasta',
    path: `${userRoute.base}${userRoute.pasta}`,
    component: PastaPage,
    layout: MainLayout,
    icon: IconPastaSvg,
  },
  {
    path: `${userRoute.base}${userRoute.orderLookup}`,
    component: OrderLookupPage,
    layout: FreeLayout,
  },
  {
    path: `${userRoute.base}${userRoute.welcome}`,
    component: WelcomePage,
    layout: FreeLayout,
  },
];

export const publicRoutes: PublicRoutes[] = [...publicRoutesAdmin, ...publicRoutesUser];

export const privateRoutes = [];
