import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './state';

const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    addToOrder(state, action) {
      state.items = [action.payload, ...state.items];
    },
    removeItemToOrder(state, action) {
      const idItem = action.payload;
      const indexItem = state.items.findIndex((x) => x.id === idItem);
      if (indexItem > -1) {
        state.items.splice(indexItem, 1);
        state.isResetOrderItem = true;
      }
    },
    updateSizeItem(state, action) {
      const { id, newSize, money } = action.payload;
      state.items.forEach((x) => {
        if (x.id === id) {
          x.size = newSize;
          x.money = money;
        }
      });
    },
    updateQuantityItem(state, action) {
      const { id, newQuantity } = action.payload;
      state.items.forEach((x) => {
        if (x.id === id) {
          x.quantity = newQuantity;
        }
      });
    },
    addTopingOrder(state, action) {
      const { id, newToping } = action.payload;
      state.items.forEach((x) => {
        if (x.id === id) {
          x.toping = [newToping, ...x.toping];
        }
      });
    },
    updateTopingOrder(state, action) {
      const { id, newToping } = action.payload;
      state.items.forEach((x) => {
        if (x.id === id) {
          x.toping.forEach((t) => {
            if (t.title === newToping.title && t.position === newToping.position) {
              t.quantity = newToping.quantity;
              t.name = newToping.name;
            }
          });
        }
      });
    },
    removeTopingOrder(state, action) {
      const { id, idToping } = action.payload;
      state.items.forEach((x) => {
        if (x.id === id) {
          const index = x.toping.findIndex((x) => x.id === idToping);
          state.topingRemoved = x.toping.find((x) => x.id === idToping);
          x.toping.splice(index, 1);
        }
      });
    },
    setPizzaSelected(state, action) {
      state.typePizzaSelected = action.payload;
    },
    resetStateOrder(state) {
      state.items = [];
      state.isResetOrderItem = true;
    },
    setIsResetOrderItem(state, action) {
      state.isResetOrderItem = action.payload;
    },
  },
});

// Actions
export const orderActions = orderSlice.actions;

// Selectors
export const selectOrder = (state: any) => state.order.items;
export const selectTopingRemoved = (state: any) => state.order.topingRemoved;
export const selectTypePizzaSelected = (state: any) => state.order.typePizzaSelected;
export const selectIsResetOrderItemSelected = (state: any) => state.order.isResetOrderItem;

// Reducer
const orderReducer = orderSlice.reducer;
export default orderReducer;
