import { configureStore, ThunkAction, Action, combineReducers } from '@reduxjs/toolkit';
import commonReducer from '~/sagas/common/slice';
import orderReducer from '~/sagas/order/slice';
import pizzaReducer from '~/sagas/pizza/slice';

import authReducer from '~/thunks/auth/authSlice';

const rootReducer = combineReducers({
  auth: authReducer,
  pizza: pizzaReducer,
  order: orderReducer,
  common: commonReducer,
});

export function makeStore() {
  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
    devTools: process.env.NODE_ENV !== 'production',
  });
}

const store = makeStore();

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;

export default store;
