import classNames from 'classnames/bind';

import styles from './Dropdown.module.scss';
import icons from '~/assets/icons';
import { DropdownOrderLookupEnum } from '~/utils/enum';

type DropdownProps = {
  title: DropdownOrderLookupEnum;
  value: string;
};

const cx = classNames.bind(styles);

const Dropdown = (props: DropdownProps) => {
  const { title, value } = props;
  return (
    <div id='dropdownOrderLookup' className={cx('dropdown-container')}>
      <p className={cx('dropdown-title')}>{title}</p>
      <div className={cx('dropdown-display-value')}>
        <p className={cx('dropdown-value')}>{value}</p>
        <img src={icons.arrowDownSvg} alt='arrowDownSvg' />
      </div>
    </div>
  );
};

export default Dropdown;
