import classNames from 'classnames/bind';
import { MouseEvent, ReactNode } from 'react';
import styles from './FooterButton.module.scss';
import { TypeFooterButtonEnum } from '~/utils/enum';

type FooterButtonItemProps = {
  content?: ReactNode;
  type?: TypeFooterButtonEnum;
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
};

const cx = classNames.bind(styles);

const FooterButton = (props: FooterButtonItemProps) => {
  //#region Destructuring Props
  const { content, type = TypeFooterButtonEnum.ACTION, onClick, disabled = false } = props;
  //#endregion Destructuring Props

  let buttonClassNameByType = '';
  if (type === TypeFooterButtonEnum.ACTION) {
    buttonClassNameByType = 'footer-button-action';
  }
  if (type === TypeFooterButtonEnum.ARROW) {
    buttonClassNameByType = 'footer-button-arrow';
  }
  if (type === TypeFooterButtonEnum.SEND && !disabled) {
    buttonClassNameByType = 'footer-button-send';
  }
  if (type === TypeFooterButtonEnum.ADD) {
    buttonClassNameByType = 'footer-button-add';
  }

  return (
    <button
      id='footerButton'
      className={cx(['footer-button', buttonClassNameByType, disabled && 'footer-button-disable'])}
      onClick={onClick}
      disabled={disabled}
    >
      {content}
    </button>
  );
};

export default FooterButton;
