import classNames from 'classnames/bind';
import { contentButtonDrinks, dataFilterDrinks } from '~/utils/constants/mockData';
import styles from './DrinksPage.module.scss';
import { IItemSelected } from '~/utils/interface/common';
import { ContentButton, SelectBox } from '~/components/specific';
import { KeyOptionOrderEnum, NameSelectBoxHeaderDrinksEnum } from '~/utils/enum';
import { IItemOrder } from '~/sagas/order/state';
import { useDispatch, useSelector } from 'react-redux';
import { orderActions, selectOrder } from '~/sagas/order/slice';
import { useState } from 'react';

const cx = classNames.bind(styles);

const DrinksPage = () => {
  const dispatch = useDispatch();

  const [selectedSizeDrinks, setSelectedSizeDrinks] = useState<string>('2 Liter');
  const orderSelector = useSelector(selectOrder);

  const handleChangeSize = (itemSelected: IItemSelected) => {
    // TODO: Handle logic
  };

  const handleSelectedItem = (itemSelected: IItemSelected) => {
    if (itemSelected.id === -1) {
      return;
    }
    const id = orderSelector[0]?.id;
    if (!id) {
      return;
    }
    if (orderSelector[0].type === KeyOptionOrderEnum.DRINKS) {
      // TODO handle logic
      const payloadUpdate = {
        id: id,
        newSize: itemSelected.value,
        money: 10,
      };
      dispatch(orderActions.updateSizeItem(payloadUpdate));
    }
    // switch (itemSelected.nameSelectBox) {
    //   case NameSelectBoxHeaderDrinksEnum.SIZE:
    //     handleChangeSize(itemSelected);
    //     break;
    // }

    // setSelectedSizeDrinks(itemSelected.id);
  };

  const handleSelectDrink = (payload: { id: number; content: string }) => {
    const pizzaSelected: IItemOrder = {
      id: Date.now(),
      name: payload.content,
      size: selectedSizeDrinks,
      money: 10,
      type: KeyOptionOrderEnum.DRINKS,
      quantity: 1,
      toping: [],
    };

    dispatch(orderActions.addToOrder(pizzaSelected));
  };

  return (
    <div className={cx('page-container')}>
      <div className={cx('page-head-wrap')}>
        {dataFilterDrinks.map((item) => {
          const itemDefault = item.data.find((x) => x.id === item.defaultValue);

          return (
            <SelectBox
              key={item.id}
              name={item.name}
              data={item.data}
              defaultValue={itemDefault}
              onChange={handleSelectedItem}
            />
          );
        })}
      </div>

      <div className={cx('page-divide-line')} />

      <div className={cx('page-body-wrap')}>
        {contentButtonDrinks.map((item) => (
          <ContentButton key={item.id} buttonText={item.content} onClick={() => handleSelectDrink(item)} />
        ))}
      </div>
    </div>
  );
};

export default DrinksPage;
