import icons from '~/assets/icons';
import {
  KeyOptionOrderEnum,
  KeySizePizzaEnum,
  KeyTypeChoosePizzaEnum,
  KeyTypeCrustTypeEnum,
  KeyTypeFlavorsEnum,
  KeyTypeSauceEnum,
  KeyTypeSizeEnum,
  ToppingValueEnum,
  NameSelectBoxHeaderPizzaEnum,
  TypeFooterButtonEnum,
  NameSelectBoxHeaderDessertsEnum,
  KeyTypeChooseSizeDessertsEnum,
  NameSelectBoxHeaderWingsEnum,
  KeyTypeWingsEnum,
  KeyOptionsEntree,
  KeyOptionsSize,
  KeyOptionsCrust,
  KeyOptionsSauces,
  KeyOptionsPreference,
  NameSelectBoxHeaderPastaEnum,
  NameSelectBoxHeaderBowlEnum,
} from '../enum';

export const footerButton = [
  { id: 1, content: 'Manager Functions', type: TypeFooterButtonEnum.ACTION },
  { id: 2, content: 'Order Note', type: TypeFooterButtonEnum.ACTION },
  { id: 3, content: 'Sides', type: TypeFooterButtonEnum.ACTION },
  { id: 4, content: 'Coupons', type: TypeFooterButtonEnum.ACTION },
  { id: 5, content: 'Loyalty', type: TypeFooterButtonEnum.ACTION },
  // { id: 6, content: 'Delete Customer', type: TypeFooterButtonEnum.ACTION },
  { id: 7, content: 'Clear All', type: TypeFooterButtonEnum.ACTION },
  { id: 8, content: 'Order Lookup', type: TypeFooterButtonEnum.ACTION },
  { id: 9, content: 'Pay Now', type: TypeFooterButtonEnum.ACTION },
  // { id: 10, content: 'Add', type: TypeFooterButtonEnum.ACTION },
];

export const footerOrderButton = [
  { content: 'Tips', type: TypeFooterButtonEnum.ARROW },
  { content: 'Reassign', type: TypeFooterButtonEnum.ACTION },
  { content: 'Merge Orders', type: TypeFooterButtonEnum.ACTION },
  { content: 'Show Details', type: TypeFooterButtonEnum.ACTION },
];

export const footerButtonSend = 'Send';

export const footerOrderButtonSaveAndReturn = 'Save & Return';

export const footerPayNowButton = 'Pay Now';

export const footerAddButton = 'Add';

export const foodOrderOptions = [
  {
    label: '1',
    value: 1,
  },
  {
    label: '2',
    value: 2,
  },
  {
    label: '3',
    value: 3,
  },
  {
    label: '4',
    value: 4,
  },
  {
    label: '5',
    value: 5,
  },
  {
    label: '6',
    value: 6,
  },
  {
    label: '7',
    value: 7,
  },
  {
    label: '8',
    value: 8,
  },
  {
    label: '9',
    value: 9,
  },
  {
    label: '10',
    value: 10,
  },
];

export const orderTypes = ['To Go'];

export const dataSelector: any = ['Pepperoni', 'Sausage', 'Mushrooms', 'Onions', 'Black Olives'];

export const dataSelectorDrinks: any = ['9 Inch'];

export const buttonTop = [
  { id: KeySizePizzaEnum.SMALL, title: 'Serves 1-2', subTitle: '10” Small', size: 10 },
  { id: KeySizePizzaEnum.MEDIUM, title: 'Serves 2-3', subTitle: '12” Medium', size: 12 },
  { id: KeySizePizzaEnum.LARGE, title: 'Serves 3-4', subTitle: '14” Large', size: 14 },
  { id: KeySizePizzaEnum.X_LARGE, title: 'Serves 4-5', subTitle: '16” X-Large', size: 16 },
];

export const customPizzaButton = 'Custom Pizza';

export const contentButton = [
  { id: 1, content: `Margherita`, topingIds: [KeyOptionOrderEnum.SPINACH] },
  { id: 2, content: `Pepperoni`, topingIds: [KeyOptionOrderEnum.PEPPERONI] },
  { id: 3, content: `Shrimp Scampi`, topingIds: [KeyOptionOrderEnum.SHRIMP] },
  {
    id: 4,
    content: `Veggie`,
    topingIds: [
      KeyOptionOrderEnum.TOMATOES,
      KeyOptionOrderEnum.SPINACH,
      KeyOptionOrderEnum.BLACK_OLIVES,
      KeyOptionOrderEnum.GREEN_PEPPERS,
    ],
  },
  { id: 5, content: `Chicken Bacon`, topingIds: [KeyOptionOrderEnum.CHICKEN, KeyOptionOrderEnum.BACON] },
  { id: 6, content: `BBQ Chicken`, topingIds: [KeyOptionOrderEnum.CHICKEN] },
  { id: 7, content: `Hawaiian Luau`, topingIds: [KeyOptionOrderEnum.HAM, KeyOptionOrderEnum.PINEAPPLE] },
  { id: 8, content: `Truffle Mushroom`, topingIds: [KeyOptionOrderEnum.MUSHROOMS] },
  { id: 9, content: `Four Cheese`, topingIds: [] },
  { id: 10, content: `Seafood Medley`, topingIds: [KeyOptionOrderEnum.ANCHOVIES, KeyOptionOrderEnum.SHRIMP] },
  { id: 11, content: `Taco Fiesta`, topingIds: [KeyOptionOrderEnum.JALAPENO] },
  {
    id: 12,
    content: `Pesto Garden`,
    topingIds: [KeyOptionOrderEnum.SPINACH, KeyOptionOrderEnum.EGGPLANT, KeyOptionOrderEnum.RED_ONIONS],
  },
  { id: 13, content: `Buffalo Chicken`, topingIds: [KeyOptionOrderEnum.CHICKEN] },
  {
    id: 14,
    content: `Ratatouille Rhapsody`,
    topingIds: [KeyOptionOrderEnum.EGGPLANT, KeyOptionOrderEnum.POTATOES, KeyOptionOrderEnum.RED_ONIONS],
  },
  { id: 15, content: `Bacon Mac'n' Cheese`, topingIds: [KeyOptionOrderEnum.BACON, KeyOptionOrderEnum.MACARONI] },
  {
    id: 16,
    content: `Greek Goddess`,
    topingIds: [KeyOptionOrderEnum.BLACK_OLIVES, KeyOptionOrderEnum.GREEN_OLIVES, KeyOptionOrderEnum.RED_ONIONS],
  },
  {
    id: 17,
    content: `Spicy Italian`,
    topingIds: [KeyOptionOrderEnum.SAUSAGE, KeyOptionOrderEnum.BACON, KeyOptionOrderEnum.JALAPENO],
  },
  { id: 18, content: `Carbonara Comfort`, topingIds: [KeyOptionOrderEnum.POTATOES, KeyOptionOrderEnum.KIELBASA] },
  { id: 19, content: `Tandoori Spice`, topingIds: [KeyOptionOrderEnum.CHICKEN] },
  { id: 20, content: `Korean BBQ`, topingIds: [KeyOptionOrderEnum.GROUND_BEEF, KeyOptionOrderEnum.CHICKEN] },
];

export const buttonFoodOrderBowlsPage = [
  {
    id: 1,
    title: 'Pepperoni',
  },
  {
    id: 2,
    title: 'Sausage',
  },
  {
    id: 3,
    title: 'Bacon',
  },
  {
    id: 4,
    title: 'Kielbasa',
  },
  {
    id: 5,
    title: 'Spinach',
  },
  {
    id: 6,
    title: 'Pineapple',
  },
  {
    id: 7,
    title: 'Eggplant',
  },
  {
    id: 8,
    title: 'Potatoes',
  },
  {
    id: 9,
    title: 'Jalapeño',
  },
  {
    id: 10,
    title: 'Cauliflower',
  },
  {
    id: 11,
    title: 'Mushrooms',
  },
  {
    id: 12,
    title: 'red onions',
  },
  {
    id: 13,
    title: 'green peppers',
  },
  {
    id: 14,
    title: 'black olives',
  },
  {
    id: 15,
    title: 'tomatoes',
  },
  {
    id: 16,
    title: 'Italian sausage',
  },
  {
    id: 17,
    title: 'ground beef',
  },
  {
    id: 18,
    title: 'green olives',
  },
  {
    id: 19,
    title: 'Black Olives',
  },
  {
    id: 20,
    title: 'Cauliflower',
  },
  {
    id: 21,
    title: 'Pineapple',
  },
  {
    id: 22,
    title: 'Eggplant',
  },
  {
    id: 23,
    title: 'Potatoes',
  },
  {
    id: 24,
    title: 'Jalapeño',
  },
  {
    id: 25,
    title: 'Cauliflower',
  },
];

export const optionsOrderPizza = [
  {
    id: KeyOptionOrderEnum.PEPPERONI,
    title: 'Pepperoni',
  },
  {
    id: KeyOptionOrderEnum.SAUSAGE,
    title: 'Sausage',
  },
  {
    id: KeyOptionOrderEnum.BACON,
    title: 'Bacon',
  },
  {
    id: KeyOptionOrderEnum.KIELBASA,
    title: 'Kielbasa',
  },
  {
    id: KeyOptionOrderEnum.SPINACH,
    title: 'Spinach',
  },
  {
    id: KeyOptionOrderEnum.PINEAPPLE,
    title: 'Pineapple',
  },
  {
    id: KeyOptionOrderEnum.EGGPLANT,
    title: 'Eggplant',
  },
  {
    id: KeyOptionOrderEnum.POTATOES,
    title: 'Potatoes',
  },
  {
    id: KeyOptionOrderEnum.JALAPENO,
    title: 'Jalapeño',
  },
  {
    id: KeyOptionOrderEnum.CAULIFLOWER,
    title: 'Cauliflower',
  },
  {
    id: KeyOptionOrderEnum.MUSHROOMS,
    title: 'Mushrooms',
  },
  {
    id: KeyOptionOrderEnum.RED_ONIONS,
    title: 'Red Onions',
  },
  {
    id: KeyOptionOrderEnum.GREEN_PEPPERS,
    title: 'Green Peppers',
  },
  {
    id: KeyOptionOrderEnum.BLACK_OLIVES,
    title: 'Black Olives',
  },
  {
    id: KeyOptionOrderEnum.TOMATOES,
    title: 'Tomatoes',
  },
  {
    id: KeyOptionOrderEnum.ITALIAN_SAUSAGE,
    title: 'Italian Sausage',
  },
  {
    id: KeyOptionOrderEnum.GROUND_BEEF,
    title: 'Ground Beef',
  },
  {
    id: KeyOptionOrderEnum.GREEN_OLIVES,
    title: 'Green Olives',
  },
  {
    id: KeyOptionOrderEnum.SHRIMP,
    title: 'Shrimp',
  },
  {
    id: KeyOptionOrderEnum.CHICKEN,
    title: 'Chicken',
  },
  {
    id: KeyOptionOrderEnum.ANCHOVIES,
    title: 'Anchovies',
  },
  {
    id: KeyOptionOrderEnum.CRAB,
    title: 'Crab',
  },
  {
    id: KeyOptionOrderEnum.MACARONI,
    title: 'Macaroni',
  },
  {
    id: KeyOptionOrderEnum.GARLIC,
    title: 'Garlic',
  },
  {
    id: KeyOptionOrderEnum.HAM,
    title: 'Ham',
  },
];

export const optionsWings = [
  {
    id: KeyOptionOrderEnum.PEPPERONI,
    title: 'Pepperoni',
  },
  {
    id: KeyOptionOrderEnum.SAUSAGE,
    title: 'Sausage',
  },
  {
    id: KeyOptionOrderEnum.BACON,
    title: 'Bacon',
  },
  {
    id: KeyOptionOrderEnum.KIELBASA,
    title: 'Kielbasa',
  },
  {
    id: KeyOptionOrderEnum.SPINACH,
    title: 'Spinach',
  },
  {
    id: KeyOptionOrderEnum.PINEAPPLE,
    title: 'Pineapple',
  },
  {
    id: KeyOptionOrderEnum.EGGPLANT,
    title: 'Eggplant',
  },
  {
    id: KeyOptionOrderEnum.POTATOES,
    title: 'Potatoes',
  },
  {
    id: KeyOptionOrderEnum.JALAPENO,
    title: 'Jalapeño',
  },
  {
    id: KeyOptionOrderEnum.CAULIFLOWER,
    title: 'Cauliflower',
  },
  {
    id: KeyOptionOrderEnum.MUSHROOMS,
    title: 'Mushrooms',
  },
  {
    id: KeyOptionOrderEnum.RED_ONIONS,
    title: 'Red Onions',
  },
  {
    id: KeyOptionOrderEnum.GREEN_PEPPERS,
    title: 'Green Peppers',
  },
  {
    id: KeyOptionOrderEnum.BLACK_OLIVES,
    title: 'Black Olives',
  },
  {
    id: KeyOptionOrderEnum.TOMATOES,
    title: 'Tomatoes',
  },
  {
    id: KeyOptionOrderEnum.ITALIAN_SAUSAGE,
    title: 'Italian Sausage',
  },
  {
    id: KeyOptionOrderEnum.GROUND_BEEF,
    title: 'Ground Beef',
  },
  {
    id: KeyOptionOrderEnum.GREEN_OLIVES,
    title: 'Green Olives',
  },
  {
    id: KeyOptionOrderEnum.SHRIMP,
    title: 'Shrimp',
  },
  {
    id: KeyOptionOrderEnum.CHICKEN,
    title: 'Chicken',
  },
  {
    id: KeyOptionOrderEnum.ANCHOVIES,
    title: 'Anchovies',
  },
  {
    id: KeyOptionOrderEnum.CRAB,
    title: 'Crab',
  },
  {
    id: KeyOptionOrderEnum.MACARONI,
    title: 'Macaroni',
  },
  {
    id: KeyOptionOrderEnum.GARLIC,
    title: 'Garlic',
  },
  {
    id: KeyOptionOrderEnum.HAM,
    title: 'Ham',
  },
];

export const optionsPasta = [
  {
    id: KeyOptionOrderEnum.PEPPERONI,
    title: 'Pepperoni',
  },
  {
    id: KeyOptionOrderEnum.SAUSAGE,
    title: 'Sausage',
  },
  {
    id: KeyOptionOrderEnum.BACON,
    title: 'Bacon',
  },
  {
    id: KeyOptionOrderEnum.KIELBASA,
    title: 'Kielbasa',
  },
  {
    id: KeyOptionOrderEnum.SPINACH,
    title: 'Spinach',
  },
  {
    id: KeyOptionOrderEnum.PINEAPPLE,
    title: 'Pineapple',
  },
  {
    id: KeyOptionOrderEnum.EGGPLANT,
    title: 'Eggplant',
  },
  {
    id: KeyOptionOrderEnum.POTATOES,
    title: 'Potatoes',
  },
  {
    id: KeyOptionOrderEnum.JALAPENO,
    title: 'Jalapeño',
  },
  {
    id: KeyOptionOrderEnum.CAULIFLOWER,
    title: 'Cauliflower',
  },
  {
    id: KeyOptionOrderEnum.MUSHROOMS,
    title: 'Mushrooms',
  },
  {
    id: KeyOptionOrderEnum.RED_ONIONS,
    title: 'Red Onions',
  },
  {
    id: KeyOptionOrderEnum.GREEN_PEPPERS,
    title: 'Green Peppers',
  },
  {
    id: KeyOptionOrderEnum.BLACK_OLIVES,
    title: 'Black Olives',
  },
  {
    id: KeyOptionOrderEnum.TOMATOES,
    title: 'Tomatoes',
  },
  {
    id: KeyOptionOrderEnum.ITALIAN_SAUSAGE,
    title: 'Italian Sausage',
  },
  {
    id: KeyOptionOrderEnum.GROUND_BEEF,
    title: 'Ground Beef',
  },
  {
    id: KeyOptionOrderEnum.GREEN_OLIVES,
    title: 'Green Olives',
  },
  {
    id: KeyOptionOrderEnum.SHRIMP,
    title: 'Shrimp',
  },
  {
    id: KeyOptionOrderEnum.CHICKEN,
    title: 'Chicken',
  },
  {
    id: KeyOptionOrderEnum.ANCHOVIES,
    title: 'Anchovies',
  },
  {
    id: KeyOptionOrderEnum.CRAB,
    title: 'Crab',
  },
  {
    id: KeyOptionOrderEnum.MACARONI,
    title: 'Macaroni',
  },
  {
    id: KeyOptionOrderEnum.GARLIC,
    title: 'Garlic',
  },
  {
    id: KeyOptionOrderEnum.HAM,
    title: 'Ham',
  },
];

export const optionsBowls = [
  {
    id: KeyOptionOrderEnum.PEPPERONI,
    title: 'Pepperoni',
  },
  {
    id: KeyOptionOrderEnum.SAUSAGE,
    title: 'Sausage',
  },
  {
    id: KeyOptionOrderEnum.BACON,
    title: 'Bacon',
  },
  {
    id: KeyOptionOrderEnum.KIELBASA,
    title: 'Kielbasa',
  },
  {
    id: KeyOptionOrderEnum.SPINACH,
    title: 'Spinach',
  },
  {
    id: KeyOptionOrderEnum.PINEAPPLE,
    title: 'Pineapple',
  },
  {
    id: KeyOptionOrderEnum.EGGPLANT,
    title: 'Eggplant',
  },
  {
    id: KeyOptionOrderEnum.POTATOES,
    title: 'Potatoes',
  },
  {
    id: KeyOptionOrderEnum.JALAPENO,
    title: 'Jalapeño',
  },
  {
    id: KeyOptionOrderEnum.CAULIFLOWER,
    title: 'Cauliflower',
  },
  {
    id: KeyOptionOrderEnum.MUSHROOMS,
    title: 'Mushrooms',
  },
  {
    id: KeyOptionOrderEnum.RED_ONIONS,
    title: 'Red Onions',
  },
  {
    id: KeyOptionOrderEnum.GREEN_PEPPERS,
    title: 'Green Peppers',
  },
  {
    id: KeyOptionOrderEnum.BLACK_OLIVES,
    title: 'Black Olives',
  },
  {
    id: KeyOptionOrderEnum.TOMATOES,
    title: 'Tomatoes',
  },
  {
    id: KeyOptionOrderEnum.ITALIAN_SAUSAGE,
    title: 'Italian Sausage',
  },
  {
    id: KeyOptionOrderEnum.GROUND_BEEF,
    title: 'Ground Beef',
  },
  {
    id: KeyOptionOrderEnum.GREEN_OLIVES,
    title: 'Green Olives',
  },
  {
    id: KeyOptionOrderEnum.SHRIMP,
    title: 'Shrimp',
  },
  {
    id: KeyOptionOrderEnum.CHICKEN,
    title: 'Chicken',
  },
  {
    id: KeyOptionOrderEnum.ANCHOVIES,
    title: 'Anchovies',
  },
  {
    id: KeyOptionOrderEnum.CRAB,
    title: 'Crab',
  },
  {
    id: KeyOptionOrderEnum.MACARONI,
    title: 'Macaroni',
  },
  {
    id: KeyOptionOrderEnum.GARLIC,
    title: 'Garlic',
  },
  {
    id: KeyOptionOrderEnum.HAM,
    title: 'Ham',
  },
];

export const entreeOptionsBowlsPage = ['3 Cheese De..'];

export const sizeOptionsBowlsPage = ['Regular'];

export const sidesOptionsBowlsPage = ['Yogurt'];

export const saucesOptionsBowlsPage = ['Cheddar Mo..'];

export const preferenceOptionsBowlsPage = [''];

export const dataDrinks = [
  { id: 1, content: `Orange Crush` },
  { id: 2, content: `Aquafina` },
  { id: 3, content: `Diet Pepsi` },
  { id: 4, content: `Dr. Pepper` },
  { id: 5, content: `Lipton Iced Tea` },
  { id: 6, content: `Mountain Dew` },
  { id: 7, content: `Pepsi` },
  { id: 8, content: `Starry` },
  { id: 9, content: `Coke Classic` },
  { id: 10, content: `Chai Tea` },
];

export const contentButtonSecretPage = [
  { id: 1, content: 'Howie 5-0' },
  { id: 2, content: 'The Greektown' },
  { id: 3, content: 'Tie-Dye' },
  { id: 4, content: `‘73 Classic` },
  { id: 5, content: 'Chicken Bacon' },
  { id: 6, content: 'BBQ Chicken' },
  { id: 7, content: 'Hawaiian Luau' },
  { id: 8, content: 'Truffle Mushroom' },
  { id: 9, content: 'Four Cheese' },
  { id: 10, content: 'Seafood Medley' },
  { id: 11, content: 'Taco Fiesta' },
  { id: 12, content: 'Pesto Garden' },
  { id: 13, content: 'Buffalo Chicken' },
  { id: 14, content: 'Ratatouille Rhapsody' },
  { id: 15, content: "Bacon Mac 'n' Cheese" },
  { id: 16, content: 'Greek Goddess' },
  { id: 17, content: 'Spicy Italian' },
  { id: 18, content: 'Carbonara Comfort' },
  { id: 19, content: 'Tandoori Spice' },
  { id: 20, content: 'Korean BBQ' },
];

// DessertsPage
export const chooseSizeDesserts = [
  { id: KeyTypeChooseSizeDessertsEnum.NINE_INCHES, content: '9 Inch' },
  { id: KeyTypeChooseSizeDessertsEnum.TWELVE_INCHES, content: '12 Inch' },
];

export const dataSelectorDesserts = [
  {
    id: 1,
    name: NameSelectBoxHeaderDessertsEnum.SIZE,
    data: chooseSizeDesserts,
    defaultValue: KeyTypeChooseSizeDessertsEnum.NINE_INCHES,
  },
];

export const buttonLabel = [
  { id: 1, value: 'Howie Cookie' },
  { id: 2, value: 'Cinnamon Howie' },
  { id: 3, value: 'Triple Berry Cheesecake' },
  { id: 4, value: 'Salted Caramel Brownie' },
  { id: 5, value: 'Tiramisu' },
  { id: 6, value: 'Apple Crisp' },
  { id: 7, value: 'Chocolate Lava' },
  { id: 8, value: 'Pumpkin Spice Bread Pudding' },
  { id: 9, value: 'Peanut Butter Cup Pie' },
  { id: 10, value: 'Lemon Meringue' },
];

// Pizza selector
export const choosePizza = [
  { id: KeyTypeChoosePizzaEnum.BACON_JALAPENO, content: 'Bacon Jalapeno' },
  { id: KeyTypeChoosePizzaEnum.BEE_STRING_PIZZA, content: 'Bee Sting Pizza' },
  { id: KeyTypeChoosePizzaEnum.PEPPERONI_DUO, content: 'Pepperoni Duo' },
  { id: KeyTypeChoosePizzaEnum.MEAT_EATERS, content: 'Meat Eaters' },
  { id: KeyTypeChoosePizzaEnum.WORKS_PIZZA, content: 'Works Pizza' },
  { id: KeyTypeChoosePizzaEnum.HOWIE_SPECIAL, content: 'Howie Special' },
  { id: KeyTypeChoosePizzaEnum.HOWIE_MAUI, content: 'Howie Maui' },
  { id: KeyTypeChoosePizzaEnum.BBQ_CHICKEN, content: 'BBQ Chicken' },
  { id: KeyTypeChoosePizzaEnum.VEGGIE, content: 'Veggie' },
  { id: KeyTypeChoosePizzaEnum.HAWAIIAN, content: 'Hawaiian' },
  { id: KeyTypeChoosePizzaEnum.CHICKEN_BACON_RANCH, content: 'Chicken Bacon Ranch' },
  { id: KeyTypeChoosePizzaEnum.BACON_CHEDDAR_CHEESEBURGER, content: 'Bacon Cheddar Cheeseburger' },
  { id: KeyTypeChoosePizzaEnum.ASIAN_CHICKEN, content: 'Asian Chicken' },
  { id: KeyTypeChoosePizzaEnum.PHILLY_STEAK_CHEESE, content: 'Philly Steak & Cheese' },
  { id: KeyTypeChoosePizzaEnum.BUFFALO_CHICKEN, content: 'Buffalo Chicken' },
  { id: KeyTypeChoosePizzaEnum.THREEE_CHEESER_PEPPERONI, content: '3 Cheeser Pepperoni' },
];

export const size = [
  { id: KeyTypeSizeEnum.EIGHT_LARGE, content: '8" Large', size: 8 },
  { id: KeyTypeSizeEnum.TEN_LARGE, content: '10" Large', size: 10 },
  { id: KeyTypeSizeEnum.TWELVE_MEDIUM_LARGE, content: '12" Medium Large', size: 12 },
  { id: KeyTypeSizeEnum.FOURTEEN_LARGE, content: '14" Large', size: 14 },
  { id: KeyTypeSizeEnum.EIGHTEEN_X_LARGE, content: '18" X-Large', size: 18 },
];

export const crustType = [
  { id: KeyTypeCrustTypeEnum.ORIGINAL_ROUND, content: 'Original Round' },
  { id: KeyTypeCrustTypeEnum.THIN_CRUST, content: 'Thin Crust' },
  { id: KeyTypeCrustTypeEnum.STUFFED_CRUST, content: 'Stuffed Crust' },
];

export const sauce = [
  { id: KeyTypeSauceEnum.RANCH_SAUCE, content: 'Ranch Sauce' },
  { id: KeyTypeSauceEnum.GARLIC_SAUCE, content: 'Garlic Sauce' },
  { id: KeyTypeSauceEnum.MARINARA, content: 'Marinara' },
  { id: KeyTypeSauceEnum.BBQ_SAUCE, content: 'BBQ Sauce' },
  { id: KeyTypeSauceEnum.TABASCO, content: 'Tabasco' },
  { id: KeyTypeSauceEnum.SRIRACHA, content: 'Sriracha' },
];

export const flavors = [
  { id: KeyTypeFlavorsEnum.BUTTER, content: 'Butter' },
  { id: KeyTypeFlavorsEnum.BUTTER_CHEESE, content: 'Butter Cheese' },
  { id: KeyTypeFlavorsEnum.ASIAGO_CHEESE, content: 'Asiago Cheese' },
  { id: KeyTypeFlavorsEnum.RANCH, content: 'Ranch' },
  { id: KeyTypeFlavorsEnum.ITALIAN_HERB, content: 'Italian Herb' },
  { id: KeyTypeFlavorsEnum.CAJUN, content: 'Cajun' },
  { id: KeyTypeFlavorsEnum.SESAME, content: 'Sesame' },
  { id: KeyTypeFlavorsEnum.LEMON_ZEST, content: 'Lemon Zest' },
];

// Wings selector
export const wingsDropdownOptions = [
  { id: 1, content: 'Boneless Wings' },
  { id: 2, content: 'Howie Wings' },
];

export const sidesDropdownOptions = [
  { id: 1, content: 'Howie Bread' },
  { id: 2, content: '3 Cheeser Howie Bread' },
  { id: 3, content: 'Fresh Garden Salad' },
  { id: 4, content: 'Original Howie Wings' },
  { id: 5, content: 'BBQ Howie Wings' },
  { id: 6, content: 'Cinnamon Howie Bread' },
  { id: 7, content: 'Fries' },
];

export const dresssingDropdownOptions = [{ id: 1, content: 'Blue Cheese' }];

export const preferenceDropdownOptions = [{ id: 1, content: 'Regular Cook' }];

// Pasta selector
export const pastaDropdownOptions = [{ id: 1, content: 'Baked Spaghetti' }];

export const sizeDropdownOptions = [
  { id: 1, content: 'Large' },
  { id: 2, content: 'Small' },
];

export const cheeseDropdownOptions = [{ id: 1, content: 'Cheddar Mozzarella' }];

export const sauceDropdownOptions = [{ id: 1, content: 'Marinara' }];

export const sideBreadDropdownOptions = [
  { id: 1, content: 'Howie Bread' },
  { id: 2, content: 'Cajun Bread' },
  { id: 3, content: '3 Cheese Bread' },
  { id: 4, content: 'No Bread' },
];

// Bowl selector
export const entreeBowlDropdownOptions = [
  { id: 1, content: '3-Topping' },
  { id: 2, content: '4-Topping' },
  { id: 3, content: 'Howie Special' },
  { id: 4, content: 'Meat Eaters' },
];

export const sizeBowlDropdownOptions = [
  { id: 1, content: 'Regular' },
  { id: 2, content: 'Large' },
];

export const sidesBowlDropdownOptions = [
  { id: 1, content: 'Howie Bread' },
  { id: 2, content: '3 Cheeser Howie Bread' },
  {
    id: 3,
    content: 'Fresh Garden Salad',
  },
  { id: 4, content: 'Original Howie Wings' },
  { id: 5, content: 'BBQ Howie Wings' },
  { id: 6, content: 'Cinnamon Howie Bread' },
  { id: 7, content: 'Fries' },
];

// TODO: Need to check data from design (last item in option)
export const saucesBowlDropdownOptions = [
  { id: 1, content: 'Pizza Sauce' },
  { id: 2, content: 'Extra Cheese' },
];

export const seasoningBowlDropdownOptions = [{ id: 1, content: 'Italian Herb' }];

export const menuWelcomePage = [
  { id: 1, icon: icons.ordersSvg, title: 'Orders' },
  { id: 2, icon: icons.timeClockSvg, title: 'Time Clock' },
  { id: 3, icon: icons.deliverySvg, title: 'Delivery' },
  { id: 4, icon: icons.toolsSvg, title: 'Tools' },
  { id: 5, icon: icons.cashSvg, title: 'Cash' },
  { id: 6, icon: icons.logOffSvg, title: 'Log Off' },
];

export const footerInformationWelcomePage = [
  { id: 1, title: 'Order ID', content: 'New' },
  { id: 2, title: 'User', content: 'Hunger R.' },
  { id: 3, title: 'Table', content: 'Pending' },
];

// Title greetings
export const TITLE_GREETINGS_LOGIN = 'Good Morning!';

export const TITLE_GREETINGS_WELCOME = 'Have a good day!';

// Number of hours in haft a day
export const HOURS_IN_HAFT_A_DAY = 12;

//Length of the time seconds
export const LENGTH_OF_THE_TIME_SECONDS = 2;

// Value pad of the time seconds
export const VALUE_PAD_OF_THE_TIME_SECONDS = '0';

// Time seconds interval get time
export const TIME_SECONDS_INTERVAL_GET_TIME = 1000;

// The days of week
export const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

// The months of year
export const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

// Lower threshold for using the suffix 'th'
export const LOWER_BOUND_FOR_TH_SUFFIX = 11;

// Upper threshold for using the suffix 'th'
export const UPPER_BOUND_FOR_TH_SUFFIX = 19;

// Special number associated with the suffix 'th'
export const SPECIAL_NUMBER_FOR_SUFFIXES = 10;

// Password landing page
export const PASSWORDS_LANDING_PAGE = 'pizza99';

export const dataTableOrderLookup = [
  {
    id: 6,
    time: '4:25pm',
    type: 'To Go',
    table: '3',
    customerContent: 'Cashiola, Katerina',
    customerSubContent: '1086 Lasso Ln',
    paid: 'Unpaid',
    total: 6,
  },
];

export const toppingPizza = [
  { id: 1, value: ToppingValueEnum.X1 },
  { id: 2, value: ToppingValueEnum.X2 },
  { id: 3, value: ToppingValueEnum.X3 },
  { id: 4, value: ToppingValueEnum.LIGHT },
  { id: 5, value: ToppingValueEnum.NONE },
];

export const toppingPizzaTouch = [
  { id: 1, value: ToppingValueEnum.X1 },
  { id: 2, value: ToppingValueEnum.X2 },
  { id: 3, value: ToppingValueEnum.X3 },
  { id: 4, value: ToppingValueEnum.LIGHT },
  { id: 5, value: ToppingValueEnum.NONE },
];
//Dialog
export const descriptionDialogOrderSent = 'Order Sent';

export const TIMEOUT_DIALOG_DEFAULT = 2000;

export const dataSelectBoxHeaderPizza = [
  {
    id: 1,
    name: NameSelectBoxHeaderPizzaEnum.SPECIALTY,
    data: choosePizza,
    emptyValue: 'Choose Pizza',
  },
  {
    id: 2,
    name: NameSelectBoxHeaderPizzaEnum.SIZE,
    data: size,
    defaultValue: KeyTypeSizeEnum.FOURTEEN_LARGE,
  },
  {
    id: 3,
    name: NameSelectBoxHeaderPizzaEnum.CRUST_TYPE,
    data: crustType,
  },
  {
    id: 4,
    name: NameSelectBoxHeaderPizzaEnum.SAUCE,
    data: sauce,
  },
  {
    id: 5,
    name: NameSelectBoxHeaderPizzaEnum.FLAVORS,
    data: flavors,
  },
];

export const dataSelectBoxHeaderWings = [
  {
    id: 1,
    name: NameSelectBoxHeaderWingsEnum.WINGS,
    data: wingsDropdownOptions,
    defaultValue: 2,
  },
  {
    id: 2,
    name: NameSelectBoxHeaderWingsEnum.SIDES,
    data: sidesDropdownOptions,
    defaultValue: 1,
  },
  {
    id: 3,
    name: NameSelectBoxHeaderWingsEnum.DRESSSING,
    data: dresssingDropdownOptions,
    defaultValue: 1,
  },
  {
    id: 4,
    name: NameSelectBoxHeaderWingsEnum.PREFERENCE,
    data: preferenceDropdownOptions,
    defaultValue: 1,
  },
];

export const dataSelectBoxHeaderPasta = [
  {
    id: 1,
    name: NameSelectBoxHeaderPastaEnum.PASTA,
    data: pastaDropdownOptions,
    defaultValue: 1,
  },
  {
    id: 2,
    name: NameSelectBoxHeaderPastaEnum.SIZE,
    data: sizeDropdownOptions,
    defaultValue: 1,
  },
  {
    id: 3,
    name: NameSelectBoxHeaderPastaEnum.CHEESE,
    data: cheeseDropdownOptions,
    defaultValue: 1,
  },
  {
    id: 4,
    name: NameSelectBoxHeaderPastaEnum.SAUCE,
    data: sauceDropdownOptions,
    defaultValue: 1,
  },
  {
    id: 5,
    name: NameSelectBoxHeaderPastaEnum.SIDE_BREAD,
    data: sideBreadDropdownOptions,
    defaultValue: 2,
  },
];

export const dataSelectBoxHeaderBowl = [
  {
    id: 1,
    name: NameSelectBoxHeaderBowlEnum.ENTREE,
    data: entreeBowlDropdownOptions,
    defaultValue: 1,
  },
  {
    id: 2,
    name: NameSelectBoxHeaderBowlEnum.SIZE,
    data: sizeBowlDropdownOptions,
    defaultValue: 1,
  },
  {
    id: 3,
    name: NameSelectBoxHeaderBowlEnum.SIDES,
    data: sidesBowlDropdownOptions,
    defaultValue: 7,
  },
  {
    id: 4,
    name: NameSelectBoxHeaderBowlEnum.SAUCES,
    data: saucesBowlDropdownOptions,
    defaultValue: 1,
  },
  {
    id: 5,
    name: NameSelectBoxHeaderBowlEnum.SEASONING,
    data: seasoningBowlDropdownOptions,
    defaultValue: 1,
  },
];

export const MAX_LENGTH_INPUT_CODE = 4;

export const STATION_TAG_CONTENT = 'Station 1';

// Data number keyboard
export const dataNumberKeyboard = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'];

// Data Filter Flatbread Page
export const dataFilterFlatbread = [
  {
    id: 1,
    name: 'Entree',
    data: [
      {
        id: 1,
        content: 'Pepperoni Duo',
      },
      {
        id: 2,
        content: 'Meat Eaters',
      },
      {
        id: 3,
        content: 'Works Flatbread',
      },
      {
        id: 4,
        content: 'Howie Special',
      },
      {
        id: 5,
        content: 'Howie Maui ',
      },
      {
        id: 6,
        content: 'BBQ Chicken',
      },
      {
        id: 7,
        content: 'Chicken Bacon Ranch',
      },
      {
        id: 8,
        content: 'Asian Chicken',
      },
      {
        id: 9,
        content: 'Buffalo Chicken',
      },
    ],
    // defaultValue: 6,
  },
  {
    id: 2,
    name: 'Size',
    data: [
      {
        id: 1,
        content: 'Small',
      },
      {
        id: 2,
        content: 'Large',
      },
    ],
    defaultValue: 1,
  },
  {
    id: 3,
    name: 'Crust',
    data: [
      {
        id: 1,
        content: 'Original',
      },
      {
        id: 2,
        content: 'Butter',
      },
      {
        id: 3,
        content: 'Butter Cheese',
      },
      {
        id: 4,
        content: 'Asiago Cheese',
      },
      {
        id: 5,
        content: 'Ranch',
      },
      {
        id: 6,
        content: 'Cajun',
      },
      {
        id: 7,
        content: 'Sesame',
      },
      {
        id: 8,
        content: 'Garlic Herb',
      },
      {
        id: 9,
        content: 'HH Italian Herb',
      },
      {
        id: 10,
        content: 'Southwest Ranch',
      },
    ],
    // defaultValue: 1,
  },
  {
    id: 4,
    name: 'Sauces',
    data: [
      {
        id: 1,
        content: 'Marinara',
      },
      {
        id: 2,
        content: 'BBQ Sauce',
      },
      {
        id: 3,
        content: 'Buffalo Sauce',
      },
      {
        id: 4,
        content: 'Ranch Dressing',
      },
      {
        id: 5,
        content: 'Garlic Sauce',
      },
    ],
    // defaultValue: 2,
  },
  {
    id: 5,
    name: 'Preference',
    data: [
      {
        id: 1,
        content: 'Light Done',
      },
      {
        id: 2,
        content: 'Regular Done',
      },
      {
        id: 3,
        content: 'Well Done',
      },
    ],
    // defaultValue: 1,
  },
];

export const optionsFlatbread = [
  {
    id: KeyOptionOrderEnum.PEPPERONI,
    title: 'Pepperoni',
  },
  {
    id: KeyOptionOrderEnum.SAUSAGE,
    title: 'Sausage',
  },
  {
    id: KeyOptionOrderEnum.BACON,
    title: 'Bacon',
  },
  {
    id: KeyOptionOrderEnum.KIELBASA,
    title: 'Kielbasa',
  },
  {
    id: KeyOptionOrderEnum.SPINACH,
    title: 'Spinach',
  },
  {
    id: KeyOptionOrderEnum.PINEAPPLE,
    title: 'Pineapple',
  },
  {
    id: KeyOptionOrderEnum.EGGPLANT,
    title: 'Eggplant',
  },
  {
    id: KeyOptionOrderEnum.POTATOES,
    title: 'Potatoes',
  },
  {
    id: KeyOptionOrderEnum.JALAPENO,
    title: 'Jalapeño',
  },
  {
    id: KeyOptionOrderEnum.CAULIFLOWER,
    title: 'Cauliflower',
  },
  {
    id: KeyOptionOrderEnum.MUSHROOMS,
    title: 'Mushrooms',
  },
  {
    id: KeyOptionOrderEnum.RED_ONIONS,
    title: 'Red Onions',
  },
  {
    id: KeyOptionOrderEnum.GREEN_PEPPERS,
    title: 'Green Peppers',
  },
  {
    id: KeyOptionOrderEnum.BLACK_OLIVES,
    title: 'Black Olives',
  },
  {
    id: KeyOptionOrderEnum.TOMATOES,
    title: 'Tomatoes',
  },
  {
    id: KeyOptionOrderEnum.ITALIAN_SAUSAGE,
    title: 'Italian Sausage',
  },
  {
    id: KeyOptionOrderEnum.GROUND_BEEF,
    title: 'Ground Beef',
  },
  {
    id: KeyOptionOrderEnum.GREEN_OLIVES,
    title: 'Green Olives',
  },
  {
    id: KeyOptionOrderEnum.SHRIMP,
    title: 'Shrimp',
  },
  {
    id: KeyOptionOrderEnum.CHICKEN,
    title: 'Chicken',
  },
  {
    id: KeyOptionOrderEnum.ANCHOVIES,
    title: 'Anchovies',
  },
  {
    id: KeyOptionOrderEnum.CRAB,
    title: 'Crab',
  },
  {
    id: KeyOptionOrderEnum.MACARONI,
    title: 'Macaroni',
  },
  {
    id: KeyOptionOrderEnum.GARLIC,
    title: 'Garlic',
  },
  {
    id: KeyOptionOrderEnum.HAM,
    title: 'Ham',
  },
];

// Data Filter Salads Page
export const dataFilterSalads = [
  {
    id: 1,
    name: 'Salad',
    data: [
      {
        id: 1,
        content: 'Antipasto',
      },
      {
        id: 2,
        content: 'Chef Salad',
      },
      {
        id: 3,
        content: 'Chicken Asiago',
      },
      {
        id: 4,
        content: 'Chicken Caesar',
      },
      {
        id: 5,
        content: 'Garden Salad',
      },
      {
        id: 6,
        content: 'Greek Salad',
      },
    ],
    // defaultValue: 1,
  },
  {
    id: 2,
    name: 'Size',
    data: [
      {
        id: 1,
        content: 'Regular',
      },
      {
        id: 2,
        content: 'Large',
      },
    ],
    defaultValue: 1,
  },
  {
    id: 3,
    name: 'Cheese',
    data: [
      {
        id: 1,
        content: 'Cheddar Mozzarella',
      },
      {
        id: 2,
        content: 'Feta Cheese',
      },
    ],
    defaultValue: 2,
  },
  {
    id: 4,
    name: 'Dressing',
    data: [
      {
        id: 1,
        content: 'Croutons',
      },
      {
        id: 2,
        content: 'Ranch',
      },
      {
        id: 3,
        content: 'Caesar',
      },
      {
        id: 4,
        content: 'Balsamic Vinaigrette',
      },
      {
        id: 5,
        content: 'Thousand Island',
      },
      {
        id: 6,
        content: 'Blue Cheese',
      },
      {
        id: 7,
        content: 'Greek',
      },
    ],
    defaultValue: 2,
  },
];

export const optionsSalads = [
  {
    id: KeyOptionOrderEnum.PEPPERONI,
    title: 'Pepperoni',
  },
  {
    id: KeyOptionOrderEnum.SAUSAGE,
    title: 'Sausage',
  },
  {
    id: KeyOptionOrderEnum.BACON,
    title: 'Bacon',
  },
  {
    id: KeyOptionOrderEnum.KIELBASA,
    title: 'Kielbasa',
  },
  {
    id: KeyOptionOrderEnum.SPINACH,
    title: 'Spinach',
  },
  {
    id: KeyOptionOrderEnum.PINEAPPLE,
    title: 'Pineapple',
  },
  {
    id: KeyOptionOrderEnum.EGGPLANT,
    title: 'Eggplant',
  },
  {
    id: KeyOptionOrderEnum.POTATOES,
    title: 'Potatoes',
  },
  {
    id: KeyOptionOrderEnum.JALAPENO,
    title: 'Jalapeño',
  },
  {
    id: KeyOptionOrderEnum.CAULIFLOWER,
    title: 'Cauliflower',
  },
  {
    id: KeyOptionOrderEnum.MUSHROOMS,
    title: 'Mushrooms',
  },
  {
    id: KeyOptionOrderEnum.RED_ONIONS,
    title: 'Red Onions',
  },
  {
    id: KeyOptionOrderEnum.GREEN_PEPPERS,
    title: 'Green Peppers',
  },
  {
    id: KeyOptionOrderEnum.BLACK_OLIVES,
    title: 'Black Olives',
  },
  {
    id: KeyOptionOrderEnum.TOMATOES,
    title: 'Tomatoes',
  },
  {
    id: KeyOptionOrderEnum.ITALIAN_SAUSAGE,
    title: 'Italian Sausage',
  },
  {
    id: KeyOptionOrderEnum.GROUND_BEEF,
    title: 'Ground Beef',
  },
  {
    id: KeyOptionOrderEnum.GREEN_OLIVES,
    title: 'Green Olives',
  },
  {
    id: KeyOptionOrderEnum.SHRIMP,
    title: 'Shrimp',
  },
  {
    id: KeyOptionOrderEnum.CHICKEN,
    title: 'Chicken',
  },
  {
    id: KeyOptionOrderEnum.ANCHOVIES,
    title: 'Anchovies',
  },
  {
    id: KeyOptionOrderEnum.CRAB,
    title: 'Crab',
  },
  {
    id: KeyOptionOrderEnum.MACARONI,
    title: 'Macaroni',
  },
  {
    id: KeyOptionOrderEnum.GARLIC,
    title: 'Garlic',
  },
  {
    id: KeyOptionOrderEnum.HAM,
    title: 'Ham',
  },
];

// Data Filter Salads Page
export const dataFilterSubs = [
  {
    id: 1,
    name: 'Sub',
    data: [
      {
        id: 1,
        content: 'Deluxe Combo',
      },
      {
        id: 2,
        content: 'Steak, Cheese & Mushroom',
      },
      {
        id: 3,
        content: 'Italian',
      },
      {
        id: 4,
        content: 'Ham & Cheese',
      },
      {
        id: 5,
        content: 'Chicken Bacon Ranch',
      },
      {
        id: 6,
        content: 'Pizza Deluxe',
      },
      {
        id: 7,
        content: 'Turkey Club',
      },
      {
        id: 8,
        content: 'Veggie',
      },
      {
        id: 9,
        content: 'Chicken Club',
      },
      {
        id: 10,
        content: 'Order Lookup',
      },
      {
        id: 11,
        content: 'Order Lookup',
      },
      {
        id: 12,
        content: 'Order Lookup',
      },
      {
        id: 13,
        content: 'Order Lookup',
      },
      {
        id: 14,
        content: 'Meatball',
      },
      {
        id: 15,
        content: 'Chicken Parmesan',
      },
    ],
    // defaultValue: 1,
  },
  {
    id: 2,
    name: 'Size',
    data: [
      {
        id: 1,
        content: '8” Regular',
      },
      {
        id: 2,
        content: '10” Large',
      },
    ],
    defaultValue: 1,
  },
  {
    id: 3,
    name: 'Sides',
    data: [
      {
        id: 1,
        content: 'Howie Bread',
      },
      {
        id: 2,
        content: '3 Cheeser Howie Bread',
      },
      {
        id: 3,
        content: 'Fresh Garden Salad',
      },
      {
        id: 4,
        content: 'Original Howie Wings',
      },
      {
        id: 5,
        content: 'BBQ Howie Wings',
      },
      {
        id: 6,
        content: 'Cinnamon Howie Bread',
      },
      {
        id: 7,
        content: 'Fries',
      },
    ],
    defaultValue: 7,
  },
  {
    id: 4,
    name: 'Sauces',
    data: [
      {
        id: 1,
        content: 'Marinara',
      },
      {
        id: 2,
        content: '100% Mozzarella',
      },
      {
        id: 3,
        content: 'Parmesan Cheese',
      },
      {
        id: 4,
        content: 'Butter Garlic',
      },
      {
        id: 5,
        content: 'Pizza Sauce',
      },
      {
        id: 6,
        content: 'Cheddar Mozzarella',
      },
    ],
    defaultValue: 6,
  },
  {
    id: 5,
    name: 'Preference',
    data: [
      {
        id: 1,
        content: 'Lettuce On Side',
      },
      {
        id: 2,
        content: 'Tomato On Side',
      },
      {
        id: 3,
        content: 'No Lettuce',
      },
      {
        id: 4,
        content: 'No Tomato',
      },
    ],
    defaultValue: 1,
  },
];

export const optionsSubs = [
  {
    id: KeyOptionOrderEnum.PEPPERONI,
    title: 'Pepperoni',
  },
  {
    id: KeyOptionOrderEnum.SAUSAGE,
    title: 'Sausage',
  },
  {
    id: KeyOptionOrderEnum.BACON,
    title: 'Bacon',
  },
  {
    id: KeyOptionOrderEnum.KIELBASA,
    title: 'Kielbasa',
  },
  {
    id: KeyOptionOrderEnum.SPINACH,
    title: 'Spinach',
  },
  {
    id: KeyOptionOrderEnum.PINEAPPLE,
    title: 'Pineapple',
  },
  {
    id: KeyOptionOrderEnum.EGGPLANT,
    title: 'Eggplant',
  },
  {
    id: KeyOptionOrderEnum.POTATOES,
    title: 'Potatoes',
  },
  {
    id: KeyOptionOrderEnum.JALAPENO,
    title: 'Jalapeño',
  },
  {
    id: KeyOptionOrderEnum.CAULIFLOWER,
    title: 'Cauliflower',
  },
  {
    id: KeyOptionOrderEnum.MUSHROOMS,
    title: 'Mushrooms',
  },
  {
    id: KeyOptionOrderEnum.RED_ONIONS,
    title: 'Red Onions',
  },
  {
    id: KeyOptionOrderEnum.GREEN_PEPPERS,
    title: 'Green Peppers',
  },
  {
    id: KeyOptionOrderEnum.BLACK_OLIVES,
    title: 'Black Olives',
  },
  {
    id: KeyOptionOrderEnum.TOMATOES,
    title: 'Tomatoes',
  },
  {
    id: KeyOptionOrderEnum.ITALIAN_SAUSAGE,
    title: 'Italian Sausage',
  },
  {
    id: KeyOptionOrderEnum.GROUND_BEEF,
    title: 'Ground Beef',
  },
  {
    id: KeyOptionOrderEnum.GREEN_OLIVES,
    title: 'Green Olives',
  },
  {
    id: KeyOptionOrderEnum.SHRIMP,
    title: 'Shrimp',
  },
  {
    id: KeyOptionOrderEnum.CHICKEN,
    title: 'Chicken',
  },
  {
    id: KeyOptionOrderEnum.ANCHOVIES,
    title: 'Anchovies',
  },
  {
    id: KeyOptionOrderEnum.CRAB,
    title: 'Crab',
  },
  {
    id: KeyOptionOrderEnum.MACARONI,
    title: 'Macaroni',
  },
  {
    id: KeyOptionOrderEnum.GARLIC,
    title: 'Garlic',
  },
  {
    id: KeyOptionOrderEnum.HAM,
    title: 'Ham',
  },
];

export const dataFilterBread = [
  {
    id: 1,
    name: 'Entree',
    data: [
      {
        id: 1,
        content: 'Original Howie',
      },
      {
        id: 2,
        content: '3 Cheeser Howie',
      },
      {
        id: 3,
        content: 'Asiago Howie',
      },
      {
        id: 4,
        content: 'Cajun Howie',
      },
      {
        id: 5,
        content: 'Cinnamon Howie',
      },
    ],
    defaultValue: 2,
  },
  {
    id: 2,
    name: 'Size',
    data: [
      {
        id: 1,
        content: 'Regular',
      },
      {
        id: 2,
        content: 'Large',
      },
    ],
    defaultValue: 1,
  },
  {
    id: 3,
    name: 'Seasoning',
    data: [
      {
        id: 1,
        content: 'Italian Herb',
      },
      {
        id: 2,
        content: 'No Seasoning',
      },
    ],
    defaultValue: 1,
  },
  {
    id: 4,
    name: 'Sauces',
    data: [
      {
        id: 1,
        content: 'Pizza Sauce',
      },
      {
        id: 2,
        content: 'Extra Cheese',
      },
    ],
    defaultValue: 1,
  },
];

export const optionsBread = [
  {
    id: KeyOptionOrderEnum.PEPPERONI,
    title: 'Pepperoni',
  },
  {
    id: KeyOptionOrderEnum.SAUSAGE,
    title: 'Sausage',
  },
  {
    id: KeyOptionOrderEnum.BACON,
    title: 'Bacon',
  },
  {
    id: KeyOptionOrderEnum.KIELBASA,
    title: 'Kielbasa',
  },
  {
    id: KeyOptionOrderEnum.SPINACH,
    title: 'Spinach',
  },
  {
    id: KeyOptionOrderEnum.PINEAPPLE,
    title: 'Pineapple',
  },
  {
    id: KeyOptionOrderEnum.EGGPLANT,
    title: 'Eggplant',
  },
  {
    id: KeyOptionOrderEnum.POTATOES,
    title: 'Potatoes',
  },
  {
    id: KeyOptionOrderEnum.JALAPENO,
    title: 'Jalapeño',
  },
  {
    id: KeyOptionOrderEnum.CAULIFLOWER,
    title: 'Cauliflower',
  },
  {
    id: KeyOptionOrderEnum.MUSHROOMS,
    title: 'Mushrooms',
  },
  {
    id: KeyOptionOrderEnum.RED_ONIONS,
    title: 'Red Onions',
  },
  {
    id: KeyOptionOrderEnum.GREEN_PEPPERS,
    title: 'Green Peppers',
  },
  {
    id: KeyOptionOrderEnum.BLACK_OLIVES,
    title: 'Black Olives',
  },
  {
    id: KeyOptionOrderEnum.TOMATOES,
    title: 'Tomatoes',
  },
  {
    id: KeyOptionOrderEnum.ITALIAN_SAUSAGE,
    title: 'Italian Sausage',
  },
  {
    id: KeyOptionOrderEnum.GROUND_BEEF,
    title: 'Ground Beef',
  },
  {
    id: KeyOptionOrderEnum.GREEN_OLIVES,
    title: 'Green Olives',
  },
  {
    id: KeyOptionOrderEnum.SHRIMP,
    title: 'Shrimp',
  },
  {
    id: KeyOptionOrderEnum.CHICKEN,
    title: 'Chicken',
  },
  {
    id: KeyOptionOrderEnum.ANCHOVIES,
    title: 'Anchovies',
  },
  {
    id: KeyOptionOrderEnum.CRAB,
    title: 'Crab',
  },
  {
    id: KeyOptionOrderEnum.MACARONI,
    title: 'Macaroni',
  },
  {
    id: KeyOptionOrderEnum.GARLIC,
    title: 'Garlic',
  },
  {
    id: KeyOptionOrderEnum.HAM,
    title: 'Ham',
  },
];

// Data Filter Salads Page
export const dataFilterDrinks = [
  {
    id: 1,
    name: 'Size',
    data: [
      {
        id: 1,
        content: '2 Liter',
      },
      {
        id: 2,
        content: '20 Ounce',
      },
    ],
    defaultValue: 1,
  },
];

export const contentButtonDrinks = [
  { id: 1, content: `Orange Crush`, topingIds: [] },
  { id: 2, content: `Aquafina`, topingIds: [] },
  { id: 3, content: `Diet Pepsi`, topingIds: [] },
  {
    id: 4,
    content: `Dr. Pepper`,
    topingIds: [],
  },
  { id: 5, content: `Lipton Iced Tea`, topingIds: [] },
  { id: 6, content: `Mountain Dew`, topingIds: [] },
  { id: 7, content: `Pepsi`, topingIds: [] },
  { id: 8, content: `Starry`, topingIds: [] },
  { id: 9, content: `Coke Classic`, topingIds: [] },
  { id: 10, content: `Chai Tea`, topingIds: [] },
];

// Side bar order type
export const orderTypeSidebar = [
  {
    id: 1,
    title: 'Delivery',
    isActive: true,
  },
  {
    id: 2,
    title: 'Pickup',
    isActive: false,
  },
  {
    id: 3,
    title: 'Dine-In',
    isActive: false,
  },
  {
    id: 4,
    title: 'TP Delivery',
    isActive: false,
  },
  {
    id: 5,
    title: 'Walk-In',
    isActive: false,
  },
  {
    id: 6,
    title: 'To Go',
    isActive: false,
  },
];
