import { IconSvgProps } from '~/utils/interface/common';
import styles from './styles.module.scss';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

const IconWingsSvg = (props: IconSvgProps) => {
  //#region Destructuring Props
  const { strokePath = '#1A1346' } = props;
  //#endregion Destructuring Props

  return (
    <svg className={cx('icon-wings')} viewBox='0 0 36 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        id='Vector'
        d='M17.5344 20.3324L14.9797 14.4714M14.9797 14.4714C14.9797 14.4714 13.9278 11.9167 12.4249 8.6105C10.922 5.30428 5.21132 3.80153 2.20582 8.6105C-0.799677 13.4195 2.5059 17.6273 4.6099 19.7312C6.7139 21.8351 13.3266 28.4475 14.5288 29.3492C15.731 30.2509 18.5363 31.7536 20.54 30.5514C22.5437 29.3492 22.6441 26.3436 22.9445 23.9391C23.0313 23.2448 23.2183 21.7734 23.4405 20.0318M14.9797 14.4714L18.7367 3.80153C18.7367 3.80153 19.3378 1.6976 22.0429 1.09648C24.7479 0.495354 26.8518 2.89984 26.8518 2.89984L34.3659 10.4139C34.3659 10.4139 32.863 12.5177 29.557 12.2172C26.251 11.9166 24.7479 9.81275 24.7479 9.81275M24.7479 9.81275L22.9445 8.00932M24.7479 9.81275C24.7479 9.81275 24.464 12.0268 24.1184 14.7278M24.1184 14.7278L21.7424 12.5177M24.1184 14.7278C23.8983 16.447 23.6533 18.3634 23.4405 20.0318M23.4405 20.0318L20.2396 17.3267M9.11867 10.7142C9.11867 11.3782 8.58041 11.9165 7.91643 11.9165C7.25244 11.9165 6.71418 11.3782 6.71418 10.7142C6.71418 10.0502 7.25244 9.51198 7.91643 9.51198C8.58041 9.51198 9.11867 10.0502 9.11867 10.7142ZM7.91643 14.9221C7.91643 15.5861 7.37816 16.1243 6.71418 16.1243C6.0502 16.1243 5.51194 15.5861 5.51194 14.9221C5.51194 14.2581 6.0502 13.7198 6.71418 13.7198C7.37816 13.7198 7.91643 14.2581 7.91643 14.9221ZM12.1243 16.1243C12.1243 16.7883 11.586 17.3266 10.922 17.3266C10.2581 17.3266 9.71979 16.7883 9.71979 16.1243C9.71979 15.4603 10.2581 14.9221 10.922 14.9221C11.586 14.9221 12.1243 15.4603 12.1243 16.1243Z'
        stroke={strokePath}
        strokeWidth='1.20224'
        strokeLinecap='round'
      />
    </svg>
  );
};

export default IconWingsSvg;
