import React, { MouseEvent } from 'react';
import classNames from 'classnames/bind';
import icons from '~/assets/icons';
import styles from './BackButton.module.scss';

type BackButtonProps = {
  onBackButtonClick: (event: MouseEvent<HTMLButtonElement>) => void;
};

const cx = classNames.bind(styles);

const BackButton = (props: BackButtonProps) => {
  const { onBackButtonClick } = props;

  return (
    <button className={cx('back-btn')} onClick={onBackButtonClick}>
      <img src={icons.backWhiteSvg} alt='backSvg' className={cx('back-btn-responsive')} />
    </button>
  );
};

export default BackButton;
