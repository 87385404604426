import classNames from 'classnames/bind';

import styles from './TimeClock.module.scss';
import icons from '~/assets/icons';

const cx = classNames.bind(styles);

type TimeClockProps = {
  contentTime: string;
  valueTime: string;
};

const TimeClock = (props: TimeClockProps) => {
  const { contentTime = 'Clocked-in at', valueTime = '9:45am' } = props;
  return (
    <div id='timeClock' className={cx('time-clock-container')}>
      <div className={cx('time-clock-icon')}>
        <img src={icons.clockSvg} alt='clock' />
      </div>
      <div className={cx('time-clock-content')}>
        <p className={cx('time-clock-content-text')}>{contentTime}</p>
        <p className={cx('time-clock-content-text-bold')}>{valueTime}</p>
      </div>
    </div>
  );
};

export default TimeClock;
