import classNames from 'classnames/bind';
import styles from './FlatbreadPage.module.scss';
import { dataFilterFlatbread, optionsFlatbread, toppingPizza } from '~/utils/constants/mockData';
import { ButtonFoodOrder, ButtonFoodOrderTouch, SelectBox } from '~/components/specific';
import { KeyOptionOrderEnum, NameSelectBoxHeaderFlatbreadEnum } from '~/utils/enum';
import { IItemSelected, IToppingItemSelected } from '~/utils/interface/common';
import { IItemOrder } from '~/sagas/order/state';
import { useDispatch, useSelector } from 'react-redux';
import { orderActions, selectOrder } from '~/sagas/order/slice';
import { useState } from 'react';

interface Props {}

const cx = classNames.bind(styles);

const FlatbreadPage = (props: Props) => {
  const dispatch = useDispatch();
  const isTouchScreen = JSON.parse(localStorage.getItem('isTouchScreen') ?? 'false');

  const [sizeSelected, setSizeSelected] = useState<string>('Small');
  const orderSelector = useSelector(selectOrder);

  const handleChangeEntree = (itemSelected: IItemSelected) => {
    const flatbreadSelected: IItemOrder = {
      id: Date.now(),
      name: itemSelected.value,
      size: sizeSelected,
      money: sizeSelected === 'Small' ? 10 : 12,
      type: KeyOptionOrderEnum.FLATBREAD,
      quantity: 1,
      toping: [],
    };
    dispatch(orderActions.addToOrder(flatbreadSelected));
  };

  const handleChangeSize = (itemSelected: IItemSelected) => {
    const id = orderSelector[0]?.id;
    if (!id) {
      return;
    }
    if (orderSelector[0].type === KeyOptionOrderEnum.FLATBREAD) {
      // TODO handle logic
      const payloadUpdate = {
        id: id,
        newSize: itemSelected.value,
        money: itemSelected.value === 'Small' ? 10 : 12,
      };
      dispatch(orderActions.updateSizeItem(payloadUpdate));
      setSizeSelected(itemSelected.value);
    }
  };

  const handleChangeCrust = (itemSelected: IItemSelected) => {
    // TODO handle logic
  };

  const handleChangeSauce = (itemSelected: IItemSelected) => {
    // TODO handle logic
  };

  const handleChangePreference = (itemSelected: IItemSelected) => {
    // TODO handle logic
  };

  const handleChangeSelectBox = (itemSelected: IItemSelected) => {
    if (itemSelected.id === -1) {
      return;
    }
    switch (itemSelected.nameSelectBox) {
      case NameSelectBoxHeaderFlatbreadEnum.ENTREE:
        handleChangeEntree(itemSelected);
        break;
      case NameSelectBoxHeaderFlatbreadEnum.SIZE:
        handleChangeSize(itemSelected);
        break;
      case NameSelectBoxHeaderFlatbreadEnum.CRUST:
        handleChangeCrust(itemSelected);
        break;
      case NameSelectBoxHeaderFlatbreadEnum.SAUCES:
        handleChangeSauce(itemSelected);
        break;
      case NameSelectBoxHeaderFlatbreadEnum.PREFERENCE:
        handleChangePreference(itemSelected);
        break;
    }
  };

  const handleChangeTopping = (itemSelected: IToppingItemSelected) => {
    const id = orderSelector[0]?.id;
    if (!id) {
      return;
    }
    if (!itemSelected.value) {
      const itemTopingRemove = orderSelector[0].toping.find(
        (t: any) => t.position === itemSelected.position && t.title === itemSelected.title
      );
      if (!itemTopingRemove) {
        return;
      }

      const payloadRemoveToping = {
        id: id,
        idToping: itemTopingRemove.id,
      };
      dispatch(orderActions.removeTopingOrder(payloadRemoveToping));
      return;
    }
    const dataAddToping = {
      id: id,
      newToping: {
        id: Date.now(),
        position: itemSelected.position,
        quantity: itemSelected.value,
        name: `${itemSelected.value} ${itemSelected.position} ${itemSelected.title}`,
        title: itemSelected.title,
        money: 2,
      },
    };
    const topingExited = orderSelector[0].toping.find(
      (t: any) => t.title === itemSelected.title && t.position === itemSelected.position
    );
    if (topingExited) {
      dispatch(orderActions.updateTopingOrder(dataAddToping));
      return;
    }
    dispatch(orderActions.addTopingOrder(dataAddToping));
  };

  return (
    <div className={cx('page-container')}>
      <div className={cx('page-head-wrap')}>
        {dataFilterFlatbread.map((item) => {
          const itemDefault = item.data.find((x) => x.id === item.defaultValue);

          return (
            <SelectBox
              key={item.id}
              name={item.name}
              data={item.data}
              defaultValue={itemDefault}
              onChange={handleChangeSelectBox}
            />
          );
        })}
      </div>

      <div className={cx('page-divide-line')} />

      {isTouchScreen ? (
        <div className={cx('page-body-wrap')}>
          {optionsFlatbread.map((item) => (
            <ButtonFoodOrderTouch
              toppingItem={item}
              isActive={false}
              data={toppingPizza}
              key={item.id}
              onChange={handleChangeTopping}
            />
          ))}
        </div>
      ) : (
        <div className={cx('page-body-wrap')}>
          {optionsFlatbread.map((item) => (
            <ButtonFoodOrder
              toppingItem={item}
              isActive={true}
              data={toppingPizza}
              key={item.id}
              onChange={handleChangeTopping}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default FlatbreadPage;
