import { IconSvgProps } from '~/utils/interface/common';
import styles from './styles.module.scss';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

const IconArrowDown = (props: IconSvgProps) => {
  //#region Destructuring Props
  const { strokePath = '#1A1346' } = props;
  //#endregion Destructuring Props

  return (
    <svg className={cx('icon-arrow')} viewBox='0 0 13 8' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.2629 0L6.27045 5.1901L1.24786 0.032762L0 1.38798L6.24034 8L12.5714 1.42075L11.2629 0Z'
        fill={strokePath}
      />
    </svg>
  );
};

export default IconArrowDown;
