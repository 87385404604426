import { Listbox, Transition } from '@headlessui/react';
import classNames from 'classnames/bind';
import { Fragment, useEffect, useState } from 'react';
import styles from './ButtonFoodOrder.module.scss';

import { useSelector } from 'react-redux';
import { selectTopingRemoved, selectTypePizzaSelected } from '~/sagas/order/slice';
import { IToping } from '~/sagas/order/state';
import { emptyValue } from '~/utils/constants/common';
import { ToppingSelectorPositionEnum, ToppingValueEnum } from '~/utils/enum';
import {
  IToppingItem,
  IToppingItemSelected,
  IToppingValueSelected,
  SelectorToppingData,
} from '~/utils/interface/common';
import { useLocation } from 'react-router-dom';
import { selectIsCustomPizza } from '~/sagas/pizza/slice';

export type SelectorProps = {
  toppingItem: IToppingItem;
  data: SelectorToppingData[];
  isActive: boolean;
  onChange?: (itemSelected: IToppingItemSelected) => void;
};

const cx = classNames.bind(styles);

const ButtonFoodOrder = (props: SelectorProps) => {
  const { toppingItem, data, isActive, onChange } = props;

  const location = useLocation();

  const topingRemovedSelector: IToping = useSelector(selectTopingRemoved);
  const typePizzaSelected: number = useSelector(selectTypePizzaSelected);
  const isCustomPizza: number = useSelector(selectIsCustomPizza);

  const [isClickNone, setIsClickNone] = useState<boolean>(false);
  const [valueSelected, setValueSelected] = useState<IToppingValueSelected>({
    Left: '',
    Mid: '',
    Right: '',
  });

  useEffect(() => {
    setValueSelected({
      Left: '',
      Mid: '',
      Right: '',
    });
  }, [typePizzaSelected]);

  useEffect(() => {
    if (topingRemovedSelector && topingRemovedSelector.title === toppingItem.title) {
      setValueSelected((prev) => {
        return {
          ...prev,
          [topingRemovedSelector.position]: '',
        };
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [topingRemovedSelector]);

  const checkValue = (value: string) => {
    if (value === ToppingValueEnum.LIGHT) {
      return 'Lt.';
    }
    if (value === ToppingValueEnum.NONE) {
      return '';
    }
    return value;
  };

  const onValueChange = (value: string, positionSelected: ToppingSelectorPositionEnum, e?: MouseEvent) => {
    const checkedValue = checkValue(value);

    if (positionSelected === ToppingSelectorPositionEnum.MID) {
      if (!checkedValue) {
        setIsClickNone(true);
      } else {
        setIsClickNone(false);
      }
    }

    const newValueSelect = { ...valueSelected, [positionSelected]: checkedValue };
    const newToppingItem = {
      ...toppingItem,
      position: positionSelected,
      value: checkedValue,
      selectedValue: newValueSelect,
    };

    onChange && onChange(newToppingItem);
    setValueSelected(newValueSelect);
  };

  return (
    <div className={cx('topping-select-container')}>
      <div className={cx('topping-name')}>{toppingItem.title}</div>

      <div className={cx('topping-select-wrap')}>
        {/* Left */}
        <div
          className={cx([
            'topping-left',
            'btn-topping-common-style',
            valueSelected[ToppingSelectorPositionEnum.LEFT] && 'btn-topping-active',
          ])}
        >
          <Listbox
            defaultValue={emptyValue}
            value={valueSelected[ToppingSelectorPositionEnum.LEFT]}
            // disabled={!isActive}
            onChange={(value) => onValueChange(value, ToppingSelectorPositionEnum.LEFT)}
          >
            <Listbox.Button className={cx('list-box-btn')}>
              {valueSelected[ToppingSelectorPositionEnum.LEFT] && (
                <div className={cx(['btn-topping-text'])}>
                  {valueSelected[ToppingSelectorPositionEnum.LEFT] || emptyValue}
                </div>
              )}
            </Listbox.Button>

            <Transition
              as={Fragment}
              enter='transition duration-100 ease-out'
              enterFrom='transform scale-50 opacity-0'
              enterTo='transform scale-100 opacity-100'
              leave='transition duration-50 ease-out'
              leaveFrom='transform scale-100 opacity-100'
              leaveTo='transform scale-50 opacity-0'
            >
              <Listbox.Options className={cx('topping-list-selector')}>
                {data.map((item) => (
                  <Listbox.Option key={item.id} value={item.value}>
                    {({ active, selected }) => (
                      <div
                        className={cx([
                          'topping-selector-item',
                          (active || selected) && 'topping-selector-item-active',
                        ])}
                      >
                        {item.value}
                      </div>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </Listbox>
        </div>

        {/* Mid */}

        {location.pathname !== '/pizza' || isCustomPizza ? (
          <div
            className={cx([
              'topping-mid',
              'btn-topping-common-style',
              valueSelected[ToppingSelectorPositionEnum.MID] && 'btn-topping-active',
            ])}
          >
            <Listbox
              defaultValue={emptyValue}
              value={valueSelected[ToppingSelectorPositionEnum.MID]}
              // disabled={!isActive}
              onChange={(value) => onValueChange(value, ToppingSelectorPositionEnum.MID)}
            >
              <Listbox.Button className={cx('list-box-btn')}>
                {valueSelected[ToppingSelectorPositionEnum.MID] && (
                  <div className={cx(['btn-topping-text'])}>
                    {valueSelected[ToppingSelectorPositionEnum.MID] || emptyValue}
                  </div>
                )}
              </Listbox.Button>

              <Transition
                as={Fragment}
                enter='transition duration-100 ease-out'
                enterFrom='transform scale-50 opacity-0'
                enterTo='transform scale-100 opacity-100'
                leave='transition duration-50 ease-out'
                leaveFrom='transform scale-100 opacity-100'
                leaveTo='transform scale-50 opacity-0'
              >
                <Listbox.Options className={cx('topping-list-selector')}>
                  {data.map((item) => (
                    <Listbox.Option key={item.id} value={item.value}>
                      {({ active, selected }) => (
                        <div
                          className={cx([
                            'topping-selector-item',
                            (active || selected) && 'topping-selector-item-active',
                          ])}
                        >
                          {item.value}
                        </div>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </Listbox>
          </div>
        ) : (
          <div
            id='divMidActive'
            className={cx([
              'topping-mid',
              'btn-topping-common-style',
              (isActive || valueSelected[ToppingSelectorPositionEnum.MID]) && !isClickNone ? 'btn-topping-active' : '',
            ])}
          >
            <Listbox
              defaultValue={emptyValue}
              value={valueSelected[ToppingSelectorPositionEnum.MID]}
              // disabled={!isActive}
              onChange={(value) => onValueChange(value, ToppingSelectorPositionEnum.MID)}
            >
              <Listbox.Button className={cx('list-box-btn')}>
                {valueSelected[ToppingSelectorPositionEnum.MID] && (
                  <div className={cx(['btn-topping-text'])}>
                    {valueSelected[ToppingSelectorPositionEnum.MID] || emptyValue}
                  </div>
                )}
              </Listbox.Button>

              <Transition
                as={Fragment}
                enter='transition duration-100 ease-out'
                enterFrom='transform scale-50 opacity-0'
                enterTo='transform scale-100 opacity-100'
                leave='transition duration-50 ease-out'
                leaveFrom='transform scale-100 opacity-100'
                leaveTo='transform scale-50 opacity-0'
              >
                <Listbox.Options className={cx('topping-list-selector')}>
                  {data.map((item) => (
                    <Listbox.Option key={item.id} value={item.value}>
                      {({ active, selected }) => (
                        <div
                          className={cx([
                            'topping-selector-item',
                            (active || selected) && 'topping-selector-item-active',
                          ])}
                        >
                          {item.value}
                        </div>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </Listbox>
          </div>
        )}

        {/* Right */}
        <div
          className={cx([
            'topping-right',
            'btn-topping-common-style',
            valueSelected[ToppingSelectorPositionEnum.RIGHT] && 'btn-topping-active',
          ])}
        >
          <Listbox
            defaultValue={emptyValue}
            value={valueSelected[ToppingSelectorPositionEnum.RIGHT]}
            // disabled={!isActive}
            onChange={(value) => onValueChange(value, ToppingSelectorPositionEnum.RIGHT)}
          >
            <Listbox.Button className={cx('list-box-btn')}>
              {valueSelected[ToppingSelectorPositionEnum.RIGHT] && (
                <div className={cx(['btn-topping-text'])}>
                  {valueSelected[ToppingSelectorPositionEnum.RIGHT] || emptyValue}
                </div>
              )}
            </Listbox.Button>

            <Transition
              as={Fragment}
              enter='transition duration-100 ease-out'
              enterFrom='transform scale-50 opacity-0'
              enterTo='transform scale-100 opacity-100'
              leave='transition duration-50 ease-out'
              leaveFrom='transform scale-100 opacity-100'
              leaveTo='transform scale-50 opacity-0'
            >
              <Listbox.Options className={cx('topping-list-selector')}>
                {data.map((item) => (
                  <Listbox.Option key={item.id} value={item.value}>
                    {({ active, selected }) => (
                      <div
                        className={cx([
                          'topping-selector-item',
                          (active || selected) && 'topping-selector-item-active',
                        ])}
                      >
                        {item.value}
                      </div>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </Listbox>
        </div>
      </div>
    </div>
  );
};

export default ButtonFoodOrder;
