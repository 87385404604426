import { useCallback, useEffect, useRef, useState } from 'react';

export const useTouchScreen = () => {
  const [isTouchScreen, setIsTouchScreen] = useState(false);

  useEffect(() => {
    const detectTouchScreen = () => {
      const isTouchScreenLocal = localStorage.getItem('isTouchScreen');
      if (!isTouchScreenLocal) {
        setIsTouchScreen('ontouchstart' in window || navigator.maxTouchPoints > 0);
        localStorage.setItem('isTouchScreen', `${'ontouchstart' in window || navigator.maxTouchPoints > 0}`);
      }
    };

    detectTouchScreen(); // Initial detection

    window.addEventListener('resize', detectTouchScreen);

    return () => {
      window.removeEventListener('resize', detectTouchScreen);
    };
  }, []);

  return isTouchScreen;
};

export const useLongPress = (
  onLongPress: (e: any) => void,
  onClick: () => void,
  { shouldPreventDefault = true, delay = 300 } = {}
) => {
  const [longPressTriggered, setLongPressTriggered] = useState(false);
  const timeout = useRef<any>();
  const target = useRef<any>();

  const start = useCallback(
    (event: any) => {
      if (shouldPreventDefault && event.target) {
        event.target.addEventListener('touchend', preventDefault, {
          passive: false,
        });
        target.current = event.target;
      }
      timeout.current = setTimeout(() => {
        onLongPress(event);
        setLongPressTriggered(true);
      }, delay);
    },
    [onLongPress, delay, shouldPreventDefault]
  );

  const clear = useCallback(
    (event: any, shouldTriggerClick = true) => {
      timeout.current && clearTimeout(timeout.current);
      shouldTriggerClick && !longPressTriggered && onClick();
      setLongPressTriggered(false);
      if (shouldPreventDefault && target.current) {
        target.current.removeEventListener('touchend', preventDefault);
      }
    },
    [shouldPreventDefault, onClick, longPressTriggered]
  );

  return {
    onMouseDown: (e: any) => start(e),
    onTouchStart: (e: any) => start(e),
    onMouseUp: (e: any) => clear(e),
    onMouseLeave: (e: any) => clear(e, false),
    onTouchEnd: (e: any) => clear(e),
  };
};

const isTouchEvent = (event: any) => {
  return 'touches' in event;
};

const preventDefault = (event: any) => {
  if (!isTouchEvent(event)) return;

  if (event.touches.length < 2 && event.preventDefault) {
    event.preventDefault();
  }
};

export const useDelayVisibility = (initialState: boolean, delay: number) => {
  const [isVisible, setIsVisible] = useState(initialState);

  const handleClickWithDelay = () => {
    setIsVisible(true);

    setTimeout(() => {
      setIsVisible(false);
    }, delay);
  };

  return { isVisible, handleClickWithDelay };
};
