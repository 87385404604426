import classNames from 'classnames/bind';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  dataSelectBoxHeaderPasta,
  optionsOrderPizza,
  optionsPasta,
  toppingPizza,
  toppingPizzaTouch,
} from '~/utils/constants/mockData';
import { NameSelectBoxHeaderWingsEnum } from '~/utils/enum';
import styles from './PastaPage.module.scss';
import { orderActions } from '~/sagas/order/slice';
import { ButtonFoodOrder, ButtonFoodOrderTouch, SelectBox } from '~/components/specific';
import { IItemSelected } from '~/utils/interface/common';

const cx = classNames.bind(styles);

const PastaPage = () => {
  const dispatch = useDispatch();
  const isTouchScreen = JSON.parse(localStorage.getItem('isTouchScreen') ?? 'false');

  const handleChangeSelectBox = (itemSelected: IItemSelected) => {
    if (itemSelected.id === -1) {
      return;
    }
  };

  return (
    <div id='pastaPage' className={cx('page-container')}>
      <div className={cx('page-head-wrap')}>
        {dataSelectBoxHeaderPasta.map((item) => {
          const itemDefault = item.data.find((x) => x.id === item.defaultValue);
          return (
            <SelectBox
              key={item.name}
              name={item.name}
              data={item.data}
              defaultValue={itemDefault}
              onChange={handleChangeSelectBox}
            />
          );
        })}
      </div>

      <div className={cx('page-divide-line')} />

      {isTouchScreen ? (
        <div className={cx('page-body-wrap')}>
          {optionsOrderPizza.map((item) => (
            <ButtonFoodOrderTouch toppingItem={item} isActive={false} data={toppingPizzaTouch} key={item.id} />
          ))}
        </div>
      ) : (
        <div className={cx('page-body-wrap')}>
          {optionsPasta.map((item) => (
            <ButtonFoodOrder toppingItem={item} isActive={true} data={toppingPizza} key={item.id} />
          ))}
        </div>
      )}
    </div>
  );
};

export default PastaPage;
