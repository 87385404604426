import classNames from 'classnames/bind';
import { useState } from 'react';
import styles from './OrderType.module.scss';
import icons from '~/assets/icons';
import { orderTypes } from '~/utils/constants/mockData';

interface Props {}

const cx = classNames.bind(styles);

const OrderType = (props: Props) => {
  const [orderType, setOrderType] = useState(orderTypes[0]);

  const handleSelectOrderType = () => {};

  return (
    <div id='order-type' className={cx('order-type-container')}>
      <div className={cx('order-type-selected-wrapper')} onClick={handleSelectOrderType}>
        <div className={cx('order-type-selected')}>
          <span className={cx('order-type-selected-title')}>{`Order Type: `}</span>
          <span className={cx('order-type-selected-label')}>{orderType}</span>
        </div>
        <div className={cx('order-type-arrow')}>
          <img src={icons.arrowDownSvg} alt='Arrow' />
        </div>
      </div>
    </div>
  );
};

export default OrderType;
