import { MouseEvent } from 'react';
import classNames from 'classnames/bind';
import styles from './Footer.module.scss';
import { FooterButton } from '~/components/specific';
import { footerOrderButton, footerOrderButtonSaveAndReturn } from '~/utils/constants/mockData';
import { TypeFooterButtonEnum } from '~/utils/enum';

type FooterProps = {
  onSendButtonClick: (event: MouseEvent<HTMLButtonElement>) => void;
};

const cx = classNames.bind(styles);

const Footer = (props: FooterProps) => {
  const { onSendButtonClick } = props;

  return (
    <div id='footerOrder' className={cx('footer-order-container')}>
      <div className={cx('footer-order-group-button')}>
        {footerOrderButton.map((item, index) => (
          <div key={index}>
            <FooterButton content={item.content} type={TypeFooterButtonEnum.ACTION} />
          </div>
        ))}
      </div>
      <div className={cx('footer-order-save-return')}>
        <FooterButton
          content={footerOrderButtonSaveAndReturn}
          type={TypeFooterButtonEnum.SEND}
          onClick={onSendButtonClick}
        />
      </div>
    </div>
  );
};

export default Footer;
