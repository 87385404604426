import classNames from 'classnames/bind';
import styles from './DateTitle.module.scss';
import { TypeDateEnum } from '~/utils/enum';
import { LOWER_BOUND_FOR_TH_SUFFIX, SPECIAL_NUMBER_FOR_SUFFIXES, UPPER_BOUND_FOR_TH_SUFFIX, daysOfWeek, months } from '~/utils/constants/mockData';

type DateTitleProps = {
  type: TypeDateEnum;
};

const cx = classNames.bind(styles);

const DateTitle = (props: DateTitleProps) => {
  const { type = TypeDateEnum.LOGIN } = props;

  let textClassNameByType = '';
  let containerClassNameByType = '';

  const getDayWithOrdinal = (day: number) => {
    if (day >= LOWER_BOUND_FOR_TH_SUFFIX && day <= UPPER_BOUND_FOR_TH_SUFFIX) {
      return `${day}th`;
    } else {
      const lastDigit = day % SPECIAL_NUMBER_FOR_SUFFIXES;
      return [`${day}th`, `${day}st`, `${day}nd`, `${day}rd`][lastDigit] || `${day}th`;
    }
  };

  const formatDate = (currentDate: Date) => {
    const day = currentDate.getDate();
    const month = currentDate.getMonth();
    const year = currentDate.getFullYear();

    const dayWithOrdinal = getDayWithOrdinal(day);
    const dayOfWeek = daysOfWeek[currentDate.getDay()];
    const monthName = months[month];

    return `${dayOfWeek}, ${monthName} ${dayWithOrdinal}, ${year}`;
  };

  if (type === TypeDateEnum.LOGIN) {
    textClassNameByType = 'date-title-login';
    containerClassNameByType = 'date-title-container-login';
  }

  if (type === TypeDateEnum.WELCOME) {
    textClassNameByType = 'date-title-welcome';
    containerClassNameByType = 'date-title-container-welcome';
  }

  return (
    <div id='dateTitleContainer' className={cx([containerClassNameByType])}>
      <p id='dateTitle' className={cx('date-title-container', [textClassNameByType])}>{`${formatDate(new Date())}`}</p>
    </div>
  );
};

export default DateTitle;
