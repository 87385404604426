import { IconSvgProps } from '~/utils/interface/common';
import styles from './styles.module.scss';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

const IconPizzaSvg = (props: IconSvgProps) => {
  //#region Destructuring Props
  const { strokePath = '#1A1346' } = props;
  //#endregion Destructuring Props

  return (
    <svg className={cx('icon-pizza')} viewBox='0 0 31 31' fill='none' color='red' xmlns='http://www.w3.org/2000/svg'>
      <path
        fill='none'
        d='M23.8375 11.9113L29.9756 15.5484L5.062 29.9756C-0.354001 20.2767 -0.354001 10.6989 5.062 1L17.0976 8.03174M23.8375 11.9113L17.0976 8.03174M23.8375 11.9113C23.5395 12.6385 23.0413 13.2647 22.4015 13.7158C21.7617 14.1669 21.0071 14.4242 20.2268 14.4573C19.1734 14.4573 18.1632 14.0358 17.4184 13.2855C16.6735 12.5352 16.2551 11.5176 16.2551 10.4565C16.2952 9.58278 16.5881 8.73997 17.0976 8.03174M9.27445 3.06103C6.9705 6.79044 5.74936 11.0952 5.74936 15.4878C5.74936 19.8804 6.9705 24.1852 9.27445 27.9146M12.4037 20.1554C11.1407 20.1554 10.117 19.1241 10.117 17.8519C10.117 16.5797 11.1407 15.5484 12.4037 15.5484C13.6666 15.5484 14.6905 16.5797 14.6905 17.8519C14.6905 19.1241 13.6666 20.1554 12.4037 20.1554Z'
        stroke={strokePath}
        strokeWidth='1.32602'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default IconPizzaSvg;
