import { useContext, useEffect, useState } from 'react';
import classNames from 'classnames/bind';

import { SidebarContext } from '~/context';
import { defaultTax } from '~/utils/constants/common';
import { formatNumberToCurrency } from '~/utils/helper';
import styles from './OrderPriceRow.module.scss';

type Props = {
  subTotal: number;
  tax: number;
};

const cx = classNames.bind(styles);

const OrderPriceRow = (props: Props) => {
  const { subTotal, tax } = props;
  const { price } = useContext(SidebarContext);
  const [totalPrice, setTotalPrice] = useState<number>(0);

  useEffect(() => {
    if (price <= 0) {
      setTotalPrice(0);
      return;
    }
    const tax = price * defaultTax;
    const total = price + tax;
    setTotalPrice(total);
  }, [price]);

  return (
    <div className={cx('price-row-wrap')}>
      <div className={cx('price-sub-total-tax-wrap')}>
        <div className={cx(['price-format-display', 'price-sub-total'])}>
          <span className={cx('price-title')}>Subtotal</span>
          <span>{`${formatNumberToCurrency(subTotal)}`}</span>
        </div>
        <div className={cx(['price-format-display', 'price-tax'])}>
          <span className={cx('price-title')}>Tax</span>
          <span>{`${formatNumberToCurrency(tax)}`}</span>
        </div>
      </div>

      <div className={cx(['price-format-display', 'price-total'])}>
        <span>Total</span>
        <span>{`${formatNumberToCurrency(subTotal + tax)}`}</span>
      </div>
    </div>
  );
};

export default OrderPriceRow;
