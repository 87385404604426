/**
 * Format number to currency
 * @param value is price need format
 * @returns format the like $0.00
 */
export const formatNumberToCurrency = (value: number): string => {
  if (!value) {
    return '$0.00';
  }

  const formattedNumber = value.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });

  return formattedNumber;
};
