import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './state';

const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    setIsTouchScreen(state, action) {
      state.isTouchScreen = action.payload;
    },
    resetStateIsTouchScreen(state) {
      state.isTouchScreen = false;
    },
  },
});

// Actions
export const commonActions = commonSlice.actions;

// Selectors
export const selectIsTouchScreen = (state: any) => state.common.isTouchScreen;

// Reducer
const commonReducer = commonSlice.reducer;
export default commonReducer;
