import { IconSvgProps } from '~/utils/interface/common';
import styles from './styles.module.scss';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

const IconDessertSvg = (props: IconSvgProps) => {
  //#region Destructuring Props
  const { strokePath = '#1A1346' } = props;
  //#endregion Destructuring Props

  return (
    <svg className={cx('icon-deserts')} viewBox='0 0 33 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        id='Vector'
        d='M31.7317 9.5808V23.203H1V9.5808M31.7317 9.5808H13.4235V16.8823C13.4238 17.1922 13.3612 17.499 13.2394 17.784C13.1177 18.069 12.9393 18.3263 12.7151 18.5403C12.4909 18.7543 12.2256 18.9206 11.9352 19.0289C11.6449 19.1373 11.3355 19.1856 11.0259 19.1708H10.917C10.31 19.1708 9.72792 18.9297 9.29873 18.5006C8.86955 18.0714 8.62844 17.4893 8.62844 16.8823V9.5808H1M31.7317 9.5808L16.0389 2.17051C13.8577 1.06218 11.3646 0.728125 8.96859 1.22317C6.57258 1.71821 4.41597 3.01296 2.85262 4.89495C1.75698 6.22287 1.10861 7.86265 1 9.5808M1 16.4469H4.16035M17.6736 16.4469H31.7317'
        stroke={strokePath}
        strokeWidth='1.22361'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default IconDessertSvg;
