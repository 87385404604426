import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './state';

const pizzaSlice = createSlice({
  name: 'pizza',
  initialState,
  reducers: {
    setStateNamePizza(state, action) {
      state.namePizza = action.payload;
    },
    setStateSizePizzaKey(state, action) {
      state.sizePizzaKey = action.payload;
    },
    setIsCustomPizza(state, action) {
      state.isCustomPizza = action.payload;
    },
    resetStatePizza(state) {
      state.namePizza = initialState.namePizza;
      state.sizePizzaKey = initialState.sizePizzaKey;
    },
    setAddPizzaAction(state, action) {
      state.isAddPizzaEvent = action.payload;
    },
    setIsReload(state, action) {
      state.isReload = action.payload;
    },
  },
});

// Actions
export const pizzaActions = pizzaSlice.actions;

// Selectors
export const selectNamePizza = (state: any) => state.pizza.namePizza;
export const selectSizePizzaKey = (state: any) => state.pizza.sizePizzaKey;
export const selectIsAddPizzaEvent = (state: any) => state.pizza.isAddPizzaEvent;
export const selectIsCustomPizza = (state: any) => state.pizza.isCustomPizza;
export const selectIsReload = (state: any) => state.pizza.isReload;

// Reducer
const pizzaReducer = pizzaSlice.reducer;
export default pizzaReducer;
