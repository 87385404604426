import { KeyOptionOrderEnum, KeySizePizzaEnum } from '~/utils/enum';

export interface IItemOrder {
  id: number;
  size?: KeySizePizzaEnum | string;
  name: string;
  type: KeyOptionOrderEnum;
  money: number;
  quantity: number;
  toping: IToping[];
}

export interface IToping {
  id: number;
  position: string;
  name: string;
  quantity: string;
  title: string;
  money: number;
}

export interface IOrder {
  items: IItemOrder[];
  topingRemoved?: IToping;
  typePizzaSelected?: number;
  isResetOrderItem?: boolean;
}

export const initialState: IOrder = {
  items: [],
  isResetOrderItem: false,
};
