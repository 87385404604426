import classNames from 'classnames/bind';
import styles from './LeftContent.module.scss';
import Table from '../table/Table';
import Dropdown from '../dropdown/Dropdown';
import { DropdownOrderLookupEnum } from '~/utils/enum';

const cx = classNames.bind(styles);

const LeftContent = () => {
  return (
    <div id='leftContentOrderLookup' className={cx('left-content-container')}>
      <div className={cx('left-content-dropdown-wrap')}>
        <h4 className={cx('left-content-title')}>Order Lookup</h4>
        <div className={cx('dropdown-wrap')}>
          <Dropdown title={DropdownOrderLookupEnum.ORDER_TYPE} value={'All'} />
          <Dropdown title={DropdownOrderLookupEnum.STATUS} value={'Paid'} />
          <Dropdown title={DropdownOrderLookupEnum.SORT} value={'Ascending'} />
        </div>
      </div>
      <div className={cx('table-wrap')}>
        <Table />
      </div>
    </div>
  );
};

export default LeftContent;
