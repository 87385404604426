import classNames from 'classnames/bind';
import styles from './BreadPage.module.scss';
import { IItemSelected, IToppingItemSelected } from '~/utils/interface/common';
import { ButtonFoodOrder, ButtonFoodOrderTouch, SelectBox } from '~/components/specific';
import { dataFilterBread, optionsBread, toppingPizza } from '~/utils/constants/mockData';
import { NameSelectBoxHeaderBreadEnum } from '~/utils/enum';

interface Props {}

const cx = classNames.bind(styles);

const BreadPage = (props: Props) => {
  const isTouchScreen = JSON.parse(localStorage.getItem('isTouchScreen') ?? 'false');

  const handleChangeEntree = (itemSelected: IItemSelected) => {
    // TODO: Handle logic
  };

  const handleChangeSize = (itemSelected: IItemSelected) => {
    // TODO: Handle logic
  };

  const handleChangeSeasoning = (itemSelected: IItemSelected) => {
    // TODO: Handle logic
  };

  const handleChangeSauces = (itemSelected: IItemSelected) => {
    // TODO: Handle logic
  };

  const handleSelectedItem = (itemSelected: IItemSelected) => {
    if (itemSelected.id === -1) {
      return;
    }
    switch (itemSelected.nameSelectBox) {
      case NameSelectBoxHeaderBreadEnum.ENTREE:
        handleChangeEntree(itemSelected);
        break;
      case NameSelectBoxHeaderBreadEnum.SIZE:
        handleChangeSize(itemSelected);
        break;
      case NameSelectBoxHeaderBreadEnum.SEASONING:
        handleChangeSeasoning(itemSelected);
        break;
      case NameSelectBoxHeaderBreadEnum.SAUCES:
        handleChangeSauces(itemSelected);
        break;
    }
  };

  const handleChangeTopping = (itemSelected: IToppingItemSelected) => {
    console.log(`[FlatbreadPage]: -> itemSelected: ${JSON.stringify(itemSelected, null, 3)}`);
  };

  return (
    <div className={cx('page-container')}>
      <div className={cx('page-head-wrap')}>
        {dataFilterBread.map((item) => {
          const itemDefault = item.data.find((x) => x.id === item.defaultValue);

          return (
            <SelectBox
              key={item.id}
              name={item.name}
              data={item.data}
              defaultValue={itemDefault}
              onChange={handleSelectedItem}
            />
          );
        })}
      </div>

      <div className={cx('page-divide-line')} />

      {isTouchScreen ? (
        <div className={cx('page-body-wrap')}>
          {optionsBread.map((item) => (
            <ButtonFoodOrderTouch
              toppingItem={item}
              isActive={false}
              data={toppingPizza}
              key={item.id}
              onChange={handleChangeTopping}
            />
          ))}
        </div>
      ) : (
        <div className={cx('page-body-wrap')}>
          {optionsBread.map((item) => (
            <ButtonFoodOrder
              toppingItem={item}
              isActive={true}
              data={toppingPizza}
              key={item.id}
              onChange={handleChangeTopping}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default BreadPage;
