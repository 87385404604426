import classNames from 'classnames/bind';
import styles from './ButtonSubmit.module.scss';

type ButtonSubmitProps = {
  title: string;
  onClick: () => void;
};

const cx = classNames.bind(styles);

const ButtonSubmit = (props: ButtonSubmitProps) => {
  const { title, onClick } = props;
  return (
    <button className={cx('button-submit-title')} onClick={onClick}>
      {title}
    </button>
  );
};

export default ButtonSubmit;
