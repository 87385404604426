import classNames from 'classnames/bind';
import styles from './ButtonTop.module.scss';

type ButtonTopItemProps = {
  title?: string;
  subTitle?: string;
  isActive?: boolean;
  onClick?: () => void;
};

const cx = classNames.bind(styles);

const ButtonTop = (props: ButtonTopItemProps) => {
  //#region Destructuring Props
  const { title, subTitle, isActive, onClick } = props;
  //#endregion Destructuring Props

  const handleClick = () => {
    onClick && onClick();
  };

  return (
    <button id='buttonTop' className={cx(['button-top', isActive && 'active'])} onClick={handleClick}>
      <span className={cx(['button-text', 'button-text-sub-title'])}>{subTitle}</span>
      <span className={cx(['button-text', 'button-text-title'])}>{title}</span>
    </button>
  );
};

export default ButtonTop;
