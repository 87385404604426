import classNames from 'classnames/bind';
import styles from './ContentButton.module.scss';
import { MouseEvent } from 'react';

type ContentButtonItemProps = {
  buttonText: string;
  onClick: (event: MouseEvent<HTMLButtonElement>) => void;
};

const cx = classNames.bind(styles);

const ContentButton = (props: ContentButtonItemProps) => {
  const { buttonText, onClick } = props;

  return (
    <button className={cx('content-button')} onClick={onClick}>
      {buttonText}
    </button>
  );
};

export default ContentButton;
