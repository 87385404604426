import { KeySizePizzaEnum } from '~/utils/enum';

interface Pizza {
  namePizza: string;
  sizePizzaKey: KeySizePizzaEnum;
  isAddPizzaEvent: boolean;
  isCustomPizza: boolean;
  isReload: boolean;
}

export const initialState: Pizza = {
  namePizza: '',
  sizePizzaKey: KeySizePizzaEnum.LARGE,
  isAddPizzaEvent: false,
  isCustomPizza: false,
  isReload: false,
};
