import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { publicRoutes } from './utils/constants/route';
import { useTouchScreen } from './utils/customHook';

type Props = {};

const App = (props: Props) => {
  useTouchScreen();
  return (
    <BrowserRouter>
      <Routes>
        {publicRoutes.map((route, index) => {
          const Page = route.component;
          const Layout = route.layout;
          return (
            <Route
              key={index}
              path={route.path}
              element={
                <Layout>
                  <Page />
                </Layout>
              }
            />
          );
        })}
      </Routes>
    </BrowserRouter>
  );
};

export default App;
