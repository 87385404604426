import classNames from 'classnames/bind';
import styles from './ButtonPrimary.module.scss';
import { TypeButtonEnum } from '~/utils/enum';

type ButtonPrimaryProps = {
  title: string;
  type?: TypeButtonEnum;
  action?: () => void;
};

const cx = classNames.bind(styles);

const ButtonPrimary = (props: ButtonPrimaryProps) => {
  const { title, type = TypeButtonEnum.SUBMIT, action } = props;

  let buttonClassNameByType = '';
  if (type === TypeButtonEnum.LOGIN) {
    buttonClassNameByType = 'button-login';
  }
  if (type === TypeButtonEnum.SUBMIT) {
    buttonClassNameByType = 'button-submit-landing';
  }

  return (
    <button id='buttonPrimary' className={cx('button-primary', [buttonClassNameByType])} onClick={action}>
      {title}
    </button>
  );
};

export default ButtonPrimary;
