import classNames from 'classnames/bind';
import { NavItem } from '~/components/specific';

import styles from './Header.module.scss';
import { publicRoutes } from '~/utils/constants/route';
import { useLocation } from 'react-router';

const cx = classNames.bind(styles);

const Header = () => {
  //#region Declare Hook
  const location = useLocation();
  //#endregion Declare Hook

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  //#endregion Handle Function

  return (
    <div className={cx('container')}>
      {publicRoutes.map((nav, index) => {
        if (!nav.name || !nav.icon) {
          return;
        }

        return (
          <NavItem
            key={index}
            path={nav.path}
            name={nav.name}
            Icon={nav.icon}
            isActive={nav.path === location.pathname}
          />
        );
      })}
    </div>
  );
};

export default Header;
