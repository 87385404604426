import { useEffect, useRef, useState } from 'react';
import classNames from 'classnames/bind';

import icons from '~/assets/icons';
import styles from './SelectOption.module.scss';

interface SelectOptionProps {
  data: { label: string; value: number }[];
  selected: number;
  setSelected: React.Dispatch<React.SetStateAction<any>>;
  onChange?: (val: number) => void;
}

const cx = classNames.bind(styles);

const SelectOption = (props: SelectOptionProps) => {
  const { data, selected, setSelected, onChange } = props;

  const [showSelect, setShowSelect] = useState<boolean>(false);

  const optionsRef = useRef<HTMLUListElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleClickSelect = () => {
    setShowSelect(!showSelect);
  };

  const handleSelectedOption = (val: number) => {
    setSelected(val);
    setShowSelect(false);
    onChange && onChange(val);
  };

  const handleOutsideClick = (event: MouseEvent) => {
    if (
      optionsRef.current &&
      !optionsRef.current.contains(event.target as Node) &&
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setShowSelect(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  return (
    <div id='select-option' className={cx('select-container')} ref={dropdownRef}>
      <div
        className={showSelect ? cx('select-selected-wrap-active') : cx('select-selected-wrap')}
        onClick={handleClickSelect}
      >
        <span className={cx('select-selected')}>{selected}</span>
        <img
          className={showSelect ? cx('select-selected-arrow-rotate') : cx('icon-arrow')}
          src={icons.arrowDownColorSvg}
          alt='Arrow'
        />
      </div>

      {showSelect && (
        <ul ref={optionsRef} className={cx('select-options')}>
          {data.map((option) => (
            <li className={cx('select-option')} key={option.value} onClick={() => handleSelectedOption(option.value)}>
              {option.label}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default SelectOption;
