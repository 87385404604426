import classNames from 'classnames/bind';
import { ButtonFoodOrder, ButtonFoodOrderTouch, SelectBox } from '~/components/specific';
import { dataFilterSubs, optionsSubs, toppingPizza, toppingPizzaTouch } from '~/utils/constants/mockData';
import styles from './SubsPage.module.scss';
import { IItemSelected, IToppingItemSelected } from '~/utils/interface/common';
import { KeyOptionOrderEnum, NameSelectBoxHeaderSubsEnum } from '~/utils/enum';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { orderActions, selectOrder } from '~/sagas/order/slice';
import { IItemOrder } from '~/sagas/order/state';

const cx = classNames.bind(styles);

const SubsPage = () => {
  const dispatch = useDispatch();
  const isTouchScreen = JSON.parse(localStorage.getItem('isTouchScreen') ?? 'false');
  const [sizeSelected, setSizeSelected] = useState<string>('8” Regular');
  const orderSelector = useSelector(selectOrder);

  const handleChangeSub = (itemSelected: IItemSelected) => {
    const flatbreadSelected: IItemOrder = {
      id: Date.now(),
      name: itemSelected.value,
      size: sizeSelected,
      money: sizeSelected === '8” Regular' ? 8 : 10,
      type: KeyOptionOrderEnum.SUBS,
      quantity: 1,
      toping: [],
    };
    dispatch(orderActions.addToOrder(flatbreadSelected));
  };

  const handleChangeSize = (itemSelected: IItemSelected) => {
    const id = orderSelector[0]?.id;
    if (!id) {
      return;
    }
    if (orderSelector[0].type === KeyOptionOrderEnum.SUBS) {
      // TODO handle logic
      const payloadUpdate = {
        id: id,
        newSize: itemSelected.value,
        money: itemSelected.value === '8” Regular' ? 8 : 10,
      };
      dispatch(orderActions.updateSizeItem(payloadUpdate));
      setSizeSelected(itemSelected.value);
    }
  };

  const handleChangeSlides = (itemSelected: IItemSelected) => {
    // TODO: Handle logic
  };

  const handleChangeSauces = (itemSelected: IItemSelected) => {
    // TODO: Handle logic
  };

  const handleChangePreference = (itemSelected: IItemSelected) => {
    // TODO: Handle logic
  };

  const handleSelectedItem = (itemSelected: IItemSelected) => {
    if (itemSelected.id === -1) {
      return;
    }
    switch (itemSelected.nameSelectBox) {
      case NameSelectBoxHeaderSubsEnum.SUB:
        handleChangeSub(itemSelected);
        break;
      case NameSelectBoxHeaderSubsEnum.SIZE:
        handleChangeSize(itemSelected);
        break;
      case NameSelectBoxHeaderSubsEnum.SIDES:
        handleChangeSlides(itemSelected);
        break;
      case NameSelectBoxHeaderSubsEnum.SAUCES:
        handleChangeSauces(itemSelected);
        break;
      case NameSelectBoxHeaderSubsEnum.PREFERENCE:
        handleChangePreference(itemSelected);
        break;
    }
  };

  const handleChangeTopping = (itemSelected: IToppingItemSelected) => {
    const id = orderSelector[0]?.id;
    if (!id) {
      return;
    }
    if (!itemSelected.value) {
      const itemTopingRemove = orderSelector[0].toping.find(
        (t: any) => t.position === itemSelected.position && t.title === itemSelected.title
      );
      if (!itemTopingRemove) {
        return;
      }

      const payloadRemoveToping = {
        id: id,
        idToping: itemTopingRemove.id,
      };
      dispatch(orderActions.removeTopingOrder(payloadRemoveToping));
      return;
    }
    const dataAddToping = {
      id: id,
      newToping: {
        id: Date.now(),
        position: itemSelected.position,
        quantity: itemSelected.value,
        name: `${itemSelected.value} ${itemSelected.position} ${itemSelected.title}`,
        title: itemSelected.title,
        money: 2,
      },
    };
    const topingExited = orderSelector[0].toping.find(
      (t: any) => t.title === itemSelected.title && t.position === itemSelected.position
    );
    if (topingExited) {
      dispatch(orderActions.updateTopingOrder(dataAddToping));
      return;
    }
    dispatch(orderActions.addTopingOrder(dataAddToping));
  };

  return (
    <div className={cx('page-container')}>
      <div className={cx('page-head-wrap')}>
        {dataFilterSubs.map((item) => {
          const itemDefault = item.data.find((x) => x.id === item.defaultValue);

          return (
            <SelectBox
              key={item.id}
              name={item.name}
              data={item.data}
              defaultValue={itemDefault}
              onChange={handleSelectedItem}
            />
          );
        })}
      </div>

      <div className={cx('page-divide-line')} />

      {isTouchScreen ? (
        <div className={cx('page-body-wrap')}>
          {optionsSubs.map((item) => (
            <ButtonFoodOrderTouch
              toppingItem={item}
              isActive={false}
              data={toppingPizzaTouch}
              key={item.id}
              onChange={handleChangeTopping}
            />
          ))}
        </div>
      ) : (
        <div className={cx('page-body-wrap')}>
          {optionsSubs.map((item) => (
            <ButtonFoodOrder
              toppingItem={item}
              isActive={true}
              data={toppingPizza}
              key={item.id}
              onChange={handleChangeTopping}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default SubsPage;
