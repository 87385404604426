import classNames from 'classnames/bind';
import { ReactNode } from 'react';
import styles from './Main.module.scss';
import Header from './header/Header';
import Sidebar from './sidebar/Sidebar';
import Footer from './footer/Footer';
import { useTouchScreen } from '~/utils/customHook';

type Props = {
  children: ReactNode;
};

const cx = classNames.bind(styles);

const Main = (props: Props) => {
  const isTouchScreen = useTouchScreen();

  return (
    <div className={cx('main-layout-container')}>
      <div className={cx('main-content-wrap')}>
        <div className={cx('sidebar-wrap')}>
          <Sidebar />
        </div>

        <div className={cx('right-content')}>
          <Header />

          <div id='main-wrap' className={cx('content-wrap')}>
            {props.children}
          </div>
        </div>
      </div>

      <div className={cx('footer-wrap')}>
        <Footer />
      </div>
    </div>
  );
};

export default Main;
