import classNames from 'classnames/bind';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { IconSvgProps } from '~/utils/interface/common';
import styles from './NavItem.module.scss';
import { useDispatch } from 'react-redux';
import { orderActions } from '~/sagas/order/slice';
import { pizzaActions } from '~/sagas/pizza/slice';

type NavItemProps = {
  path: string;
  name: string;
  Icon: React.ComponentType<IconSvgProps>;
  isActive: boolean;
};

const cx = classNames.bind(styles);

const NavItem = (props: NavItemProps) => {
  //#region Destructuring Props
  const { path, Icon, name, isActive } = props;
  //#endregion Destructuring Props
  const dispatch = useDispatch();
  const handleClick = () => {
    dispatch(pizzaActions.setIsReload(true));
  };

  return (
    <NavLink
      to={path}
      className={({ isActive }) => cx(['nav-item-wrap', isActive && 'nav-active'])}
      onClick={handleClick}
    >
      <div className={cx('nav-icon')}>
        <Icon strokePath={isActive ? '#f6f6f6' : undefined} />
      </div>
      <span className={cx('nav-label')}>{name}</span>
    </NavLink>
  );
};

export default NavItem;
