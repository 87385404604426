import { IconSvgProps } from '~/utils/interface/common';
import styles from './styles.module.scss';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

const IconMallSvg = (props: IconSvgProps) => {
  return (
    <svg viewBox='0 0 28 19' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M1.65627 0.5L13.9999 10.2681L26.3436 0.5H1.65627ZM0 1.79808V16.5C0 17.6046 0.895431 18.5 2 18.5H26C27.1046 18.5 28 17.6046 28 16.5V1.79808L14.604 12.399C14.2472 12.6803 13.7524 12.6803 13.3957 12.399L0 1.79808Z'
        fill='white'
      />
    </svg>
  );
};

export default IconMallSvg;
