import { IconSvgProps } from '~/utils/interface/common';
import styles from './styles.module.scss';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

const IconSaladsSvg = (props: IconSvgProps) => {
  //#region Destructuring Props
  const { strokePath = '#1A1346' } = props;
  //#endregion Destructuring Props

  return (
    <svg className={cx('icon-salads')} viewBox='0 0 36 34' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        id='Vector'
        d='M12.2129 6.60671C12.2129 3.51021 14.7231 1 17.8196 1C20.9161 1 23.4263 3.51021 23.4263 6.60671C24.1698 5.86321 25.1782 5.44552 26.2296 5.44552C27.2811 5.44552 28.2895 5.86321 29.033 6.60671C30.2533 7.81589 30.5484 9.67725 29.7619 11.2046M12.2129 17.8202C12.2129 14.7237 14.7231 12.2135 17.8196 12.2135M17.8196 12.2135C20.9161 12.2135 23.4263 14.7237 23.4263 17.8202M17.8196 12.2135C17.8192 9.37345 15.6959 6.98211 12.8757 6.64619C10.0556 6.31026 7.42961 8.13582 6.7621 10.8963C6.6997 11.1561 6.52363 11.374 6.28273 11.4895C6.04182 11.6051 5.7617 11.606 5.52004 11.492C4.07214 10.9045 2.41077 11.4183 1.54724 12.7206C0.683715 14.0228 0.857063 15.7532 1.96177 16.8583L3.23387 18.1304M32.7391 18.3218C33.8483 16.8022 34.4474 14.9702 34.4504 13.0889V11.9676C34.4504 11.3483 33.9483 10.8462 33.329 10.8462H32.2075C28.027 10.8503 24.4047 13.7443 23.4777 17.8207M32.3976 28.2861C30.2799 31.1097 26.9563 32.7715 23.4268 32.7715H12.2134C8.68391 32.7715 5.36039 31.1097 3.24268 28.2861C1.78693 26.3451 1 23.9843 1 21.5581C1 19.4938 2.67347 17.8203 4.7378 17.8203H30.9024C32.9668 17.8203 34.6402 19.4938 34.6402 21.5581C34.6402 23.9843 33.8533 26.3451 32.3976 28.2861Z'
        stroke={strokePath}
        strokeWidth='1.32901'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default IconSaladsSvg;
