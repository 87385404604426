import classNames from 'classnames/bind';
import styles from './Footer.module.scss';
import FooterInformation from '../footerInformation/FooterInformation';
import { FooterButton } from '~/components/specific';
import { TypeFooterButtonEnum } from '~/utils/enum';
import icons from '~/assets/icons';

type FooterProps = {};

const cx = classNames.bind(styles);

const Footer = (props: FooterProps) => {
  return (
    <div id='footerWelcome' className={cx('footer-container')}>
      <div className={cx('footer-options')}>
        <FooterInformation title='Order ID' subTitle='New' />
        <FooterInformation title='User' subTitle='Hunger R.' />
        <FooterInformation title='Table' subTitle='Pending' />
      </div>
      <div className={cx('footer-options-button')}>
        <FooterButton type={TypeFooterButtonEnum.ACTION} content={'Config'} />
        <FooterButton type={TypeFooterButtonEnum.ACTION} content={'Management'} />
        <FooterButton
          type={TypeFooterButtonEnum.SEND}
          content={
            <div className={cx('footer-options-button-icon-wrap')}>
              <img src={icons.mallSvg} alt='sunSvg' className={cx('footer-options-button-icon')} />
              <span className={cx('footer-options-button-icon-text')}>8 unread messages</span>
            </div>
          }
        />
      </div>
    </div>
  );
};

export default Footer;
