import classNames from 'classnames/bind';

import ButtonTop from '~/components/specific/buttonTop/ButtonTop';
import ContentButton from '~/components/specific/contentButton/ContentButton';

import { buttonTop, contentButtonSecretPage } from '~/utils/constants/mockData';
import styles from './SecretPage.module.scss';

const cx = classNames.bind(styles);

const SecretPage = () => {
  return (
    <div className={cx('page-container')}>
      <div className={cx('page-head-wrap')}>
        {buttonTop.map((item, index) => (
          <div key={index}>
            <ButtonTop title={item.title} subTitle={item.subTitle} />
          </div>
        ))}
      </div>

      <div className={cx('page-divide-line')} />

      <div className={cx('page-body-wrap')}>
        {contentButtonSecretPage.map((item, index) => (
          <ContentButton
            key={item.id}
            buttonText={item.content}
            onClick={() => console.log(`Button ${index + 1} clicked`)}
          />
        ))}
      </div>
    </div>
  );
};

export default SecretPage;
