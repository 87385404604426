import { useState } from 'react';
import classNames from 'classnames/bind';
import styles from './BowlsPage.module.scss';
import { dataSelectBoxHeaderBowl, optionsBowls, toppingPizza } from '~/utils/constants/mockData';
import { ButtonFoodOrder, ButtonFoodOrderTouch, SelectBox } from '~/components/specific';
import { IItemSelected } from '~/utils/interface/common';
// import {
//   buttonFoodOrderBowlsPage,
//   entreeOptionsBowlsPage,
//   preferenceOptionsBowlsPage,
//   saucesOptionsBowlsPage,
//   sidesOptionsBowlsPage,
//   sizeOptionsBowlsPage,
// } from '~/utils/constants/mockData';
// import { ButtonFoodOrder, Selector } from '~/components/specific';

type BowlsPageProps = {};

const cx = classNames.bind(styles);

const BowlsPage = (props: BowlsPageProps) => {
  const isTouchScreen = JSON.parse(localStorage.getItem('isTouchScreen') ?? 'false');

  //#region Destructuring Props
  //#endregion Destructuring Props

  //#region Declare Hook
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [entreeSelected, setEntreeSelected] = useState<any>(null);
  const [sizeSelected, setSizeSelected] = useState<any>(null);
  const [sidesSelected, setSidesSelected] = useState<any>(null);
  const [saucesSelected, setSaucesSelected] = useState<any>(null);
  const [preferenceSelected, setPreferenceSelected] = useState<any>(null);
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  //#endregion Handle Function

  // const arraySelection: any[] = [
  //   {
  //     name: 'Specialty',
  //     data: choosePizza,
  //     selected: pizza,
  //     setSelected: setPizza,
  //   },
  //   {
  //     name: 'Size',
  //     data: size,
  //     selected: pizzaSize,
  //     setSelected: setPizzaSize,
  //   },
  //   {
  //     name: 'Crust Type',
  //     data: crustType,
  //     selected: pizzaCrustType,
  //     setSelected: setPizzaCrustType,
  //   },
  //   {
  //     name: 'Sauce',
  //     data: sauce,
  //     selected: pizzaSauce,
  //     setSelected: setPizzaSauce,
  //   },
  //   {
  //     name: 'Flavors',
  //     data: flavors,
  //     selected: pizzaFlavors,
  //     setSelected: setPizzaFlavors,
  //   },
  // ];

  const handleChangeSelectBox = (itemSelected: IItemSelected) => {
    if (itemSelected.id === -1) {
      return;
    }
  };
  return (
    <div id='bowlPage' className={cx('page-container')}>
      <div className={cx('page-head-wrap')}>
        {dataSelectBoxHeaderBowl.map((item) => {
          const itemDefault = item.data.find((x) => x.id === item.defaultValue);
          return (
            <SelectBox
              key={item.name}
              name={item.name}
              data={item.data}
              defaultValue={itemDefault}
              onChange={handleChangeSelectBox}
            />
          );
        })}
      </div>

      <div className={cx('page-divide-line')} />

      {isTouchScreen ? (
        <div className={cx('page-body-wrap')}>
          {optionsBowls.map((item) => (
            <ButtonFoodOrderTouch toppingItem={item} isActive={false} data={toppingPizza} key={item.id} />
          ))}
        </div>
      ) : (
        <div className={cx('page-body-wrap')}>
          {optionsBowls.map((item) => (
            <ButtonFoodOrder toppingItem={item} isActive={true} data={toppingPizza} key={item.id} />
          ))}
        </div>
      )}
    </div>
  );
};

export default BowlsPage;
