import classNames from 'classnames/bind';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ButtonFoodOrder, ButtonFoodOrderTouch, SelectBox } from '~/components/specific';
import { orderActions, selectOrder, selectTypePizzaSelected } from '~/sagas/order/slice';
import {
  contentButton,
  dataSelectBoxHeaderPizza,
  optionsOrderPizza,
  size,
  toppingPizza,
  toppingPizzaTouch,
} from '~/utils/constants/mockData';
import { KeyOptionOrderEnum, KeyTypeSizeEnum, NameSelectBoxHeaderPizzaEnum } from '~/utils/enum';
import { IItemSelected, IToppingItemSelected } from '~/utils/interface/common';
import styles from './OptionsOrderPizza.module.scss';
import { IItemOrder } from '~/sagas/order/state';
import { selectIsCustomPizza } from '~/sagas/pizza/slice';
import { useTouchScreen } from '~/utils/customHook';

const cx = classNames.bind(styles);

type Props = {
  typePizzaId?: number;
};

const OptionsOrderPizza = (props: Props) => {
  //#region Destructuring Props
  // const { typePizzaId } = props;
  //#endregion Destructuring Props

  const dispatch = useDispatch();
  const isTouchScreen = JSON.parse(localStorage.getItem('isTouchScreen') ?? 'false');

  const orderSelector: IItemOrder[] = useSelector(selectOrder);
  const typePizzaSelected: number = useSelector(selectTypePizzaSelected);
  const isCustomPizza: number = useSelector(selectIsCustomPizza);

  const [sizeSelected, setSizeSelected] = useState<number>(KeyTypeSizeEnum.FOURTEEN_LARGE);
  const [topingIds, setTopingIds] = useState<KeyOptionOrderEnum[]>(() => {
    const pizzaSelected = contentButton.find((x) => x.id === typePizzaSelected);
    if (!pizzaSelected) {
      return [];
    }
    return pizzaSelected.topingIds;
  });

  useEffect(() => {
    if (!typePizzaSelected) {
      return;
    }

    const pizzaSelected = contentButton.find((x) => x.id === typePizzaSelected);
    if (!pizzaSelected) {
      setTopingIds([]);
      return;
    }

    setTopingIds(pizzaSelected.topingIds);
  }, [typePizzaSelected]);

  const handleChangePizza = (itemSelected: IItemSelected) => {
    const itemSizeSelected = size.find((x) => x.id === sizeSelected);
    if (!itemSizeSelected) {
      return;
    }
    const pizzaSelected = {
      id: Date.now(),
      name: itemSelected.value,
      size: itemSizeSelected.size,
      money: itemSizeSelected.size,
      type: KeyOptionOrderEnum.PIZZA,
      quantity: 1,
      toping: [],
    };
    console.log(`Select Pizza -> ${JSON.stringify(pizzaSelected)}`);
    dispatch(orderActions.addToOrder(pizzaSelected));
    dispatch(orderActions.setPizzaSelected(itemSelected.id));
  };

  const handleChangeSize = (itemSelected: IItemSelected) => {
    const id = orderSelector[0]?.id;
    if (!id) {
      return;
    }
    const payloadUpdateOrder = {
      id: id,
      newSize: size.find((x) => x.id === itemSelected.id)?.size,
      money: size.find((x) => x.id === itemSelected.id)?.size,
    };
    dispatch(orderActions.updateSizeItem(payloadUpdateOrder));
    setSizeSelected(itemSelected.id);
  };

  const handleChangeSelectBox = (itemSelected: IItemSelected) => {
    if (itemSelected.id === -1) {
      return;
    }
    switch (itemSelected.nameSelectBox) {
      case NameSelectBoxHeaderPizzaEnum.SPECIALTY:
        handleChangePizza(itemSelected);
        break;
      case NameSelectBoxHeaderPizzaEnum.SIZE:
        handleChangeSize(itemSelected);
        break;
    }
  };

  const handleChangeToping = (itemSelected: IToppingItemSelected) => {
    const id = orderSelector[0]?.id;
    if (!id) {
      return;
    }
    if (!itemSelected.value) {
      const itemTopingRemove = orderSelector[0].toping.find(
        (t) => t.position === itemSelected.position && t.title === itemSelected.title
      );
      if (!itemTopingRemove) {
        return;
      }

      const payloadRemoveToping = {
        id: id,
        idToping: itemTopingRemove.id,
      };
      dispatch(orderActions.removeTopingOrder(payloadRemoveToping));
      return;
    }
    const dataAddToping = {
      id: id,
      newToping: {
        id: Date.now(),
        position: itemSelected.position,
        quantity: itemSelected.value,
        name: `${itemSelected.value} ${itemSelected.position} ${itemSelected.title}`,
        title: itemSelected.title,
        money: 2,
      },
    };
    const topingExited = orderSelector[0].toping.find(
      (t) => t.title === itemSelected.title && t.position === itemSelected.position
    );
    if (topingExited) {
      dispatch(orderActions.updateTopingOrder(dataAddToping));
      return;
    }
    dispatch(orderActions.addTopingOrder(dataAddToping));
  };

  return (
    <div className={cx('page-container')}>
      <div className={cx('page-head-wrap')}>
        {dataSelectBoxHeaderPizza.map((item) => {
          const itemDefault = item.data.find((x) => x.id === item.defaultValue);
          return (
            <SelectBox
              key={item.name}
              name={item.name}
              data={item.data}
              defaultValue={itemDefault}
              emptyValue={item.emptyValue}
              onChange={handleChangeSelectBox}
            />
          );
        })}
      </div>

      <div className={cx('page-divide-line')} />

      {isTouchScreen ? (
        <div className={cx('page-body-wrap')}>
          {optionsOrderPizza.map((item) => (
            <ButtonFoodOrderTouch
              toppingItem={item}
              isActive={isCustomPizza ? false : !typePizzaSelected ? true : topingIds.includes(item.id)}
              data={toppingPizzaTouch}
              key={item.id}
              onChange={handleChangeToping}
            />
          ))}
        </div>
      ) : (
        <div className={cx('page-body-wrap')}>
          {optionsOrderPizza.map((item) => (
            <ButtonFoodOrder
              toppingItem={item}
              isActive={isCustomPizza ? true : !typePizzaSelected ? true : topingIds.includes(item.id)}
              data={toppingPizza}
              key={item.id}
              onChange={handleChangeToping}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default OptionsOrderPizza;
