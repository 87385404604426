import classNames from 'classnames/bind';
import { useLocation, useNavigate } from 'react-router-dom';

import icons from '~/assets/icons';
import styles from './OrderInfoRow.module.scss';
import { useEffect, useState } from 'react';
import {
  HOURS_IN_HAFT_A_DAY,
  LENGTH_OF_THE_TIME_SECONDS,
  TIME_SECONDS_INTERVAL_GET_TIME,
  VALUE_PAD_OF_THE_TIME_SECONDS,
} from '~/utils/constants/mockData';
import { useDispatch } from 'react-redux';
import { userRoute } from '~/utils/constants/route';
import { pizzaActions } from '~/sagas/pizza/slice';

const cx = classNames.bind(styles);

const OrderInfoRow = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const handleBackButtonClick = () => {
    const isPizzaPage = location.pathname === `${userRoute.base}${userRoute.pizza}`;

    if (isPizzaPage) {
      dispatch(pizzaActions.setAddPizzaAction(true));
      dispatch(pizzaActions.setIsCustomPizza(false));
      return;
    }
    dispatch(pizzaActions.setIsCustomPizza(false));
    navigate(`${userRoute.base}${userRoute.pizza}`);
  };

  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(new Date());
    }, TIME_SECONDS_INTERVAL_GET_TIME);

    return () => clearInterval(intervalId);
  }, []);

  const displayTime = (): string => {
    const hours = currentTime.getHours();
    const minutes = currentTime.getMinutes();
    const formattedHours = (hours % HOURS_IN_HAFT_A_DAY || HOURS_IN_HAFT_A_DAY).toString();
    const formattedMinutes = minutes.toString().padStart(LENGTH_OF_THE_TIME_SECONDS, VALUE_PAD_OF_THE_TIME_SECONDS);
    return `${formattedHours}:${formattedMinutes}`;
  };

  return (
    <div className={cx('info-row-wrap')}>
      <button className={cx('back-btn')} onClick={handleBackButtonClick}>
        <img src={icons.backWhiteSvg} alt='backSvg' className={cx('btn-back-icon')} />
      </button>

      <div className={cx('info-center-wrap')}>
        <span className={cx('info-time')}>{displayTime()}</span>
        <span className={cx('info-order-wrap')}>
          <span className={cx('info-order-opacity')}>Order ID</span>
          <span>New</span>
        </span>
      </div>

      <span className={cx('info-customer-status')}>
        <span>Customer</span>
        <span className={cx('info-customer-status-pending')}>(Pending)</span>
      </span>
    </div>
  );
};

export default OrderInfoRow;
