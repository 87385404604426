import classNames from 'classnames/bind';

import styles from './Greetings.module.scss';
import icons from '~/assets/icons';

type GreetingsProps = {
  title: string;
  isDisplaySun?: boolean;
  isWelcome?: boolean;
};

const cx = classNames.bind(styles);

const Greetings = (props: GreetingsProps) => {
  const { title, isDisplaySun = true, isWelcome = false } = props;
  return (
    <div id='greetings' className={cx('greetings-container')}>
      {isDisplaySun && (
        <div className={cx('greetings-icon')}>
          <img src={icons.sunSvg} alt='sunSvg' />
        </div>
      )}
      <p className={cx('greetings-title', isWelcome && 'welcome')}>{title}</p>
    </div>
  );
};

export default Greetings;
