import React from 'react';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames/bind';
import images from '~/assets/images';

import styles from './Login.module.scss';
import Greetings from '~/components/specific/greetings/Greetings';
import Time from '~/components/specific/time/Time';
import { BaseButton } from '~/components/specific';
import ButtonPrimary from '~/components/specific/buttonPrimary/ButtonPrimary';
import InputCode from '~/components/specific/inputCode/inputCode';
import DateTitle from '~/components/specific/dateTitle/DateTitle';
import { enterStationCode, posVersion } from '~/utils/constants/common';
import { STATION_TAG_CONTENT, dataNumberKeyboard, TITLE_GREETINGS_LOGIN } from '~/utils/constants/mockData';
import { KeyboardActionTextEnum, TypeButtonEnum, TypeDateEnum } from '~/utils/enum';
import { userRoute } from '~/utils/constants/route';

interface Props {}

const cx = classNames.bind(styles);

const Login = (props: Props) => {
  const navigate = useNavigate()

  const handleLoginClicked = () => {
    navigate(`${userRoute.base}${userRoute.welcome}`)
  };

  return (
    <div id='login' className={cx('login-container')}>
      <div className={cx('login-banner')}>
        <div className={cx('login-banner-header')}>
          <div className={cx('login-banner-header-logo')}>
            <img src={images.logoMini} alt='logo' />
          </div>
        </div>

        <div className={cx('login-banner-content')}>
          <div className={cx('login-banner-greeting-wrapper')}>
            <Greetings title={TITLE_GREETINGS_LOGIN} />
          </div>
          <div className={cx('login-banner-date-wrapper')}>
            <DateTitle type={TypeDateEnum.LOGIN} />
          </div>
          <div className={cx('login-banner-time-wrapper')}>
            <Time isLogin />
          </div>
        </div>

        <div className={cx('login-banner-footer')}>
          <div className={cx('login-banner-footer-title')}>
            <div className={cx('login-banner-footer-title-txt')}>{STATION_TAG_CONTENT}</div>
          </div>
        </div>
      </div>

      <div className={cx('login-content')}>
        <form className={cx('login-content-form')} onSubmit={handleLoginClicked}>
          <div className={cx('login-content-station-code')}>
            <InputCode title={enterStationCode} />
          </div>
          <div className={cx('login-content-keyboard')}>
            {dataNumberKeyboard.slice(0, 9).map((number, index) => (
              <BaseButton key={index} title={number} isNumericOrder />
            ))}
          </div>
          <div className={cx('login-content-keyboard')}>
            <div className={cx('login-content-keyboard-clear')}>
              <BaseButton title={KeyboardActionTextEnum.CLEAR} />
            </div>
            <div className={cx('login-content-keyboard-zero')}>
              <BaseButton title={dataNumberKeyboard[9]} isNumericOrder />
            </div>
            <div className={cx('login-content-keyboard-back')}>
              <BaseButton title={KeyboardActionTextEnum.BACK} />
            </div>
          </div>

          <div className={cx('login-content-actions')}>
            <BaseButton title={KeyboardActionTextEnum.CANCEL} fontSize20 backgroundType1/>
            <ButtonPrimary title={KeyboardActionTextEnum.LOGIN} type={TypeButtonEnum.LOGIN} />
          </div>
        </form>

        <div className={cx('login-content-footer')}>{posVersion}</div>
      </div>
    </div>
  );
};

export default Login;
