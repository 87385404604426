import classNames from 'classnames/bind';
import styles from './FooterInformation.module.scss';

type FooterInformationProps = {
  title: string;
  subTitle: string;
};

const cx = classNames.bind(styles);

const FooterInformation = (props: FooterInformationProps) => {
  const { title, subTitle } = props;

  return (
    <div id='footerInformation' className={cx('footer-information-container')}>
      <p className={cx('footer-information-title')}>{title}</p>
      <p className={cx('footer-information-sub-title')}>{subTitle}</p>
    </div>
  );
};

export default FooterInformation;
