export const Languages = ['en'];

export const urlApiAuth = {
  login: 'auth/login',
  refreshToken: 'auth/refresh-token',
};

// Selector Component
export const emptyValue = 'None';
export const emptyStrValue = '';

// Sidebar
export const defaultTax = 0.06;

export const posVersion = 'HungerRush360 POS 10.49.30127';

export const incorrectPasswordMessage = 'Incorrect Password';

export const enterStationCode = 'Enter Station Code';

export const enterEmployeeID = 'Please enter your Employee ID';

// Time content order lookup
export const firstTimeContent = 'Clocked-in at';

// Number keyboard
export const numberKeyboard = [1, 2, 3, 4, 5, 6, 7, 8, 9];
