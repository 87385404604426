import { useState } from 'react';
import classNames from 'classnames/bind';
import { ContentButton, SelectBox } from '~/components/specific';
import { buttonLabel, dataSelectorDesserts } from '~/utils/constants/mockData';
import styles from './DessertsPage.module.scss';
import { IItemSelected } from '~/utils/interface/common';

type DessertsPageProps = {};

const cx = classNames.bind(styles);

const DessertsPage = (props: DessertsPageProps) => {
  //#region Handle Function
  const handleClick = () => {};
  //#endregion Handle Function

  const handleChangeSelectBox = (itemSelected: IItemSelected) => {
    console.log(`[DessertsPage] -> itemSelected: ${JSON.stringify(itemSelected, null, 3)}`);
  };

  return (
    <div className={cx('page-container')}>
      <div className={cx('page-head-wrap')}>
        {dataSelectorDesserts.map((item) => {
          const itemDefault = item.data.find((x) => x.id === item.defaultValue);
          return (
            <SelectBox
              key={item.name}
              name={item.name}
              data={item.data}
              defaultValue={itemDefault}
              onChange={handleChangeSelectBox}
            />
          );
        })}
      </div>

      <div className={cx('page-divide-line')} />

      <div className={cx('page-body-wrap')}>
        {buttonLabel.map((item) => (
          <ContentButton key={item.id} buttonText={item.value} onClick={handleClick} />
        ))}
      </div>
    </div>
  );
};

export default DessertsPage;
